<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/settings')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("профиль") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list
            v-subheader {{ $t("информация") }}
            v-list-item
              v-list-item-icon.mr-5
                v-icon mdi-account
              v-list-item-content
                v-list-item-title {{ $t("имя") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1
                  v-btn.mr-1(small icon @click="editName()" :loading="saveNameLoading")
                    v-icon(small color="primary") mdi-pencil
                  | {{ user.name }} ({{ user.id }})

            v-list-item
              v-list-item-icon.mr-5
                v-icon mdi-phone
              v-list-item-content
                v-list-item-title {{ $t("номер_телефона") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ user.phone | phone }}

            v-list-item(v-if="googleEmail")
              v-list-item-icon.mr-5
                v-icon mdi-google
              v-list-item-content
                v-list-item-title Google
              v-list-item-action
                v-list-item-action-text.subtitle-1
                  //- v-btn.mr-1(icon @click="unlinkConfirm()")
                  //-   v-icon(small color="primary") mdi-close-octagon
                  | {{ googleEmail }}

            v-list-item
              v-list-item-icon.mr-5
                v-icon mdi-calendar
              v-list-item-content
                v-list-item-title {{ $t("дата_регистрации") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ user.registrationDate | moment("DD-MM-YYYY") }}

    v-dialog(v-model="nameDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_имя") }}
        v-card-text
          v-container
            v-form.mt-3(ref="nameForm" @submit.prevent="saveName()")
              v-text-field(:label="$t('имя')" v-model="nameInput" type="text" :rules="[rules.required]" autocomplete="off" maxlength="30" counter="30")
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="nameDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="saveName()") {{ $t("сохранить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    nameDialog: false,
    nameInput: null,
    saveNameLoading: false,

    googleEmail: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },

  created() {
    if (this.$isCordova() && this.$isAndroid()) {
      this.fetchGoogleProvider()
    }
  },

  methods: {
    ...mapActions({
      setName: 'auth/setName',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
    }),

    fetchGoogleProvider() {
      this.googleEmail = null
      window.FirebasePlugin.getCurrentUser((user) => {
        if (user.providers) {
          user.providers.forEach((provider) => {
            if (provider.providerId === 'google.com') {
              this.googleEmail = provider.email
            }
          })
        }
      }, () => {})
    },

    unlinkConfirm() {
      this.showConfirm({
        title: this.$t('вы_уверены_что_хотите_отвязать_type', { type: 'Google' }),
        resolveText: this.$t('отвязать_type', { type: 'Google' }),
        rejectText: this.$t('нет'),
        persistent: true,
      }).then(() => {
        this.unlinkGoogleProvider()
      }).catch(() => {})
    },

    unlinkGoogleProvider() {
      window.FirebasePlugin.unlinkUserWithProvider('google.com', () => {
        this.fetchGoogleProvider()
        localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
      }, (error) => {
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    editName() {
      this.nameInput = this.user.name
      this.nameDialog = true
    },

    saveName() {
      if (this.$refs.nameForm.validate()) {
        this.saveNameLoading = true
        this.nameDialog = false
        this.setName({ name: this.nameInput }).then(() => {
          this.saveNameLoading = false
        }).catch(() => {
          this.saveNameLoading = false
        })
      }
    },
  },
}
</script>
