import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ru from 'vuetify/es5/locale/ru'
import kk from '../i18n/locales/kk'
import { getLocale } from '../i18n'

Vue.use(Vuetify)

const options = {
  breakpoint: { scrollbarWidth: 12 },
  lang: {
    current: getLocale(),
    locales: { ru, kk },
  },
  theme: {
    dark: localStorage.getItem('rekassa.kz-ui-darkTheme') === null || localStorage.getItem('rekassa.kz-ui-darkTheme') === 'true',
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        reForeground: '#1E2E31',
        reBackground: '#152223',
        primary: '#15BAAA',
        secondary: '#21B56D',

        success: '#21B56D',
        error: '#E15858',
        danger: '#9A4266',
        orange: '#FB8C02',
      },

      light: {
        reForeground: '#F4F4F4',
        reBackground: '#FFFFFF',
        primary: '#15BAAA',
        secondary: '#21B56D',

        success: '#21B56D',
        error: '#E15858',
        danger: '#9A4266',
        orange: '#FB8C02',
      },
    },
  },
}

export default new Vuetify({
  ...options,
})
