<template lang="pug">
  span
    v-dialog(v-model="showDialog" scrollable persistent @keydown="handleDialogKeydown($event)" :fullscreen="$vuetify.breakpoint.xsOnly" :hide-overlay="$vuetify.breakpoint.xsOnly" width="500")
      v-card
        v-card-title.pa-0
          v-toolbar(dense flat dark v-if="$vuetify.breakpoint.xsOnly")
            v-spacer
            v-toolbar-title {{ editMode ? $t("редактирование") : $t("новая_позиция") }}
            v-spacer

        v-card-text.px-6.py-4
          v-form(ref="form")
            v-row
              v-col.py-1.py-sm-2(cols="12")
                v-textarea(:label="$t('наименование')" v-model="name" :messages="barcode" rows="1" :rules="[rules.required, rules.exciseStamp.potential]" counter="500" maxlength="500" validate-on-blur clearable auto-grow autocomplete="off")
                  template(v-slot:append-outer)
                    v-btn(icon :loading="positionsLoading" @click="positionsDialog = true")
                      v-icon mdi-format-list-bulleted
              v-col.py-1.py-sm-2(cols="12" v-if="preferences.exciseStampType === 'CIGARETTES' || preferences.exciseStampType === 'GROUP_A'")
                v-text-field(:label="`${$t('маркировка')} (${$t('exciseStampType.' + preferences.exciseStampType)})`" v-model="exciseStamp" :readonly="$isCordova()" :clearable="!$isCordova()" :rules="[rules.exciseStamp.length(preferences.exciseStampType, exciseStamp), rules.exciseStamp.cyrillic]" validate-on-blur autocomplete="off" placeholder=" " :counter="exciseStampCharLimit" :maxlength="exciseStampCharLimit")
                  template(v-if="$isCordova()" v-slot:append-outer)
                    v-btn(icon @click="scanDataMatrix()")
                      v-icon mdi-qrcode-scan
              v-col.py-1.py-sm-2(cols="6")
                re-numeric-input-field(:label="$t('количество')" v-model="quantity" limit-decimal="3" :rules="[rules.numberRange(0.01, 1000000, quantity)]" validate-on-blur autocomplete="off" :disabled="disabledQuantity")
                  template(v-slot:append-outer)
                    v-btn(icon @click="increaseQuantity()")
                      v-icon mdi-plus
                    v-btn(icon @click="decreaseQuantity()")
                      v-icon mdi-minus
              v-col.py-1.py-sm-2(cols="6")
                v-select(:label="$t('единица_измерения')" v-model="unitType" :items="unitTypes" item-value="value" item-text="title")
              v-col.py-1.py-sm-2(cols="6")
                re-numeric-input-field(:label="$t('стоимость')" v-model="price" limit-decimal="2" :rules="[rules.numberRange(0.01, 1000000000, price)]" suffix="₸" validate-on-blur autocomplete="off" :autofocus="!$isCordova()")
              v-col.py-1.py-sm-2(cols="6")
                v-text-field(:label="$t('итого')" color="primary" persistent-hint :value="`${$options.filters.numeral(total, '0,0.[00]')}`" :rules="[rules.numberRange(0.01, 1000000000, total)]" suffix="₸" disabled outlined)
              v-col.pt-0(cols="12")
                v-divider
              v-col.py-1.py-sm-2(cols="6")
                v-select(:label="$t('тип')" v-model="discountMarkupType" :items="discountMarkupTypes" item-value="value" item-text="title")
              v-col.py-1.py-sm-2(cols="6")
                re-numeric-input-field(:label="$t('discountMarkupType.' + discountMarkupType)" :suffix="discountMarkupValueTitles.suffix" v-model="discountMarkupValue" limit-decimal="2" :rules="[rules.numberRange(0, 100000000, discountMarkupValue)]" validate-on-blur autocomplete="off")
              template(v-if="configuration.taxMode")
                v-col.pt-0(cols="12")
                  v-divider
                v-col.py-1.py-sm-2(cols="6")
                  v-select(:label="$t('ставка_ндс')" v-model="taxType" :items="taxTypes" item-value="rate" item-text="title")
                v-col.py-1.py-sm-2(cols="6")
                  v-text-field(:label="$t('в_том_числе_ндс')" :value="`${taxType >= 0 ? $options.filters.numeral(tax, '0,0.[00]') : '-'}`" suffix="₸" disabled outlined)

        v-divider
        v-card-actions
          v-btn(v-if="editMode" text color="red" @click="deleteItem()") {{ $t('удалить') }}
          v-spacer
          v-btn(outlined @click="showDialog = false") {{ $t('отменить') }}
          v-btn(v-if="!editMode" color="primary" @click="addItem()") {{ $t('добавить') }}
          v-btn(v-if="editMode" color="primary" @click="saveItem()") {{ $t('сохранить') }}

    re-positions(v-model="positionsDialog" @positionSelected="positionSelected")

    re-scandit(v-model="scanditDialog" @exciseStampScanned="exciseStampScanned")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import fdo from '@comrun/kfdo'
import { Decimal } from 'decimal.js'
import NumericInputField from '../../utils/NumericInputField.vue'
import Positions from './Positions.vue'
import Scandit from './Scandit.vue'
import dictionaryMixin from '../../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../../mixins/billsAndCoinsMixin'
import i18n from '../../../i18n/index'

export default {
  components: {
    're-positions': Positions,
    're-numeric-input-field': NumericInputField,
    're-scandit': Scandit,
  },

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    preNewItem: {
      type: Object,
      default: null,
      required: false,
    },
  },

  data: () => ({
    showDialog: false,
    editMode: false,

    name: i18n.t('позиция'),
    barcode: null,
    exciseStamp: null,
    quantity: 1,
    unitType: 'PIECE',
    price: 0,
    taxType: 12,
    discountMarkupType: 'DISCOUNT_PERCENTAGE',
    discountMarkupValue: 0,

    positionsDialog: false,

    disabledQuantity: false,

    scanditDialog: false,
  }),

  computed: {
    ...mapState({
      positionsLoading: state => state.positions.loading,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    totalWithoutDiscountMarkup() {
      let total = 0
      if (this.price > 0 && this.quantity > 0) {
        total = new Decimal(this.price).times(this.quantity).toNumber().toFixed(2)
      }
      return total
    },

    discountMarkup() {
      let discountMarkup = 0
      if (this.discountMarkupValue > 0) {
        switch (this.discountMarkupType) {
          case 'DISCOUNT_PERCENTAGE':
            discountMarkup = new Decimal(this.totalWithoutDiscountMarkup)
              .dividedBy(100)
              .times(this.discountMarkupValue)
              .times(-1)
              .toNumber()
              .toFixed(2)
            break
          case 'DISCOUNT_CURRENCY':
            discountMarkup = new Decimal(this.discountMarkupValue)
              .times(-1)
              .toNumber()
              .toFixed(2)
            break
          case 'MARKUP_PERCENTAGE':
            discountMarkup = new Decimal(this.totalWithoutDiscountMarkup)
              .dividedBy(100)
              .times(this.discountMarkupValue)
              .toNumber()
              .toFixed(2)
            break
          case 'MARKUP_CURRENCY':
            discountMarkup = new Decimal(this.discountMarkupValue)
              .toNumber()
              .toFixed(2)
            break
          default:
            break
        }
      }
      return discountMarkup
    },

    total() {
      return new Decimal(this.totalWithoutDiscountMarkup).plus(this.discountMarkup).toNumber().toFixed(2)
    },

    commodityTax() {
      return this.totalWithoutDiscountMarkup > 0 ? new Decimal(this.totalWithoutDiscountMarkup).dividedBy(new Decimal(this.taxType).add(100)).times(this.taxType)
        .toNumber()
        .toFixed(2) : 0
    },

    discountMarkupTax() {
      return this.discountMarkup !== 0 ? new Decimal(this.discountMarkup).dividedBy(new Decimal(this.taxType).add(100)).times(this.taxType)
        .toNumber()
        .toFixed(2) : 0
    },

    tax() {
      return new Decimal(this.commodityTax).plus(this.discountMarkupTax).toNumber().toFixed(2)
    },

    discountMarkupValueTitles() {
      return this.discountMarkupTypes.find((item) => item.value === this.discountMarkupType)
    },

    exciseStampCharLimit() {
      if (this.preferences.exciseStampType === 'CIGARETTES') {
        return 21
      } if (this.preferences.exciseStampType === 'GROUP_A') {
        return 31
      }
      return 0
    },
  },

  watch: {
    value(value) {
      if (value) {
        if (this.item) {
          this.editMode = true
          this.setItem()
          this.showDialog = true
        } else if (this.preNewItem) {
          this.name = this.preNewItem.name
          this.price = this.preNewItem.price
          this.unitType = this.preNewItem.unitType
          this.barcode = this.preNewItem.barcode
          this.editMode = false
          this.showDialog = true
          this.$nextTick(() => {
            this.addItem()
          })
        } else {
          this.name = this.preferences.defaultItemName
          this.unitType = this.preferences.defaultItemType
          this.taxType = this.dictionary.taxType[this.preferences.defaultTaxType].rate
          this.editMode = false
          this.showDialog = true
        }
      }
    },

    name(value) {
      if (!value) {
        this.barcode = null
      }
    },

    exciseStamp(value) {
      if (value) {
        this.quantity = 1
        this.disabledQuantity = true
      } else {
        this.disabledQuantity = false
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
      }
    },

    positionsDialog(value) {
      if (value) {
        this.$refs.form.resetValidation()
      }
    },
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    setItem() {
      this.name = this.item.commodity.name
      this.quantity = new Decimal(this.item.commodity.quantity).dividedBy(1000).toNumber()
      const unitType = this.item.commodity.auxiliary.find((aux) => aux.key === 'UNIT_TYPE')
      this.unitType = unitType ? unitType.value : 'PIECE'
      this.barcode = this.item.commodity.barcode
      this.price = this.getNumberFromBillsAndCoins(this.item.commodity.price)
      this.exciseStamp = this.item.commodity.exciseStamp

      // Item tax
      if (this.item.commodity.taxes && this.item.commodity.taxes[0]) {
        this.taxType = new Decimal(this.item.commodity.taxes[0].percent).dividedBy(1000).toNumber()
      } else if (this.configuration.taxMode) {
        this.taxType = -1 // Без НДС
      }

      // Item discount
      const discount = this.item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT')
      if (discount) {
        const discountType = discount.value.includes('%') ? 'PERCENTAGE' : 'VALUE'
        if (discountType === 'PERCENTAGE') {
          this.discountMarkupType = 'DISCOUNT_PERCENTAGE'
          this.discountMarkupValue = discount.value.replace('%', '')
        } else {
          this.discountMarkupType = 'DISCOUNT_CURRENCY'
          this.discountMarkupValue = discount.value
        }
      }

      // Item markup
      const markup = this.item.commodity.auxiliary.find((aux) => aux.key === 'MARKUP')
      if (markup) {
        const markupType = markup.value.includes('%') ? 'PERCENTAGE' : 'VALUE'
        if (markupType === 'PERCENTAGE') {
          this.discountMarkupType = 'MARKUP_PERCENTAGE'
          this.discountMarkupValue = markup.value.replace('%', '')
        } else {
          this.discountMarkupType = 'MARKUP_CURRENCY'
          this.discountMarkupValue = markup.value
        }
      }
    },

    addItem() {
      if (this.$refs.form.validate()) {
        this.$emit('addItem', this.buildItem())
        this.showDialog = false
      }
    },

    saveItem() {
      if (this.$refs.form.validate()) {
        this.$emit('saveItem', this.buildItem())
        this.showDialog = false
      }
    },

    deleteItem() {
      this.$emit('deleteItem')
      this.showDialog = false
    },

    buildItem() {
      const newItem = new fdo.kkm.proto.TicketRequest.Item({
        type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY,
        commodity: new fdo.kkm.proto.TicketRequest.Item.Commodity({
          name: this.name,
          barcode: this.barcode,
          sectionCode: '1',
          quantity: new Decimal(this.quantity).times(1000).toNumber(),
          price: this.getBillsAndCoinsMoney(this.price),
          sum: this.getBillsAndCoinsMoney(this.totalWithoutDiscountMarkup),
          exciseStamp: this.exciseStamp && this.exciseStamp.trim().length > 0 ? this.exciseStamp : null,
          measureUnitCode: this.unitTypes.find((ut) => ut.value === this.unitType).code,
          auxiliary: [
            new fdo.kkm.proto.KeyValuePair({
              key: 'UNIT_TYPE',
              value: this.unitType,
            }),
          ],
        }),
      })

      if (this.configuration.taxMode && this.taxType >= 0) {
        newItem.commodity.taxes = [
          new fdo.kkm.proto.TicketRequest.Tax({
            taxType: 100,
            taxationType: this.dictionary.taxationType[this.configuration.taxationType].code,
            percent: new Decimal(this.taxType).times(1000).toNumber(),
            sum: this.getBillsAndCoinsMoney(this.commodityTax),
            isInTotalSum: true,
          }),
        ]
      }

      if (this.discountMarkupValue > 0) {
        let type = null
        let value = null
        switch (this.discountMarkupType) {
          case 'DISCOUNT_PERCENTAGE':
            type = 'DISCOUNT'
            value = `${this.discountMarkupValue}%`
            break
          case 'DISCOUNT_CURRENCY':
            type = 'DISCOUNT'
            value = `${this.discountMarkupValue}`
            break
          case 'MARKUP_PERCENTAGE':
            type = 'MARKUP'
            value = `${this.discountMarkupValue}%`
            break
          case 'MARKUP_CURRENCY':
            type = 'MARKUP'
            value = `${this.discountMarkupValue}`
            break
          default:
            break
        }
        newItem.commodity.auxiliary.push(
          new fdo.kkm.proto.KeyValuePair({
            key: type,
            value,
          }),
        )
        if (this.configuration.taxMode && this.taxType >= 0) {
          newItem.commodity.auxiliary.push(
            new fdo.kkm.proto.KeyValuePair({
              key: 'DISCOUNT_MARKUP_TAX',
              value: `${this.discountMarkupTax}`,
            }),
          )
        }
      }

      return newItem
    },

    increaseQuantity() {
      this.quantity = new Decimal(this.quantity).plus(1).toNumber()
    },

    decreaseQuantity() {
      this.quantity = new Decimal(this.quantity).minus(1).equals(0) ? 1 : new Decimal(this.quantity).minus(1).toNumber()
    },

    handleDialogKeydown(event) {
      if (event.keyCode === 13) {
        if (this.editMode) {
          this.saveItem()
        } else {
          this.addItem()
        }
        event.preventDefault()
      }
    },

    scanDataMatrix() {
      this.scanditDialog = true
    },

    exciseStampScanned(exciseStamp) {
      this.exciseStamp = exciseStamp
    },

    positionSelected(position) {
      this.name = position.name
      this.unitType = position.unitType
      this.barcode = position.barcode
      if (position.price > 0) {
        this.price = position.price
      }
    },
  },
}
</script>
<style lang="stylus">
</style>
