<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/settings')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("настройки_принтера") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row.d-print-none(v-if="!$isFFBMode()")
        v-col(align="center")
          a.title(@click.prevent="openPartnerLink()") {{ $t("купить_принтер_со_скидкой") }}

      v-row.d-print-none
        v-col
          v-list(flat)
            template(v-if="!$isFFBMode()")
              v-subheader(v-if="$isCordova()") {{ $t("выбор_принтера_для_печати") }}
              v-list-item-group(v-if="$isCordova()")
                v-list-item(@click="openSelectBluetoothPrinterDialog()")
                  v-list-item-icon
                    v-icon(:color="printer.connectionType === 'bluetooth' ? 'success' : null") mdi-bluetooth
                  v-list-item-content
                    v-list-item-title Bluetooth
                  v-list-item-action
                    v-list-item-action-text.subtitle-1 {{ printer.bluetooth.name }}
                v-list-item(v-if="$isAndroid()" @click="openSelectWifiPrinterDialog()")
                  v-list-item-icon
                    v-icon(:color="printer.connectionType === 'wifi' ? 'success' : null")  mdi-wifi
                  v-list-item-content
                    v-list-item-title {{ $t("wi-fi_сетевой") }}
                  v-list-item-action
                    v-list-item-action-text.subtitle-1 {{ printer.wifi.name }}

              v-subheader {{ $t("настройки") }}
              v-list-item(@click="openSelectRollWidthDialog()")
                v-list-item-content
                  v-list-item-title {{ $t("ширина_ленты") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ dictionary.rollWidth[settings.rollWidth].title }}
              v-list-item(v-if="$isCordova()" @click="openScrollingLinesDialog()")
                v-list-item-content
                  v-list-item-title {{ $t("кол-во_строк_промотки") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ settings.scrollingLines }} {{ $t("стр") }}
              v-list-item(v-if="$isCordova()" @click="openSelectEncodingDialog()")
                v-list-item-content
                  v-list-item-title {{ $t("кодировка") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ dictionary.encoding[settings.encoding].title }}
              v-list-item(v-if="$isCordova()" @click="openSelectQrTypeDialog()")
                v-list-item-content
                  v-list-item-title {{ $t("печать_qr_кода") }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ dictionary.qrType[settings.qrType].title }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("автопечать_чека") }}
                v-list-item-action
                  v-switch(color="teal" v-model="settings.autoPrint" @change="autoPrintChanged()")
              v-list-item(v-if="$isCordova()")
                v-list-item-content
                  v-list-item-title {{ $t("поддержка_спецсимволов") }}
                v-list-item-action
                  v-switch(color="teal" v-model="settings.specialSymbols" @change="specialSymbolsChanged()")

            v-subheader {{ $t("настройки_чека") }}
            v-list-item(@click="logoEditorDialog = true")
              v-list-item-content
                v-list-item-title {{ $t("логотип") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ preferences.logoUrl ? $t("установлен") : '---' }}
            v-list-item(@click="openAdvertisingTextDialog()")
              v-list-item-content
                v-list-item-title {{ $t("рекламный_текст") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1.text-truncate {{ preferences.advertisingText ? preferences.advertisingText : '---' }}

      v-row.d-print-none
        v-col(align="center")
          v-btn(color="primary" block :loading="testPagePrinting" @click="printTestReceipt()") {{ $t("пробная_печать_чека") }}

    v-dialog(v-model="selectBluetoothPrinterDialog" scrollable max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_bluetooth_принтер") }}
        v-card-text.pt-0
          v-container.py-0
            template(v-if="$isAndroid()")
              v-row(v-if="bluetoothPrinterList && bluetoothPrinterList.length > 0")
                v-col.py-0
                  v-radio-group.mb-4(column v-model="selectedValue" :error-messages="bluetoothPrinterRadioError")
                    v-radio(:label="`${p.name} (${p.address})`" :value="p.address" v-for="(p, $index) in bluetoothPrinterList" :key="'p_' + $index")

                v-btn(block text color="primary" @click="openBluetoothSettings()") {{ $t("bluetooth_настройки") }}

              v-row(v-if="bluetoothPrinterList && bluetoothPrinterList.length <= 0")
                v-col.pt-0
                  h2.subtitle-1.mb-4 {{ $t("нет_подключенного_принтера") }}
                  h3.subtitle-2(v-html="$t('перейдите_в_bluetooth_dotdotdot')")

                  v-btn.mt-6(block color="primary" @click="openBluetoothSettings()") {{ $t("bluetooth_настройки") }}

            template(v-else)
              v-row
                v-col.py-0
                  v-radio-group(v-if="bluetoothPrinterList && bluetoothPrinterList.length > 0" column v-model="selectedValue" :disabled="bluetoothPrinterListLoading" :error-messages="bluetoothPrinterRadioError")
                    v-radio(:label="`${p.name} (${p.address.split('-')[4]})`" :value="p.address" v-for="(p, $index) in bluetoothPrinterList" :key="'p_' + $index")
                  h2.subtitle-1.mb-4.mt-3(v-if="!bluetoothPrinterListLoading && bluetoothPrinterList && bluetoothPrinterList.length <= 0") {{ $t("принтеры_не_найдены") }}

                v-btn.mt-6(block text :loading="bluetoothPrinterListLoading" color="primary" @click="refreshBluetoothPrinterList()") {{ $t("обновить_список") }}
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="selectBluetoothPrinterDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="selectBluetoothPrinter()" :disabled="selectBluetoothPrinterButtonDisabled") {{ $t("сохранить") }}

    v-dialog(v-model="selectWifiPrinterDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("укажите_адрес_ip_wi-fi_принтера") }}
        v-card-text
          v-container
            v-form(ref="printerForm" @submit.prevent="saveWifiPrinter()")
              v-row
                v-col(cols="12" sm="8")
                  v-text-field(v-model="selectedValue" :rules="[rules.ip]" :label="$t('адрес_ip')" validate-on-blur autocomplete="off" placeholder="192.168.1.X" clearable)
                v-col(cols="12" sm="4")
                  v-text-field(v-model="selectedValueAddon" type="number" :rules="[rules.required]" :label="$t('порт')" validate-on-blur autocomplete="off" placeholder="9100" clearable)
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="selectWifiPrinterDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="saveWifiPrinter()") {{ $t("сохранить") }}

    v-dialog(v-model="selectRollWidthDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_ширину_ленты") }}
        v-card-text
          v-container
            v-radio-group(v-model="selectedValue" column)
              v-radio(:label="dictionary.rollWidth[r].title" :value="r" v-for="(r, $index) in rollWidthList" :key="'r_' + $index")
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="selectRollWidthDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="selectRollWidth()") {{ $t("выбрать") }}

    v-dialog(v-model="selectScrollingLinesDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_кол-во_строк_промотки") }}
        v-card-text
          v-container
            v-form(ref="scrollingLinesForm" @submit.prevent="selectScrollingLines()")
              v-text-field(v-model="selectedValue" type="number" :rules="[rules.required]" label="Кол-во строк" validate-on-blur autocomplete="off" clearable)
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="selectScrollingLinesDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="selectScrollingLines()") {{ $t("сохранить") }}

    v-dialog(v-model="selectEncodingDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_кодировку") }}
        v-card-text
          v-container
            v-radio-group(v-model="selectedValue" column)
              v-radio(:value="e" v-for="(e, $index) in encodingList" :key="'e_' + $index")
                template(v-slot:label) {{ dictionary.encoding[e].title }}
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="selectEncodingDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="selectEncoding()") {{ $t("выбрать") }}

    v-dialog(v-model="selectQrTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_способ_печати_qr_кода") }}
        v-card-text
          v-container
            v-radio-group(v-model="selectedValue" column)
              v-radio(:label="dictionary.qrType[qr].title" :value="qr" v-for="(qr, $index) in qrSupportList" :key="'qr_' + $index")
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="selectQrTypeDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="selectQrType()") {{ $t("выбрать") }}

    v-dialog(v-model="advertisingTextDialog" max-width="400px" persistent)
      v-card
        v-card-title.pb-0
          span.headline {{ $t("введите_рекламный_текст") }}
        v-card-text
          v-container
            v-form.mt-3(ref="advertisingTextForm" @submit.prevent="saveAdvertisingText()")
              v-textarea(:label="$t('рекламный_текст')" v-model="advertisingText" type="text" rows="3" auto-grow validate-on-blur autocomplete="off" :autofocus="!$isCordova()" maxlength="500" counter="500" clearable)
        v-divider
        v-card-actions
          v-spacer
          v-btn(outlined @click="advertisingTextDialog = false") {{ $t("отменить") }}
          v-btn(color="primary" @click="saveAdvertisingText()") {{ $t("сохранить") }}

  re-logo-editor(v-model="logoEditorDialog")

  re-receipt.d-none.d-print-block(:ticket="demoTicket" :cashRegister="cashRegister" :organization="organization" :class="rollWidthClass" :ticketLocale="ticketLocale" :preTicket="false" :demoTicket="true")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment-timezone'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import i18n, { getLocale } from '../../i18n/index'
import Receipt from '../ticket/parts/Receipt.vue'
import LogoEditor from './LogoEditor.vue'

export default {
  components: {
    're-receipt': Receipt,
    're-logo-editor': LogoEditor,
  },

  mixins: [dictionaryMixin],

  data: () => ({
    advertisingText: null,
    advertisingTextDialog: false,

    logoEditorDialog: false,
    logoBase64: localStorage.getItem('rekassa.kz-printer-logo'),

    demoTicket: {
      id: 1,
      command: 'COMMAND_TICKET',
      ticketNumber: '12345',
      qrCode: 'https://rekassa.kz',
      offlineTicketNumber: null,
      shiftNumber: 123,
      shiftDocumentNumber: 123,
      status: 'OK',
      fdo: {
        code: 'TTK',
        nameKK: '«Транстелеком» АҚ ФДО',
        nameRu: 'ОФД АО «Транстелеком»',
        url: 'https://ofd1.kz',
      },
      operator: {
        code: 123,
      },
      data: {
        ticket: {
          items: [
            {
              type: 'ITEM_TYPE_COMMODITY',
              commodity: {
                sum: {
                  bills: '15000',
                  coins: 0,
                },
                name: 'Принтер для печати чеков',
                barcode: '191217015019',
                price: {
                  bills: '15000',
                  coins: 0,
                },
                quantity: 1000,
                auxiliary: [
                  {
                    key: 'UNIT_TYPE',
                    value: 'PIECE',
                  },
                ],
              },
            },
          ],
          amounts: {
            taken: {
              bills: '15000',
              coins: 0,
            },
            total: {
              bills: '15000',
              coins: 0,
            },
            change: {
              bills: '0',
              coins: 0,
            },
          },
          dateTime: {
            date: {
              day: parseInt(moment().format('D'), 10),
              month: parseInt(moment().format('M'), 10),
              year: parseInt(moment().format('YYYY'), 10),
            },
            time: {
              hour: parseInt(moment().format('HH'), 10),
              minute: parseInt(moment().format('mm'), 10),
              second: parseInt(moment().format('ss'), 10),
            },
          },
          operator: {
            code: 123,
          },
          payments: [
            {
              sum: {
                bills: '15000',
                coins: 0,
              },
              type: 'PAYMENT_CASH',
            },
          ],
          operation: 'OPERATION_SELL',
        },
      },
      offline: false,
      extra: {
        receipts: [],
      },
    },

    dictionary: {
      rollWidth: {
        58: {
          title: '58mm',
        },
        80: {
          title: (window.cordova !== undefined ? '80mm' : '80mm / A4'),
        },
      },
      encoding: {
        cp866: {
          title: 'CP866',
        },
        'Windows-1251': {
          title: 'Windows-1251',
        },
        'Chuck-Norris': {
          title: 'Chuck Norris',
        },
      },
      qrType: {
        escpos: {
          title: i18n.t('средствами_принтера'),
        },
        image: {
          title: i18n.t('изображением'),
        },
      },
    },

    printer: null,
    settings: null,

    selectedValue: null,
    selectedValueAddon: null,

    selectBluetoothPrinterDialog: false,
    bluetoothPrinterList: [],
    bluetoothPrinterRadioError: [],

    selectWifiPrinterDialog: false,

    selectScrollingLinesDialog: false,

    selectRollWidthDialog: false,
    rollWidthList: [
      58, 80,
    ],

    selectEncodingDialog: false,
    encodingList: ['cp866', 'Windows-1251', 'Chuck-Norris'],
    selectQrTypeDialog: false,
    qrSupportList: [
      'escpos', 'image',
    ],

    testPagePrinting: false,
    selectBluetoothPrinterButtonDisabled: false,

    ticketLocale: i18n.locale,

    bluetoothPrinterListLoading: false,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    locale() {
      return getLocale()
    },

    rollWidthClass() {
      return this.settings.rollWidth === 58 ? 'roll-width-58mm' : 'roll-width-80mm'
    },
  },

  created() {
    this.printer = this.$store.state.printer.printer
    this.settings = this.$store.state.printer.settings

    if (this.$isAndroid()) {
      document.addEventListener('resume', () => {
        this.initBluetoothPrinterList()
      }, false)
    }
  },

  beforeDestroy() {
    if (this.$isAndroid()) {
      window.removeEventListener('resume', () => {
        this.initBluetoothPrinterList()
      }, false)
    }
  },

  methods: {
    ...mapActions({
      setPrinter: 'printer/setPrinter',
      setSettings: 'printer/setSettings',
      getBluetoothPrinterList: 'printer/getBluetoothPrinterList',
      openBluetoothSettings: 'printer/openBluetoothSettings',
      printReceipt: 'printer/printReceipt',
      savePreferences: 'cashRegisters/savePreferences',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    openPartnerLink() {
      this.$openLink(`https://link.rekassa.kz/printer-discount-about-${this.locale}`, '_system')
      this.analyticsLogEvent({ eventName: 're_partner_atb_clicked' })
    },

    initBluetoothPrinterList() {
      this.getBluetoothPrinterList().then((printerList) => {
        if (printerList.length === 0) {
          this.printer.bluetooth = {
            name: null,
            address: null,
          }
          this.selectBluetoothPrinterButtonDisabled = true
        } else {
          this.bluetoothPrinterList = printerList
          this.selectBluetoothPrinterButtonDisabled = false
        }
      }, () => {
        // this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },
    openSelectBluetoothPrinterDialog() {
      if (this.$isAndroid()) {
        this.initBluetoothPrinterList()
      } else {
        this.refreshBluetoothPrinterList()
      }
      this.selectedValue = this.printer.bluetooth.address
      this.bluetoothPrinterRadioError = []
      this.selectBluetoothPrinterDialog = true
    },

    selectBluetoothPrinter() {
      const selectedPrinter = this.bluetoothPrinterList.find((p) => p.address === this.selectedValue)
      if (selectedPrinter) {
        this.printer.connectionType = 'bluetooth'
        this.printer.bluetooth = selectedPrinter
        this.setPrinter(this.printer)
        this.selectBluetoothPrinterDialog = false
      } else {
        this.bluetoothPrinterRadioError = this.$t('выберите_принтер')
      }
    },

    openSelectWifiPrinterDialog() {
      this.selectedValue = this.printer.wifi.address
      this.selectedValueAddon = this.printer.wifi.port
      this.selectWifiPrinterDialog = true
    },
    saveWifiPrinter() {
      if (this.$refs.printerForm.validate()) {
        this.printer.connectionType = 'wifi'
        this.printer.wifi.address = this.selectedValue
        this.printer.wifi.port = this.selectedValueAddon
        this.printer.wifi.name = `${this.selectedValue}:${this.selectedValueAddon}`
        this.setPrinter(this.printer)
        this.selectWifiPrinterDialog = false
      }
    },

    openSelectRollWidthDialog() {
      this.selectedValue = this.settings.rollWidth
      this.selectRollWidthDialog = true
    },
    selectRollWidth() {
      this.settings.rollWidth = this.selectedValue
      this.setSettings(this.settings)
      this.selectRollWidthDialog = false
    },

    openScrollingLinesDialog() {
      this.selectedValue = this.settings.scrollingLines
      this.selectScrollingLinesDialog = true
    },
    selectScrollingLines() {
      if (this.$refs.scrollingLinesForm.validate()) {
        this.settings.scrollingLines = this.selectedValue
        this.setSettings(this.settings)
        this.selectScrollingLinesDialog = false
      }
    },

    openSelectEncodingDialog() {
      this.selectedValue = this.settings.encoding
      this.selectEncodingDialog = true
    },
    selectEncoding() {
      this.settings.encoding = this.selectedValue
      this.setSettings(this.settings)
      this.selectEncodingDialog = false
    },

    openSelectQrTypeDialog() {
      this.selectedValue = this.settings.qrType
      this.selectQrTypeDialog = true
    },
    selectQrType() {
      this.settings.qrType = this.selectedValue
      this.setSettings(this.settings)
      this.selectQrTypeDialog = false
    },

    autoPrintChanged() {
      this.setSettings(this.settings)
    },

    specialSymbolsChanged() {
      this.setSettings(this.settings)
    },

    openAdvertisingTextDialog() {
      this.advertisingText = this.preferences.advertisingText
      this.advertisingTextDialog = true
    },
    saveAdvertisingText() {
      if (this.$refs.advertisingTextForm.validate()) {
        this.preferences.advertisingText = this.advertisingText
        this.advertisingTextDialog = false
        this.save()
      }
    },

    save() {
      this.savePreferences({
        cashRegister: this.cashRegister,
        preferences: this.preferences,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id)
      }).catch((error) => {
        this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
        this.selectCashRegister(this.cashRegister.id)
      })
    },

    refreshBluetoothPrinterList() {
      this.bluetoothPrinterListLoading = true
      this.selectBluetoothPrinterButtonDisabled = true
      this.getBluetoothPrinterList(this.printer && this.printer.bluetooth ? this.printer.bluetooth : null).then((printerList) => {
        if (printerList.length === 0) {
          this.printer.bluetooth = {
            name: null,
            address: null,
          }
          this.selectBluetoothPrinterButtonDisabled = true
          this.bluetoothPrinterListLoading = false
        } else {
          this.bluetoothPrinterList = printerList
          this.selectBluetoothPrinterButtonDisabled = false
          this.bluetoothPrinterListLoading = false
        }
      }, (error) => {
        this.bluetoothPrinterListLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image()
          img.setAttribute('crossOrigin', 'anonymous')
          img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx.fillStyle = 'white'
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            resolve(dataURL)
          }
          img.src = url
        } catch (error) {
          reject(error)
        }
      })
    },

    printTestReceipt() {
      if (this.$isCordova()) {
        this.printCordova()
      } else {
        this.printWeb()
      }
    },

    printWeb() {
      window.print()
    },

    async printCordova() {
      this.testPagePrinting = true

      const printCmds = []

      if (this.preferences.logoUrl) {
        printCmds.push('#align_left#')
        printCmds.push('#logo#')
        printCmds.push(' ')
        printCmds.push('#new_line#')
      }

      if (this.preferences.advertisingText) {
        printCmds.push('#align_center#')
        printCmds.push(this.preferences.advertisingText)
        printCmds.push('#new_line#')
        printCmds.push('#line#')
      }

      printCmds.push('#align_center#')
      printCmds.push(this.organization.businessName)
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('бин_иин', this.ticketLocale)} ${this.organization.businessId}`)
      printCmds.push('#new_line#')

      printCmds.push(this.cashRegister.pos.address)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('нефискальный_чек', this.ticketLocale).toUpperCase())
      printCmds.push('#new_line#')
      printCmds.push(this.$t('для_демонстрации', this.ticketLocale).toUpperCase())
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      printCmds.push(`${this.$t(`operationType.${this.demoTicket.data.ticket.operation}`, this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(`#${this.demoTicket.shiftDocumentNumber}`)
      printCmds.push(`${this.$t('смена', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.demoTicket.shiftNumber)

      printCmds.push('#new_line#')
      printCmds.push(`${this.$t('дата', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(new Date()).format('DD-MM-YYYY'))
      printCmds.push(`${this.$t('время', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(new Date()).format('HH:mm:ss'))
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('фп', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.demoTicket.ticketNumber)
      printCmds.push(`${this.$t('кассир', this.ticketLocale)} `)
      printCmds.push('#half_line#')
      printCmds.push(this.demoTicket.operator.code)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('рнм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.registrationNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('знм', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.serialNumber)
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')
      printCmds.push(`1. ${this.preferences.barcodeInPositions ? `${this.demoTicket.data.ticket.items[0].commodity.barcode} ` : ''}${this.demoTicket.data.ticket.items[0].commodity.name}`)

      printCmds.push('#new_line#')

      printCmds.push(`1.0 ${this.$t('unitType.PIECE', this.ticketLocale)} x 15000.00₸`)
      printCmds.push('#full_width#')
      printCmds.push('15000.00₸')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('стоимость', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('15000.00₸')
      printCmds.push('#new_line#')
      printCmds.push('#line#')

      printCmds.push(this.$t('итого', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('15000.00₸')
      printCmds.push('#new_line#')

      printCmds.push(this.$t('paymentType.PAYMENT_CASH', this.ticketLocale))
      printCmds.push('#full_width#')
      printCmds.push('15000.00₸')
      printCmds.push('#new_line#')
      printCmds.push('#line#')

      printCmds.push('#qr_code#')

      printCmds.push('#align_center#')
      printCmds.push(this.ticketLocale === 'ru' ? this.demoTicket.fdo.nameRu : this.demoTicket.fdo.nameKk)
      printCmds.push('#new_line#')
      printCmds.push(`${this.$t('для_проверки_чека_отсканируйте_qr_код', this.ticketLocale)} (${this.demoTicket.fdo.url})`)
      printCmds.push('#new_line#')

      printCmds.push('#line#')
      printCmds.push(this.$t('rekassakz_бесплатный_онлайн_кассовый_аппарат', this.ticketLocale))
      printCmds.push('#new_line#')

      const payload = {
        cmds: printCmds,
        qrCode: 'https://rekassa.kz/?o=ttk&i=991461307199&f=209169540155&s=1000.0&t=20220114T171141',
      }

      if (this.preferences.logoUrl) {
        const logoBase64 = await this.getBase64Image(this.preferences.logoUrl)
        payload.logoBase64 = logoBase64.replace(/^data:image\/[a-z]+;base64,/, '')
      }

      this.printReceipt(payload).then(() => {
        setTimeout(() => {
          this.testPagePrinting = false
        }, 500)
      }, (error) => {
        if (error && error.message === 'printerNotSelected') {
          this.showSnackbar({ message: this.$t('прежде_чем_напечатать_чек_необходимо_выбрать_принтер') })
        }
        this.testPagePrinting = false
      })
    },
  },
}
</script>
