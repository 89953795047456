export function initDeepLinkHandling(router) {
  window.cordova.plugins.UniversalLinks.subscribe('signin', (eventData) => {
    router.push(`/signin?token=${eventData.params.token}`, () => {})
  })

  window.cordova.plugins.UniversalLinks.subscribe('ofdreg', (eventData) => {
    router.push(`/ofdreg?id=${eventData.params.id}`, () => {})
  })

  window.cordova.plugins.UniversalLinks.subscribe('list', () => {
    router.push('/list', () => {})
  })

  window.cordova.plugins.UniversalLinks.subscribe('payment-methods', () => {
    router.push('/kkm/fdo/payment-methods', () => {})
  })

  window.cordova.plugins.UniversalLinks.subscribe('miniapp', (eventData) => {
    router.push(`/miniapp?name=${eventData.params.name}`, () => {})
  })
}

export function isDeepLink(url) {
  if (url.endsWith('.html')) return false
  const pattern = new RegExp(`^${process.env.VUE_APP_API_ROOT_URL}`)
  return pattern.test(url)
}

export function getDeepLinkRoute(url) {
  return url.replace(process.env.VUE_APP_API_ROOT_URL, '')
}
