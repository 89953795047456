import moment from 'moment'

const ffbPos = {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    payment({ }, payload) {
      return new Promise((resolve, reject) => {
        window.cordova.plugins.rekassaFfbPosUniPos.payment((result) => {
          const resultJson = JSON.parse(result)
          if (resultJson && resultJson.dateTime) {
            resultJson.dateTime = moment(resultJson.dateTime, 'ddd MMM DD HH:mm:ss [GMT]Z YYYY', 'en').format()
          }
          resolve(resultJson)
        }, (error) => {
          reject(error)
        }, {
          amount: payload.amount,
        })
      })
    },

    refund({ }, payload) {
      return new Promise((resolve, reject) => {
        window.cordova.plugins.rekassaFfbPosUniPos.refund((result) => {
          const resultJson = JSON.parse(result)
          if (resultJson && resultJson.dateTime) {
            resultJson.dateTime = moment(resultJson.dateTime, 'ddd MMM DD HH:mm:ss [GMT]Z YYYY', 'en').format()
          }
          resolve(resultJson)
        }, (error) => {
          reject(error)
        }, {
          amount: payload.amount,
          authCode: payload.authCode,
          rrnCode: payload.rrnCode,
        })
      })
    },
  },
}

export default ffbPos
