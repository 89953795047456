<template lang="pug">
  v-dialog(v-model="showDialog" scrollable persistent @keydown="handleDialogKeydown($event)" width="500")
    v-card
      v-card-text.px-6.py-4
        v-form(ref="form")
          v-row
            v-col.py-1(cols="12")
              re-numeric-input-field(:label="$t('paymentType.' + payment.type)" v-model="payment.sum" limit-decimal="2" :rules="[rules.numberRange(0, 100000000, payment.sum)]" suffix="₸" validate-on-blur autocomplete="off" :autofocus="!$isCordova()")
          v-row(v-if="payment.type === 'PAYMENT_CASH'")
            v-col.py-1(cols="12")
              v-btn.mr-4.mb-4.re-btn-glass-effect(v-for="(kzt, $index) in kzts" :key="'k_' + $index" @click="fillWith(kzt)" small color="reForeground lighten-1")
                .font-weight-bold {{ kzt | numeral('0,0.[00]') }} ₸

          v-row(v-if="(payment.type === 'PAYMENT_CARD' || payment.type === 'PAYMENT_MOBILE') && fillableSum > 0")
            v-col.py-1(cols="12")
              v-btn.mr-4.mb-4.re-btn-glass-effect(@click="fillWith(fillableSum)" small color="reForeground lighten-1")
                .font-weight-bold {{ fillableSum | numeral('0,0.[00]') }} ₸

      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="showDialog = false") {{ $t('отменить') }}
        v-btn(color="primary" @click="savePayment()") {{ $t('сохранить') }}

</template>
<script>
import { Decimal } from 'decimal.js'
import NumericInputField from '../../utils/NumericInputField.vue'
import dictionaryMixin from '../../../mixins/dictionaryMixin'

export default {
  components: {
    're-numeric-input-field': NumericInputField,
  },

  mixins: [dictionaryMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    total: {
      type: Number,
      default: null,
      required: true,
    },
    payment: {
      type: Object,
      default: null,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    paymentsSum: {
      default: null,
      required: false,
    },
  },

  data: () => ({
    showDialog: false,
    lastSum: 0,
    kzts: [
      500,
      1000,
      2000,
      5000,
      10000,
      20000,
    ],
  }),

  computed: {
    fillableSum() {
      return new Decimal(this.total).minus(this.paymentsSum).plus(this.lastSum).toNumber()
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.lastSum = this.payment.sum
        this.showDialog = true
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
  },

  methods: {
    savePayment() {
      if (this.$refs.form.validate()) {
        this.$emit('savePayment', this.payment)
        this.showDialog = false
      }
    },

    fillWith(sum) {
      this.payment.sum = sum
      this.savePayment()
    },

    handleDialogKeydown(event) {
      if (event.keyCode === 13) {
        this.savePayment()
        event.preventDefault()
      }
    },
  },
}
</script>
<style lang="stylus">
</style>
