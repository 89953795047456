import { Decimal } from 'decimal.js'
import dictionaryMixin from './dictionaryMixin'
import i18n from '../i18n/index'
import billsAndCoinsMixin from './billsAndCoinsMixin'

export default {
  mixins: [dictionaryMixin, billsAndCoinsMixin],

  created() {
    this.calculateTicketValues()
  },

  watch: {
    ticketData() {
      this.calculateTicketValues()
    },
  },

  computed: {
    // Commodity only items
    commodityItems() {
      return this.ticketData.items ? this.ticketData.items.reduce((items, item) => {
        if (item.type === 'ITEM_TYPE_COMMODITY') {
          // Main values
          this.processItemCommodity(item)

          // UnitType, Discount, Markup
          this.processItemUnitMarkupDiscount(item)

          // Taxes
          this.processItemTax(item)

          items.push(item)
        }
        return items
      }, []) : null
    },

    // Cash payment
    cashPayment() {
      // Exception for OPERATION_SELL_RETURN
      if (this.ticketData.operation === 'OPERATION_SELL_RETURN' || this.ticketData.operation === 'OPERATION_BUY') {
        const cashPayment = this.ticketData.payments.find((p) => p.type === 'PAYMENT_CASH')
        if (cashPayment) {
          return this.getNumberFromBillsAndCoins(cashPayment.sum)
        }
        return null
      }
      return this.ticketData.amounts && this.ticketData.amounts.taken ? this.getNumberFromBillsAndCoins(this.ticketData.amounts.taken) : 0
    },

    // All payments except cash
    otherPayments() {
      return this.ticketData.payments ? this.ticketData.payments.reduce((payments, payment) => {
        if (payment.type !== 'PAYMENT_CASH') {
          payment.sum.value = this.getNumberFromBillsAndCoins(payment.sum)
          payments.push(payment)
        }
        return payments
      }, []) : null
    },

    taxes() {
      return this.processTicketTaxes(this.ticketData.items)
    },

    isDuplicate() {
      return this.ticket.extra?.receipts?.some(r => r.type === 'PRINTER')
    },
  },

  methods: {
    processItemCommodity(item) {
      item.commodity.realQuantity = new Decimal(item.commodity.quantity).dividedBy(1000).toNumber()
      item.commodity.price.value = this.getNumberFromBillsAndCoins(item.commodity.price)
      item.commodity.sum.value = this.getNumberFromBillsAndCoins(item.commodity.sum)
      item.commodity.total = item.commodity.sum.value
    },

    processItemUnitMarkupDiscount(item) {
      if (item.commodity.auxiliary) {
        // Item unitType
        const unitType = item.commodity.auxiliary.find((aux) => aux.key === 'UNIT_TYPE')
        item.commodity.unitType = unitType ? unitType.value : 'PIECE'

        // SKU
        const sku = item.commodity.auxiliary.find((aux) => aux.key === 'SKU')
        item.commodity.sku = sku ? sku.value : null

        // Item discount
        const discount = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT')
        if (discount) {
          item.commodity.discountType = discount.value.includes('%') ? 'PERCENTAGE' : 'VALUE'

          if (item.commodity.discountType === 'PERCENTAGE') {
            item.commodity.discountPercentage = discount.value.replace('%', '')
            item.commodity.discountValue = new Decimal(item.commodity.discountPercentage).times(item.commodity.total).dividedBy(100).toNumber()
          } else {
            item.commodity.discountValue = discount.value
            item.commodity.discountPercentage = new Decimal(item.commodity.discountValue).dividedBy(item.commodity.total).times(100).toNumber()
          }

          item.commodity.total = new Decimal(item.commodity.total).minus(item.commodity.discountValue).toNumber()
        }

        // Item markup
        const markup = item.commodity.auxiliary.find((aux) => aux.key === 'MARKUP')
        if (markup) {
          item.commodity.markupType = markup.value.includes('%') ? 'PERCENTAGE' : 'VALUE'
          if (item.commodity.markupType === 'PERCENTAGE') {
            item.commodity.markupPercentage = markup.value.replace('%', '')
            item.commodity.markupValue = new Decimal(item.commodity.markupPercentage).times(item.commodity.total).dividedBy(100).toNumber()
          } else {
            item.commodity.markupValue = markup.value
            item.commodity.markupPercentage = new Decimal(item.commodity.markupValue).dividedBy(item.commodity.total).times(100).toNumber()
          }
          item.commodity.total = new Decimal(item.commodity.total).plus(item.commodity.markupValue).toNumber()
        }
      } else if (item.commodity.measureUnitCode) {
        const unitType = this.unitTypes.find((ut) => ut.code === item.commodity.measureUnitCode)
        if (unitType) {
          item.commodity.unitType = unitType.value
        }
      }
    },

    processItemTax(item) {
      if (item.commodity.taxes && item.commodity.taxes[0]) {
        item.commodity.taxes[0].sum.value = this.getNumberFromBillsAndCoins(item.commodity.taxes[0].sum)
        if (item.commodity.auxiliary) {
          const discountMarkupTax = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT_MARKUP_TAX')
          if (discountMarkupTax) {
            item.commodity.taxes[0].sum.value = new Decimal(item.commodity.taxes[0].sum.value).plus(discountMarkupTax.value).toNumber().toFixed(2)
          }
        }
      }
    },

    processTicketTaxes(items) {
      if (!items) return null

      const taxPercentTypes = new Set()
      // Get all tax types in ticket
      items.forEach(item => {
        if (item.type === 'ITEM_TYPE_COMMODITY' && item.commodity.taxes && item.commodity.taxes[0] && item.commodity.taxes[0].percent >= 0) {
          taxPercentTypes.add(item.commodity.taxes[0].percent)
        }
      })

      const taxes = []
      taxPercentTypes.forEach(taxPercent => {
        if (items) {
          taxes.push(
            {
              percent: (new Decimal(taxPercent).dividedBy(1000).toNumber()),
              value: items.reduce((sum, item) => {
                if (item.type === 'ITEM_TYPE_COMMODITY' && item.commodity.taxes && item.commodity.taxes[0] && item.commodity.taxes[0].percent === taxPercent) {
                  const discountMarkupTax = item.commodity.auxiliary ? item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT_MARKUP_TAX') : 0
                  return new Decimal(sum).plus(this.getNumberFromBillsAndCoins(item.commodity.taxes[0].sum)).plus(discountMarkupTax ? discountMarkupTax.value : 0)
                    .toNumber()
                    .toFixed(2)
                }
                return sum
              }, 0),
            },
          )
        }
      })

      return taxes.sort((a, b) => a.percent - b.percent)
    },

    calculateTicketValues() {
      // Ticket
      if (this.ticketData && this.ticketData.operation.includes('OPERATION')) {
        // Ticket dateTime
        if (this.ticketData.dateTime) {
          this.ticketData.dateTime.value = this.$moment(`${this.ticketData.dateTime.date.day}-${this.ticketData.dateTime.date.month}-${this.ticketData.dateTime.date.year} ${this.ticketData.dateTime.time.hour}:${this.ticketData.dateTime.time.minute}:${this.ticketData.dateTime.time.second}`, 'D-M-YYYY HH:mm:ss').toDate()
        }

        // Ticket amounts
        if (this.ticketData.amounts) {
          // Ticket total
          this.ticketData.amounts.total.value = this.getNumberFromBillsAndCoins(this.ticketData.amounts.total)

          // Taken CASH
          if (this.ticketData.amounts.taken) {
            this.ticketData.amounts.taken.value = this.getNumberFromBillsAndCoins(this.ticketData.amounts.taken)
          }

          // Change CASH
          if (this.ticketData.amounts.change) {
            this.ticketData.amounts.change.value = this.getNumberFromBillsAndCoins(this.ticketData.amounts.change)
          }

          // Ticket discount
          if (this.ticketData.amounts.discount) {
            this.ticketData.amounts.discount.value = this.getNumberFromBillsAndCoins(this.ticketData.amounts.discount.sum)
            const discount = this.ticketData.amounts.discount.auxiliary.find((aux) => aux.key === 'DISCOUNT')
            if (discount) {
              this.ticketData.amounts.discount.type = discount.value.includes('%') ? 'PERCENTAGE' : 'VALUE'

              if (this.ticketData.amounts.discount.type === 'PERCENTAGE') {
                this.ticketData.amounts.discount.percentage = discount.value.replace('%', '')
              }

              this.ticketData.amounts.discount.sumWithoutDiscount = new Decimal(this.ticketData.amounts.total.value).plus(this.ticketData.amounts.discount.value).toNumber().toFixed(2)
            }
          }
          // Ticket markup
          if (this.ticketData.amounts.markup) {
            this.ticketData.amounts.markup.value = this.getNumberFromBillsAndCoins(this.ticketData.amounts.markup.sum)
            const markup = this.ticketData.amounts.markup.auxiliary.find((aux) => aux.key === 'MARKUP')
            if (markup) {
              this.ticketData.amounts.markup.type = markup.value.includes('%') ? 'PERCENTAGE' : 'VALUE'

              if (this.ticketData.amounts.markup.type === 'PERCENTAGE') {
                this.ticketData.amounts.markup.percentage = markup.value.replace('%', '')
              }

              this.ticketData.amounts.markup.sumWithoutMarkup = new Decimal(this.ticketData.amounts.total.value).minus(this.ticketData.amounts.markup.value).toNumber().toFixed(2)
            }
          }
        }
      }

      // MoneyPlacement
      if (this.ticketData && this.ticketData.operation.includes('MONEY')) {
        // MoneyPlacement datetime (P.s. Setting value to "dateTime" to avoid "ifs" in receipt)
        if (this.ticketData.datetime) {
          this.ticketData.dateTime = {
            value: this.$moment(`${this.ticketData.datetime.date.day}-${this.ticketData.datetime.date.month}-${this.ticketData.datetime.date.year} ${this.ticketData.datetime.time.hour}:${this.ticketData.datetime.time.minute}:${this.ticketData.datetime.time.second}`, 'D-M-YYYY HH:mm:ss').toDate(),
          }
        }

        // MoneyPlacement amounts (P.s. Setting value to "amounts.total" to avoid "ifs" in receipt)
        if (this.ticketData.sum) {
          this.ticketData.amounts = {
            total: {
              value: this.getNumberFromBillsAndCoins(this.ticketData.sum),
            },
          }
        }
      }
    },

    orderNumber() {
      return this.ticketData?.extensionOptions?.auxiliary?.find((aux) => aux.key === 'ORDER_NUMBER')?.value
    },

    $tre(key, locale, params) {
      if (locale === 'ru' || locale === 'kk') {
        return i18n.t(key, locale, params)
      }
      return i18n.t(key, 'kk', params) !== i18n.t(key, 'ru', params) ? `${i18n.t(key, 'kk', params)} / ${i18n.t(key, 'ru', params)}` : i18n.t(key, 'ru', params)
    },
  },
}
