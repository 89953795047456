import QRCode from 'qrcode'
import { loadImage, createCanvas } from 'canvas'
import EscPosEncoder from './lib/esc-pos-encoder'
import i18n from '../i18n/index'

/// ///////////////////////////// Tools ////////////////////////////////
const service = '49535343-FE7D-4AE5-8FA9-9FAFD205E455'
const characteristic = '49535343-8841-43F4-A8D4-ECBE34729BB3'

function bufferToBase64(buf) {
  const binstr = Array.prototype.map.call(buf, (ch) => String.fromCharCode(ch)).join('')
  return btoa(binstr)
}

function getWhiteSpace(size, char) {
  if (size <= 0) return ''
  if (char === undefined) return ' '.repeat(size)
  return char.repeat(size)
}

function processSpecialSymbols(encoder, text) {
  [...text].forEach((char) => {
    switch (char) {
      case 'Ә':
        encoder.raw([0x1b, 0x26, 0x3, 0x41, 0x41, 0xc, 0x18, 0x3f, 0xe0, 0x38, 0x3f, 0xf0, 0x70, 0x30, 0x38, 0x60, 0x30, 0x18, 0x60, 0x30, 0x18, 0x60, 0x30, 0x18, 0x60, 0x30, 0x18, 0x70, 0x30, 0x38, 0x3f, 0xff, 0xf0, 0x1f, 0xff, 0xe0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x41, 0x1b, 0x25, 0x0])
        break
      case '₸':
        encoder.raw([0x1b, 0x26, 0x3, 0x54, 0x54, 0xc, 0x66, 0x0, 0x0, 0x66, 0x0, 0x0, 0x66, 0x0, 0x0, 0x66, 0x0, 0x0, 0x67, 0xff, 0xf8, 0x67, 0xff, 0xf8, 0x66, 0x0, 0x0, 0x66, 0x0, 0x0, 0x66, 0x0, 0x0, 0x66, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x54, 0x1b, 0x25, 0x0])
        break
      case 'ә':
        encoder.raw([0x1b, 0x26, 0x3, 0x61, 0x61, 0xc, 0x0, 0xc7, 0xe0, 0x1, 0xc7, 0xf0, 0x3, 0x86, 0x38, 0x3, 0x6, 0x18, 0x3, 0x6, 0x18, 0x3, 0x6, 0x18, 0x3, 0x6, 0x18, 0x3, 0x86, 0x38, 0x1, 0xff, 0xf0, 0x0, 0xff, 0xe0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x61, 0x1b, 0x25, 0x0])
        break
      case 'Қ':
        encoder.raw([0x1b, 0x26, 0x3, 0x51, 0x51, 0xc, 0x7f, 0xff, 0xf8, 0x7f, 0xff, 0xf8, 0x0, 0x18, 0x0, 0x0, 0x70, 0x0, 0x1, 0xf8, 0x0, 0x7, 0x9e, 0x0, 0x1e, 0x7, 0x80, 0x78, 0x1, 0xe0, 0x60, 0x0, 0x78, 0x0, 0x0, 0x1f, 0x0, 0x0, 0xf, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x51, 0x1b, 0x25, 0x0])
        break
      case 'қ':
        encoder.raw([0x1b, 0x26, 0x3, 0x71, 0x71, 0xc, 0x3, 0xff, 0xf8, 0x3, 0xff, 0xf8, 0x0, 0xc, 0x0, 0x0, 0x1e, 0x0, 0x0, 0x33, 0x0, 0x0, 0x61, 0x80, 0x0, 0xc0, 0xc0, 0x1, 0x80, 0x60, 0x3, 0x0, 0x38, 0x2, 0x0, 0x1f, 0x0, 0x0, 0xf, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x71, 0x1b, 0x25, 0x0])
        break
      case 'Ө':
        encoder.raw([0x1b, 0x26, 0x3, 0x4f, 0x4f, 0xc, 0x1f, 0xff, 0xe0, 0x3f, 0xff, 0xf0, 0x70, 0x30, 0x38, 0x60, 0x30, 0x18, 0x60, 0x30, 0x18, 0x60, 0x30, 0x18, 0x60, 0x30, 0x18, 0x70, 0x30, 0x38, 0x3f, 0xff, 0xf0, 0x1f, 0xff, 0xe0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x4f, 0x1b, 0x25, 0x0])
        break
      case 'ө':
        encoder.raw([0x1b, 0x26, 0x3, 0x6f, 0x6f, 0xc, 0x0, 0xff, 0xe0, 0x1, 0xff, 0xf0, 0x3, 0x8c, 0x38, 0x3, 0xc, 0x18, 0x3, 0xc, 0x18, 0x3, 0xc, 0x18, 0x3, 0xc, 0x18, 0x3, 0x8c, 0x38, 0x1, 0xff, 0xf0, 0x0, 0xff, 0xe0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x6f, 0x1b, 0x25, 0x0])
        break
      case 'Ұ':
        encoder.raw([0x1b, 0x26, 0x3, 0x55, 0x55, 0xc, 0x70, 0x0, 0x0, 0x7e, 0x0, 0x0, 0xf, 0xc3, 0x0, 0x1, 0xf3, 0x0, 0x0, 0x3f, 0xf8, 0x0, 0x3f, 0xf8, 0x1, 0xf3, 0x0, 0xf, 0xc3, 0x0, 0x7e, 0x0, 0x0, 0x70, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x55, 0x1b, 0x25, 0x0])
        break
      case 'ұ':
        encoder.raw([0x1b, 0x26, 0x3, 0x75, 0x75, 0xc, 0x3, 0x80, 0x0, 0x3, 0xf0, 0x0, 0x0, 0x7e, 0x18, 0x0, 0xf, 0x98, 0x0, 0x1, 0xff, 0x0, 0x1, 0xff, 0x0, 0xf, 0x98, 0x0, 0x7e, 0x18, 0x3, 0xf0, 0x0, 0x3, 0x80, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x75, 0x1b, 0x25, 0x0])
        break
      case 'Ү':
        encoder.raw([0x1b, 0x26, 0x3, 0x59, 0x59, 0xc, 0x70, 0x0, 0x0, 0x7e, 0x0, 0x0, 0xf, 0xc0, 0x0, 0x1, 0xf0, 0x0, 0x0, 0x3f, 0xf8, 0x0, 0x3f, 0xf8, 0x1, 0xf0, 0x0, 0xf, 0xc0, 0x0, 0x7e, 0x0, 0x0, 0x70, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x59, 0x1b, 0x25, 0x0])
        break
      case 'ү':
        encoder.raw([0x1b, 0x26, 0x3, 0x79, 0x79, 0xc, 0x3, 0x80, 0x0, 0x3, 0xf0, 0x0, 0x0, 0x7e, 0x0, 0x0, 0xf, 0x80, 0x0, 0x1, 0xff, 0x0, 0x1, 0xff, 0x0, 0xf, 0x80, 0x0, 0x7e, 0x0, 0x3, 0xf0, 0x0, 0x3, 0x80, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x79, 0x1b, 0x25, 0x0])
        break
      case 'Ғ':
        encoder.raw([0x1b, 0x26, 0x3, 0x47, 0x47, 0xc, 0x0, 0xc0, 0x0, 0x7f, 0xff, 0xf8, 0x7f, 0xff, 0xf8, 0x60, 0xc0, 0x0, 0x60, 0xc0, 0x0, 0x60, 0xc0, 0x0, 0x60, 0xc0, 0x0, 0x60, 0xc0, 0x0, 0x60, 0x0, 0x0, 0x60, 0x0, 0x0, 0x60, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x47, 0x1b, 0x25, 0x0])
        break
      case 'ғ':
        encoder.raw([0x1b, 0x26, 0x3, 0x67, 0x67, 0xc, 0x0, 0xc, 0x0, 0x3, 0xff, 0xf8, 0x3, 0xff, 0xf8, 0x3, 0xc, 0x0, 0x3, 0xc, 0x0, 0x3, 0xc, 0x0, 0x3, 0x0, 0x0, 0x3, 0x0, 0x0, 0x3, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x67, 0x1b, 0x25, 0x0])
        break
      case 'Ң':
        encoder.raw([0x1b, 0x26, 0x3, 0x4e, 0x4e, 0xc, 0x7f, 0xff, 0xf8, 0x7f, 0xff, 0xf8, 0x0, 0x30, 0x0, 0x0, 0x30, 0x0, 0x0, 0x30, 0x0, 0x0, 0x30, 0x0, 0x0, 0x30, 0x0, 0x0, 0x30, 0x0, 0x7f, 0xff, 0xf8, 0x7f, 0xff, 0xff, 0x0, 0x0, 0xf, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x4e, 0x1b, 0x25, 0x0])
        break
      case 'ң':
        encoder.raw([0x1b, 0x26, 0x3, 0x6e, 0x6e, 0xc, 0x3, 0xff, 0xf8, 0x3, 0xff, 0xf8, 0x0, 0xc, 0x0, 0x0, 0xc, 0x0, 0x0, 0xc, 0x0, 0x0, 0xc, 0x0, 0x0, 0xc, 0x0, 0x0, 0xc, 0x0, 0x3, 0xff, 0xf8, 0x3, 0xff, 0xff, 0x0, 0x0, 0xf, 0x0, 0x0, 0x0])
        encoder.raw([0x1b, 0x25, 0x1, 0x6e, 0x1b, 0x25, 0x0])
        break
      default:
        encoder.text(char)
        break
    }
  })
}

function replaceSpecialSymbols(char) {
  switch (char) {
    case 'Ә':
      return 'А'
    case 'ә':
      return 'а'
    case 'Қ':
      return 'К'
    case 'қ':
      return 'к'
    case 'Ө':
      return 'О'
    case 'ө':
      return 'о'
    case 'Ұ':
      return 'У'
    case 'ұ':
      return 'у'
    case 'Ү':
      return 'Y'
    case 'ү':
      return 'y'
    case 'Ғ':
      return 'Г'
    case 'ғ':
      return 'г'
    case 'Ң':
      return 'Н'
    case 'ң':
      return 'н'
    case '₸':
      return 'Т'
    default:
      return char
  }
}

function replaceGeneral(text) {
  return text.replace(/»/g, '"')
    .replace(/«/g, '"')
    .replace(/і/g, 'i')
    .replace(/І/g, 'I')
    .replace(/һ/g, 'h')
    .replace(/Һ/g, 'h')
}

function processText(encoder, text, specialSymbols) {
  text = replaceGeneral(text)
  if (specialSymbols) {
    processSpecialSymbols(encoder, text)
  } else {
    [...text].forEach(char => encoder.text(replaceSpecialSymbols(char)))
  }
}

// create QRCode Image
async function getQRCode(content) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line consistent-return
    QRCode.toDataURL(content, { errorCorrectionLevel: 'L' }, (err, url) => {
      if (err) { return reject(err) }
      loadImage(url).then(resolve, reject)
    })
  })
}

/// ///////////////////////////// Inner functions ////////////////////////////////
function initialize() {
  return new Promise((resolve, reject) => {
    window.bluetoothle.initialize(success => resolve(success), error => reject(error))
  })
}

function findPeripheralAddressList() {
  return new Promise((resolve, reject) => {
    const printerList = []
    const unique = {}
    window.bluetoothle.startScan((success) => {
      if (success.address && unique[success.address] === undefined && success.name) {
        printerList.push({ address: success.address, name: success.name })
        unique[success.address] = success.name
      }
    }, (error) => { reject(error) }, { services: [] })
    setTimeout(() => {
      window.bluetoothle.stopScan(() => {
        resolve(printerList.sort((a, b) => b.address > a.address))
      }, (error) => { reject(error) })
    }, 3000)
  })
}

function connect(address) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error(i18n.t('проверьте_подключение_принтера')))
    }, 5000)
    window.bluetoothle.connect(success => {
      clearTimeout(timeout)
      resolve(success)
    }, error => {
      clearTimeout(timeout)
      reject(error)
    }, { address })
  })
}

function disconnect(address) {
  return new Promise((resolve, reject) => {
    window.bluetoothle.disconnect(success => resolve(success), error => reject(error), { address })
  })
}

function close(address) {
  return new Promise((resolve, reject) => {
    window.bluetoothle.close(success => resolve(success), error => reject(error), { address })
  })
}

function services(address) {
  return new Promise((resolve, reject) => {
    window.bluetoothle.services(success => resolve(success), error => reject(error), { address })
  })
}

function characteristics(address) {
  return new Promise((resolve, reject) => {
    window.bluetoothle.characteristics(success => resolve(success), error => reject(error), { address, service })
  })
}

function getDivisibleBy8(width) {
  for (let w = width; w <= 2048; w += 1) {
    if (w % 8 === 0) {
      return w
    }
  }
  return 2048
}

async function print(payload) {
  const { qrType, qrCode, logoBase64 } = payload

  let logoImage
  if (logoBase64) {
    logoImage = await loadImage(`data:image/png;base64,${logoBase64}`)
  }

  let qrCodeImage
  if (qrCode && qrType === 'image') {
    qrCodeImage = await getQRCode(qrCode)
  }

  return new Promise((resolve, reject) => {
    const { address } = payload
    const paperWidth = payload.rollWidth
    const { specialSymbols } = payload
    const { encoding } = payload
    const { scrollingLines } = payload
    const { cmds } = payload
    const width = paperWidth === 58 ? (paperWidth - 27) : (paperWidth - 34)
    const halfWidth = width / 2

    const encoder = new EscPosEncoder()

    encoder.chuchNorrisPrintRow = (enc, align, text) => {
      const canvas = createCanvas(paperWidth === 58 ? 384 : 576, 24)
      const ctx = canvas.getContext('2d')
      ctx.font = '20px Menlo-Regular'
      ctx.textBaseline = 'bottom'

      if (align === 'left') {
        ctx.textAlign = 'left'
        ctx.fillText(text, 0, 24)
      } else {
        ctx.textAlign = 'center'
        ctx.fillText(text, paperWidth === 58 ? 192 : 288, 24)
      }
      enc.image(canvas, paperWidth === 58 ? 384 : 576, 24)
    }
    encoder.chuchNorrisPrintText = (enc, align, text) => {
      if (text.length > (paperWidth === 58 ? 32 : 48)) {
        const chunks = paperWidth === 58 ? text.match(/.{1,32}/g) : text.match(/.{1,48}/g)
        chunks.forEach((chunk) => {
          enc.chuchNorrisPrintRow(enc, align, chunk)
        })
      } else {
        enc.chuchNorrisPrintRow(enc, align, text)
      }
    }

    encoder.initialize()
    encoder.raw([0x1b, 0x33, 0x0]) // Line spacing 0
    encoder.raw([0x1c, 0x2e]) // Cancel Kanji character mode
    if (encoding === 'Windows-1251') {
      encoder.codepage('windows1251')
    } else {
      encoder.codepage('cp866')
    }

    // Chuck-Norris mode - печать картинкой
    if (encoding === 'Chuck-Norris') {
      let textBuffer = ''
      let alignment = 'left'

      for (let index = 0; index < cmds.length; index += 1) {
        const cmd = cmds[index]

        if (cmd !== null && cmd !== undefined) {
          if ((index - 2) >= 0 && cmds[index - 2] !== undefined && cmds[index - 2] === '#half_line#') {
            textBuffer += getWhiteSpace(1)
          }

          switch (cmd) {
            case '#align_center#':
              alignment = 'center'
              break
            case '#align_left#':
              alignment = 'left'
              break
            case '#align_right#':
              alignment = 'center'
              break
            case '#new_line#':
              encoder.chuchNorrisPrintText(encoder, alignment, textBuffer)
              textBuffer = ''
              break
            case '#line#':
              encoder.chuchNorrisPrintText(encoder, 'left', paperWidth === 58 ? '--------------------------------' : '-----------------------------------------------')
              break
            case '#half_line#':
              textBuffer += getWhiteSpace(halfWidth - cmds[index - 1].length - cmds[index + 1].length)
              break
            case '#full_width#':
              textBuffer += getWhiteSpace(width - cmds[index - 1].length - cmds[index + 1].length + (paperWidth === 58 ? 0 : 1))
              break
            case '#full_width_dot#':
              textBuffer += getWhiteSpace(width - cmds[index - 1].length - cmds[index + 1].length, '.')
              break
            case '#logo#':
              if (logoImage) {
                encoder.align('center')
                encoder.image(logoImage, logoImage.width, getDivisibleBy8(logoImage.height))
              }
              break
            case '#qr_code#':
              encoder.align('center')
              if (qrType === 'escpos') {
                encoder.qrcode(qrCode, 1, 4, 'l')
              } else {
                encoder.image(qrCodeImage, 160, 160)
              }
              encoder.newline()
              break
            default:
              textBuffer += cmd
              break
          }
        }
      }

      encoder.chuchNorrisPrintText(encoder, 'left', '')
      encoder.chuchNorrisPrintText(encoder, 'left', '')

      for (let index = 0; index < scrollingLines; index += 1) {
        encoder.chuchNorrisPrintText(encoder, 'left', '')
      }
    } else {
      // Печать стандартными средствами принтера
      for (let index = 0; index < cmds.length; index += 1) {
        const cmd = cmds[index]

        if (cmd !== null && cmd !== undefined) {
          if ((index - 2) >= 0 && cmds[index - 2] !== undefined && cmds[index - 2] === '#half_line#') {
            encoder.text(getWhiteSpace(1))
          }

          switch (cmd) {
            case '#align_center#':
              encoder.align('center')
              break
            case '#align_left#':
              encoder.align('left')
              break
            case '#align_right#':
              encoder.align('right')
              break
            case '#new_line#':
              encoder.newline()
              break
            case '#line#':
              encoder.text(paperWidth === 58 ? '--------------------------------' : '-----------------------------------------------')
              encoder.newline()
              break
            case '#half_line#':
              encoder.text(getWhiteSpace(halfWidth - cmds[index - 1].length - cmds[index + 1].length))
              break
            case '#full_width#':
              encoder.text(getWhiteSpace(width - cmds[index - 1].length - cmds[index + 1].length + (paperWidth === 58 ? 0 : 1)))
              break
            case '#full_width_dot#':
              getWhiteSpace(width - cmds[index - 1].length - cmds[index + 1].length, '.')
              break
            case '#logo#':
              if (logoImage) {
                encoder.align('center')
                encoder.image(logoImage, logoImage.width, getDivisibleBy8(logoImage.height))
              }
              break
            case '#qr_code#':
              encoder.align('center')
              if (qrType === 'escpos') {
                encoder.qrcode(qrCode, 1, 4, 'l')
              } else {
                encoder.image(qrCodeImage, 160, 160)
              }
              encoder.newline()
              break
            default:
              if (cmd) processText(encoder, String(cmd), specialSymbols)
              break
          }
        }
      }

      encoder.newline()
      encoder.newline()

      for (let index = 0; index < scrollingLines; index += 1) {
        encoder.newline()
      }
    }

    encoder.cut()

    window.bluetoothle.writeQ(() => { resolve(1) }, (error) => { reject(error.message) }, {
      value: bufferToBase64(encoder.encode()), address, service, characteristic, chunkSize: 100,
    })
  })
}

/// ///////////////////////////// Functions ////////////////////////////////
const bluetoothBle = {
  async getBluetoothPrinterList(success, error, printer) {
    try {
      const initializeResult = await initialize()
      if (initializeResult && initializeResult.status === 'disabled') error(i18n.t('требуется_включить_bluetooth'))

      try {
        if (printer && printer.address) {
          await disconnect(printer.address)
        }
      } catch (e) {
        console.debug(e)
      }

      const printerList = await findPeripheralAddressList()
      success(printerList)
    } catch (e) {
      if (e && e.message) {
        error(e.message)
      } else error(e)
    }
  },

  async printTextArray(success, error, payload) {
    try {
      const initializeResult = await initialize()
      if (initializeResult && initializeResult.status === 'disabled') {
        error(i18n.t('требуется_включить_bluetooth'))
        return
      }
    } catch (e) {
      if (e && e.message) {
        error(e.message)
      } else error(e)
      return
    }

    try {
      await connect(payload.address)
    } catch (e) {
      if (e && e.message === 'Device previously connected, reconnect or close for new connection') {
        console.debug('Device previously connected, reconnect or close for new connection')
      } else if (e && e.message) {
        error(e.message)
        return
      } else {
        error(e)
        return
      }
    }

    try {
      await services(payload.address)
      await characteristics(payload.address)

      const printResult = await print(payload)
      success(printResult)
    } catch (e) {
      if (e && e.message === 'Device isn\'t connected') {
        try {
          if (payload.address) {
            await close(payload.address)
          }
        } catch (e2) {
          if (e2 && e2.message) console.debug(e2.message)
        }
        success(-1)
      } else if (e && e.message) {
        error(e.message)
      } else error(e)
    }
  },
}

export default bluetoothBle
