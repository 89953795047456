<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/services')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("FREEDOM_LIFE") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pt-5(align="center")
          v-img.rounded-xl(:src="`/static/freedom-life-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("FREEDOM_LIFE") }}
          .subtitle-1.my-2 {{ $t("обязательное_страхование_сотрудников") }}
          .subtitle-2.mt-4 {{ $t("согласно_закону_рк_об_обязательном_страховании_работника_от_несчастных_dotdotdot") }}

          .subtitle-1.mt-6 {{ $t("страховые_выплаты") }}
      v-row
        v-col.pt-0
          v-list.re-v-list-fulltext.rounded-t-lg.rounded-b-lg
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-clock-fast
              v-list-item-content
                v-list-item-title {{ $t("ускоренная_процедура_заключения_договора") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-cash-multiple
              v-list-item-content
                v-list-item-title {{ $t("финансовая_защита_работника") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-calendar-month
              v-list-item-content
                v-list-item-title {{ $t("своевременные_выплаты") }}

      v-row
        v-col
          .subtitle-1.my-2 {{ $t("что_необходимо_для_заключения_договора") }}:
          ul
            li.subtitle-1 {{ $t("список_работников_предприятия") }}
            li.subtitle-1 {{ $t("реквизиты_компании") }}
            li.subtitle-1 {{ $t("фонд_оплаты_труда") }}
            li.subtitle-1 {{ $t("количество_сотрудников") }}
            li.subtitle-1 {{ $t("вид_деятельности_предприятия_и_номер_окэд") }}

      v-row
        v-col.pb-0.pt-4
          .subtitle-2 {{ $t("оставить_заявку") }}

      v-row
        v-col
          v-form(ref="form")
            v-text-field(ref="iinInput" :label="$t('иин')" v-model="iin" @input="iinError = null" :error-messages="iinError" v-mask="'### ### ### ###'" placeholder="### ### ### ###" type="tel" clearable)
            v-text-field(:label="$t('город')" v-model="city" @input="cityError = null" :error-messages="cityError" clearable)
            v-text-field(:label="$t('номер_телефона')" v-model="phoneNumber" @input="phoneNumberError = null" :error-messages="phoneNumberError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" clearable)

            v-btn.mt-4(color="secondary" block @click="submitApplication()") {{ $t("продолжить") }}

      v-row
        v-col
          .body-2 {{ $t("тоо_comrun_размещает_данную_информацию_в_рамках_партнерской_программы") }}

</template>
<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'

export default {
  data: () => ({
    iin: null,
    iinError: null,
    city: null,
    cityError: null,
    phoneNumber: null,
    phoneNumberError: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    if (this.cashRegister.status !== 'TRIAL') {
      this.iin = this.organization.businessId
    }
    this.phoneNumber = this.user.phone
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    submitApplication() {
      if (this.iin?.length !== 15) {
        this.iinError = this.$t('введите_иин')
        return
      }
      if (this.city === null || this.city.length < 3) {
        this.cityError = this.$t('введите_город')
        return
      }
      if (this.phoneNumber?.length !== 18) {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        return
      }

      const url = 'https://api.sheetson.com/v2/sheets/freedomLife'
      const data = {
        iin: this.iin.replace(/\D/g, ''),
        city: this.city,
        phone: this.phoneNumber.replace(/\D/g, ''),
        date: this.$moment().format('DD-MM-YYYY HH:mm:ss'),
      }
      const config = {
        headers: {
          Authorization: 'Bearer WL6r-WEd2IPRoiBnA80pMaQYA1UwLPOtJlKx_hOYOGs7UOK2kgVd_v2ErE0',
          'X-Spreadsheet-Id': process.env.VUE_APP_TEST === 'true' ? '1UPFrERC-25RX9JqYM2f1dWDd2M9x9l60ZtIJIMOP7K0' : '10XwioZWg-80v3VfhqYI5m-RfHrDGFY9wcFKFkuFHp8c',
          'Content-Type': 'application/json',
        },
      }
      axios.post(url, data, config)
        .then((response) => {
          console.debug(response.data)
        })
        .catch((error) => {
          console.error(error)
        })

      this.$router.push('/services')
      this.showConfirm({
        title: this.$t('ваша_заявка_успешна_принята'),
        resolveText: this.$t('ok'),
      }).then(() => {})
        .catch(() => {})
      this.analyticsLogEvent({ eventName: 're_freedom_life_form_submitted' })
    },

  },
}
</script>

<style lang="stylus">
</style>
