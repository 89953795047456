<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/settings')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("настройки_чека") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.re-v-list-fulltext
            v-subheader {{ $t("настройки_по_умолчанию_в_момент_оформления_чека") }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("автоматическая_прокрутка_чека_на_экране") }}
              v-list-item-action
                v-switch(color="teal" :input-value="ticketAutoScroll" @change="ticketAutoScrollChanged")
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("режим_покупка") }}
              v-list-item-action
                v-switch(color="teal" :input-value="buyMode" @change="buyModeChanged")
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("иин_бин_покупателя") }}
              v-list-item-action
                v-switch(color="teal" :input-value="customerIinBin" @change="customerIinBinChanged")
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("штрих_код_товара_в_чеке") }}
              v-list-item-action
                v-switch(color="teal" :input-value="barcodeInPositions" @change="barcodeInPositionsChanged")
            v-list-item(v-if="$isAndroid()")
              v-list-item-content
                v-list-item-title {{ $t("соотношение_сторон_камеры_43") }}
              v-list-item-action
                v-switch(color="teal" :input-value="aspectRatio43" @change="aspectRatio43Changed")
            v-list-item(@click="openExciseStampTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("маркировка") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ $t("exciseStampType." + (preferences.exciseStampType || 'DISABLED')) }}
            v-list-item(@click="openDomainTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("вид_отрасли") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ $t("domainType." + preferences.domainType) }}
            v-list-item(@click="openDefaultTaxTypeDialog()" v-if="configuration.taxMode")
              v-list-item-content
                v-list-item-title {{ $t("налоговая_ставка") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ $t('taxType.' + preferences.defaultTaxType) }}
            v-list-item(@click="openDefaultItemNameDialog()")
              v-list-item-content
                v-list-item-title {{ $t("наименование_позиции") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ preferences.defaultItemName }}
            v-list-item(@click="openDefaultItemTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("единица_измерения") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ $t('unitType.' + preferences.defaultItemType) }}
            v-list-item(@click="openOperationTotalLimitDialog()")
              v-list-item-content
                v-list-item-title {{ $t("максимальная_сумма_чека") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ preferences.operationTotalLimit | numeral('0,0') }} ₸
            v-list-item(v-if="$isCordova()" @click="openShareTicketTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("поделиться_чеком") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ $t("shareTicketType." + shareTicketType) }}
            v-list-item(@click="openDefaultPaymentTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("тип_оплаты_по_умолчанию") }}
              v-list-item-content.re-text-align-end
                v-list-item-action-text.subtitle-1 {{ $t("paymentType." + defaultPaymentType) }}

  v-dialog(v-model="exciseStampTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_тип_маркировки") }}
      v-card-text
        v-container
          v-form.mt-3(ref="exciseStampTypeForm" @submit.prevent="saveExciseStampType()")
            v-select(:label="$t('тип_маркировки')" v-model="exciseStampType" :items="exciseStampTypes" item-value="value" item-text="title")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="exciseStampTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveExciseStampType()") {{ $t("сохранить") }}

  v-dialog(v-model="defaultItemTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_единицу_измерения") }}
      v-card-text
        v-container
          v-form.mt-3(ref="defaultItemTypeForm" @submit.prevent="saveDefaultItemType()")
            v-select(:label="$t('единица_измерения')" v-model="defaultItemType" :items="unitTypes" item-value="value" item-text="title")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="defaultItemTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveDefaultItemType()") {{ $t("сохранить") }}

  v-dialog(v-model="defaultTaxTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_налоговую_ставку") }}
      v-card-text
        v-container
          v-form.mt-3(ref="defaultTaxTypeForm" @submit.prevent="saveDefaultTaxType()")
            v-select(:label="$t('налоговая_ставка')" v-model="defaultTaxType" :items="taxTypes" item-value="value" item-text="title")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="defaultTaxTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveDefaultTaxType()") {{ $t("сохранить") }}

  v-dialog(v-model="domainTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_вид_отрасли") }}
      v-card-text
        v-container
          v-form.mt-3(ref="domainTypeForm" @submit.prevent="saveDomainType()")
            v-select(:label="$t('вид_отрасли')" v-model="domainType" :items="domainTypes" item-value="value" item-text="title")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="domainTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveDomainType()") {{ $t("сохранить") }}

  v-dialog(v-model="defaultItemNameDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("введите_наименование_позиции") }}
      v-card-text
        v-container
          v-form.mt-3(ref="defaultItemNameForm" @submit.prevent="saveDefaultItemName()")
            v-text-field(:label="$t('наименование_позиции')" v-model="defaultItemName" type="text" :rules="[rules.required]" validate-on-blur autocomplete="off" :autofocus="!$isCordova()" maxlength="40" counter="40" clearable)
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="defaultItemNameDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveDefaultItemName()") {{ $t("сохранить") }}

  v-dialog(v-model="defaultItemTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_единицу_измерения") }}
      v-card-text
        v-container
          v-form.mt-3(ref="defaultItemTypeForm" @submit.prevent="saveDefaultItemType()")
            v-select(:label="$t('единица_измерения')" v-model="defaultItemType" :items="unitTypes" item-value="value" item-text="title")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="defaultItemTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveDefaultItemType()") {{ $t("сохранить") }}

  v-dialog(v-model="operationTotalLimitDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("введите_лимит_на_сумму_чека") }}
      v-card-text
        v-container
          v-form.mt-3(ref="operationTotalLimitForm" @submit.prevent="saveOperationTotalLimit()")
            v-text-field(:label="$t('максимальная_сумма_чека')" v-model.number="operationTotalLimit" type="number" :rules="[rules.numberRange(1, 1000000000, operationTotalLimit)]" suffix="₸" validate-on-blur autocomplete="off" :autofocus="!$isCordova()")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="operationTotalLimitDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveOperationTotalLimit()") {{ $t("сохранить") }}

  v-dialog(v-model="shareTicketTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_как_поделиться_чеком") }}
      v-card-text
        v-container
          v-form.mt-3(ref="shareTicketTypeForm" @submit.prevent="saveShareTicketType()")
            v-radio-group(v-model="shareTicketTypeRadio")
              v-radio(:label="$t('shareTicketType.IMAGE')" value="IMAGE")
              v-radio(:label="$t('shareTicketType.LINK')" value="LINK")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="shareTicketTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveShareTicketType()") {{ $t("сохранить") }}

  v-dialog(v-model="defaultPaymentTypeDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("") }}
      v-card-text
        v-container
          v-form.mt-3(ref="defaultPaymentTypeForm" @submit.prevent="saveDefaultPaymentType()")
            v-radio-group(v-model="defaultPaymentTypeRadio")
              v-radio(:label="$t('paymentType.PAYMENT_CASH')" value="PAYMENT_CASH")
              v-radio(:label="$t('paymentType.PAYMENT_CARD')" value="PAYMENT_CARD")
              v-radio(:label="$t('paymentType.PAYMENT_MOBILE')" value="PAYMENT_MOBILE")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="defaultPaymentTypeDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveDefaultPaymentType()") {{ $t("сохранить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'

export default {
  mixins: [dictionaryMixin],

  data: () => ({
    ticketAutoScroll: false,
    buyMode: false,
    customerIinBin: false,
    barcodeInPositions: false,
    aspectRatio43: false,

    exciseStampType: null,
    exciseStampTypeDialog: false,

    defaultTaxType: null,
    defaultTaxTypeDialog: false,

    domainType: null,
    domainTypeDialog: false,

    defaultItemName: null,
    defaultItemNameDialog: false,

    defaultItemType: null,
    defaultItemTypeDialog: false,

    operationTotalLimit: 0,
    operationTotalLimitDialog: false,

    shareTicketType: null,
    shareTicketTypeRadio: null,
    shareTicketTypeDialog: false,

    defaultPaymentType: null,
    defaultPaymentTypeRadio: null,
    defaultPaymentTypeDialog: false,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),
  },

  watch: {
    // eslint-disable-next-line func-names
    'cashRegister.id': function () {
      this.updateValues()
    },
  },

  created() {
    this.ticketAutoScroll = localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true'
    this.aspectRatio43 = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10) === 0
    this.defaultPaymentType = localStorage.getItem('rekassa.kz-ui-defaultPaymentType') || 'PAYMENT_CASH'
    this.shareTicketType = localStorage.getItem('rekassa.kz-ui-shareTicketType') || 'IMAGE'
    this.updateValues()
  },

  methods: {
    ...mapActions({
      selectCashRegister: 'cashRegisters/selectCashRegister',
      savePreferences: 'cashRegisters/savePreferences',
      showSnackbar: 'tools/showSnackbar',
    }),

    updateValues() {
      this.buyMode = this.preferences.buyMode
      this.customerIinBin = this.preferences.customerIinBin
      this.barcodeInPositions = this.preferences.barcodeInPositions
    },

    ticketAutoScrollChanged(val) {
      localStorage.setItem('rekassa.kz-ui-ticketAutoScroll', val)
      this.ticketAutoScroll = val
    },

    aspectRatio43Changed(val) {
      this.aspectRatio43 = val
      localStorage.setItem('rekassa.kz-ui-camera-aspectRatio', val ? 0 : 1)
    },

    buyModeChanged(val) {
      this.preferences.buyMode = val
      this.buyMode = val
      this.save()
    },

    customerIinBinChanged(val) {
      this.preferences.customerIinBin = val
      this.customerIinBin = val
      this.save()
    },

    barcodeInPositionsChanged(val) {
      this.preferences.barcodeInPositions = val
      this.barcodeInPositions = val
      this.save()
    },

    openExciseStampTypeDialog() {
      if (this.cashRegister.status === 'REGISTERED') {
        this.exciseStampType = this.preferences.exciseStampType || 'DISABLED'
        this.exciseStampTypeDialog = true
      } else {
        this.showSnackbar({ message: this.$t('маркировка_доступна_только_на_зарегистрированной_кассе') })
      }
    },
    saveExciseStampType() {
      if (this.$refs.exciseStampTypeForm.validate()) {
        this.preferences.exciseStampType = this.exciseStampType
        this.exciseStampTypeDialog = false
        this.save()
      }
    },

    openDefaultTaxTypeDialog() {
      this.defaultTaxType = this.preferences.defaultTaxType
      this.defaultTaxTypeDialog = true
    },
    saveDefaultTaxType() {
      if (this.$refs.defaultTaxTypeForm.validate()) {
        this.preferences.defaultTaxType = this.defaultTaxType
        this.defaultTaxTypeDialog = false
        this.save()
      }
    },

    openDomainTypeDialog() {
      this.domainType = this.preferences.domainType
      this.domainTypeDialog = true
    },
    saveDomainType() {
      if (this.$refs.domainTypeForm.validate()) {
        this.preferences.domainType = this.domainType
        this.domainTypeDialog = false
        this.save()
      }
    },

    openDefaultItemNameDialog() {
      this.defaultItemName = this.preferences.defaultItemName
      this.defaultItemNameDialog = true
    },
    saveDefaultItemName() {
      if (this.$refs.defaultItemNameForm.validate()) {
        this.preferences.defaultItemName = this.defaultItemName
        this.defaultItemNameDialog = false
        this.save()
      }
    },

    openDefaultItemTypeDialog() {
      this.defaultItemType = this.preferences.defaultItemType
      this.defaultItemTypeDialog = true
    },
    saveDefaultItemType() {
      if (this.$refs.defaultItemTypeForm.validate()) {
        this.preferences.defaultItemType = this.defaultItemType
        this.defaultItemTypeDialog = false
        this.save()
      }
    },

    openOperationTotalLimitDialog() {
      this.operationTotalLimit = this.preferences.operationTotalLimit
      this.operationTotalLimitDialog = true
    },
    saveOperationTotalLimit() {
      if (this.$refs.operationTotalLimitForm.validate()) {
        this.preferences.operationTotalLimit = this.operationTotalLimit
        this.operationTotalLimitDialog = false
        this.save()
      }
    },

    openShareTicketTypeDialog() {
      this.shareTicketTypeRadio = this.shareTicketType
      this.shareTicketTypeDialog = true
    },
    saveShareTicketType() {
      this.shareTicketType = this.shareTicketTypeRadio
      localStorage.setItem('rekassa.kz-ui-shareTicketType', this.shareTicketType)
      this.shareTicketTypeDialog = false
    },

    openDefaultPaymentTypeDialog() {
      this.defaultPaymentTypeRadio = this.defaultPaymentType
      this.defaultPaymentTypeDialog = true
    },
    saveDefaultPaymentType() {
      this.defaultPaymentType = this.defaultPaymentTypeRadio
      localStorage.setItem('rekassa.kz-ui-defaultPaymentType', this.defaultPaymentType)
      this.defaultPaymentTypeDialog = false
    },

    save() {
      this.savePreferences({
        cashRegister: this.cashRegister,
        preferences: this.preferences,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id)
      }).catch((error) => {
        this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
        this.selectCashRegister(this.cashRegister.id)
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
</style>
