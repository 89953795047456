<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/kkm/settings')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("смена_токена") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.re-v-list-fulltext
            v-subheader {{ $t("введите_токен_полученный_из_личного_кабинета_офд") }}
            v-form.px-4(ref="form" @submit.prevent="showPinpad()")
              v-text-field(ref="token" :label="$t('токен')" v-model="token" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" @input="tokenError = null" :error-messages="tokenError" maxlength="9" validate-on-blur clearable)
              v-btn.my-3(color="primary" block :disabled="loading" @click="showPinpad()") {{ $t("изменить") }}

  re-pinpad(v-model="saveTokenDialog" :title="$t('изменение_токена')" :subtitle="$t('введите_пин-код')" :text="$t('для_изменения_токена')" :loading="loading" :errorText.sync="saveTokenErrorMessage" @action="saveToken")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import store from '../../store/index'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-pinpad': Pinpad,
  },

  data: () => ({
    loading: false,

    saveTokenDialog: false,
    saveTokenErrorMessage: null,

    token: null,
    tokenError: null,
  }),

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.status === 'REGISTERED') {
      next()
    } else {
      next('/')
    }
  },

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  methods: {
    ...mapActions({
      setToken: 'cashRegisters/setToken',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
    }),

    showPinpad() {
      this.$refs.token.blur()
      if (this.$refs.form.validate()) {
        this.saveTokenDialog = true
      }
    },

    saveToken(pincode) {
      this.loading = true
      const form = new FormData()
      form.append('token', this.token)
      this.setToken({
        cashRegister: this.cashRegister,
        data: form,
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.showSnackbar({ message: this.$t('токен_успешно_изменен') })
          this.$router.push('/kkm/settings')
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.saveTokenErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else if (error && error.response && error.response.data && error.response.data.code === 'INVALID_TOKEN') {
          this.loading = false
          this.saveTokenDialog = false
          this.tokenError = this.$t('backend/INVALID_TOKEN')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_токен', { error }) })
          this.loading = false
          this.saveTokenDialog = false
        }
      })
    },
  },
}
</script>
