import i18n from '../../i18n/index'

const miniapp = {
  namespaced: true,

  state: {
    visible: false,
    app: {
      name: null,
      url: null,
      params: {},
    },
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    show({ commit, dispatch }, payload) {
      if (payload && payload.name && payload.url) {
        commit('set', { type: 'app', value: payload })
        commit('set', { type: 'visible', value: true })
      } else {
        dispatch('tools/showSnackbar', { message: i18n.t('ошибка_при_запуске_приложения') }, { root: true })
      }
    },

    hide({ commit }) {
      commit('set', { type: 'visible', value: false })
      commit('set', {
        type: 'app',
        value: {
          name: null,
          url: null,
          params: {},
        },
      })
    },
  },
}

export default miniapp
