<template lang="pug">
  v-dialog(v-model="showDialog" scrollable persistent @keydown="handleDialogKeydown($event)" width="500")
    v-card
      v-card-text.px-6.py-4
        v-form(ref="form")
          v-row
            v-col.py-1
              v-text-field(:label="$t('иин_бин_покупателя')" v-model="customerIinBin" type="tel" :rules="[iinBinRules.iinBin]" maxlength="12" validate-on-blur autocomplete="off" clearable :autofocus="!$isCordova()")

      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="showDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="saveCustomerIinBin()") {{ $t("сохранить") }}

</template>
<script>
import i18n from '../../../i18n'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    customerIinBinProp: {
      default: null,
      required: true,
    },
  },

  data: () => ({
    showDialog: false,
    customerIinBin: null,

    iinBinRules: {
      iinBin: (v) => (v === null) || (!!v && /^\d+$/.test(v) && v.length === 12) || i18n.t('некорректный_иин_бин'),
    },
  }),

  watch: {
    value(value) {
      if (value) {
        this.showDialog = true
        this.customerIinBin = this.customerIinBinProp
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
  },

  methods: {
    saveCustomerIinBin() {
      if (this.$refs.form.validate()) {
        this.$emit('saveCustomerIinBin', this.customerIinBin)
        this.showDialog = false
      }
    },

    handleDialogKeydown(event) {
      if (event.keyCode === 13) {
        this.saveCustomerIinBin()
        event.preventDefault()
      }
    },
  },
}
</script>
<style lang="stylus">
</style>
