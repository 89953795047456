<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/services')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("ATBPOS") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pt-5(align="center")
          v-img.rounded-xl(:src="`/static/atbpos-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("принтеры_для_чеков") }}
          .subtitle-1.mt-4 {{ $t("приобретайте_оборудование_для_вашего_бизнеса_со_скидкой") }}
          .subtitle-1.mt-4 {{ $t("чековый_принтер_устройство_созданное_для_печати_информации_о_покупке_на_товарном_чеке_dotdotdot") }}

      v-row
        v-col.pt-0
          ul
            li.subtitle-1 {{ $t("принтеры_для_чеков_мобильный_58_мм_беспроводной") }}
            li.subtitle-1 {{ $t("принтер_чеков_мобильный_58_мм_android_ios") }}
            li.subtitle-1 {{ $t("чековый_принтер_kp206_usb_bluetooth") }}
            li.subtitle-1 {{ $t("принтер_чеков_xprinter_n160") }}

      v-row
        v-col
          .subtitle-1 {{ $t("сканер_штрих_кода_представляет_собой_инструмент_предназначенный_для_считывания_dotdotdot") }}

      v-row
        v-col.pt-0
          ul
            li.subtitle-1 {{ $t("ручной_беспроводной_2d_сканер_штрих_кодов") }}
            li.subtitle-1 {{ $t("ручной_usb_сканер_штрих_кодов") }}

      v-row
        v-col
          v-btn.my-4(color="secondary" block @click="openPartnerLink()") {{ $t("заказать_со_скидкой") }}

</template>
<script>
import { mapActions } from 'vuex'
import { getLocale } from '../../i18n'

export default {
  computed: {
    locale() {
      return getLocale()
    },
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
    }),

    openPartnerLink() {
      this.$openLink(`https://link.rekassa.kz/printer-discount-about-${this.locale}`, '_system')
      this.analyticsLogEvent({ eventName: 're_partner_atb_clicked' })
    },

  },
}
</script>

<style lang="stylus">
</style>
