export default {
  PIECE: {
    _deprecated: 'шт',
    code: '796',
  },
  KILOGRAM: {
    _deprecated: 'кг',
    code: '166',
  },
  GRAM: {
    _deprecated: 'г',
    code: '163',
  },
  TON: {
    _deprecated: 'тонн.',
    code: '168',
  },
  DECITONNE: {
    _deprecated: 'центнер',
    code: '206',
  },
  TON_KILOMETRE: {
    _deprecated: 'тоннокилометр',
    code: '449',
  },
  LITER: {
    _deprecated: 'литр',
    code: '112',
  },
  MILLILITER: {
    _deprecated: 'мл',
    code: '111',
  },
  METER: {
    _deprecated: 'м',
    code: '006',
  },
  SQUARE_METER: {
    _deprecated: 'м2',
    code: '055',
  },
  CUBIC_METER: {
    _deprecated: 'м3',
    code: '113',
  },
  HECTARE: {
    _deprecated: 'га',
    code: '059',
  },
  RUNNING_METER: {
    _deprecated: 'м.пог.',
    code: '018',
  },
  KILOMETRE: {
    _deprecated: 'км',
    code: '008',
  },
  BOTTLE: {
    _deprecated: 'бутылка',
    code: '868',
  },
  PACK: {
    _deprecated: 'пачка',
    code: '728',
  },
  PACKAGE: {
    _deprecated: 'упак.',
    code: '778',
  },
  KIT: {
    _deprecated: 'набор',
    code: '704',
  },
  SET: {
    _deprecated: 'компл.',
    code: '839',
  },
  PAIR: {
    _deprecated: 'пара',
    code: '715',
  },
  ROLL: {
    _deprecated: 'рулон',
    code: '736',
  },
  ONE_SERVICE: {
    _deprecated: 'одн.усл.',
    code: '5114',
  },
  MINUTE: {
    _deprecated: 'мин.',
    code: '355',
  },
  HOUR: {
    _deprecated: 'час',
    code: '356',
  },
  DAY: {
    _deprecated: 'сутки',
    code: '5208',
  },
  MONTH: {
    _deprecated: 'месяц',
    code: '362',
  },
  YEAR: {
    _deprecated: 'год',
    code: '366',
  },
  KILOWATT_HOUR: {
    _deprecated: 'кВт/ч',
    code: '245',
  },
  DOSE: {
    _deprecated: 'доза',
    code: '639',
  },
  SOWING_UNIT: {
    _deprecated: 'посевная единица',
    code: '6023',
  },
}
