<template lang="pug">
v-dialog(v-model="dialog" content-class="small-dialog" max-width="430px")
  v-card.py-3
    v-card-title
      v-row.pb-2
        v-col.pl-5(cols="10")
          .headline.grey--white {{ $t("необходимо_активировать_кассу_в_налоговой") }}
        v-col(cols="2")
          v-btn(icon @click="dialog = false")
            v-icon.grey--white mdi-close
    v-card-text.pl-0
      v-row
        v-col.pl-0.pr-0(cols="5")
          v-img(src="/static/tax-agent-activate.png" width="100%")
        v-col(cols="7")
          v-row
            v-col
              ul
                li.subtitle-1.grey--white(style="line-height: 1.3rem;") {{ $t("чтобы_перейти_из_демо_режима_в_рабочий_вам_нужно_поставить_кассу_на_учет") }}
                li.subtitle-1.mt-2.grey--white(style="line-height: 1.3rem;") {{ $t("процесс_занимает_3_минуты") }}
            v-col
              v-btn.mt-1.ml-5(color="primary" @click="showActivation()") {{ $t("поставить_на_учет") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    dialog: true,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  created() {
    if (this.cashRegister.status === 'TRIAL' && (localStorage.getItem('rekassa.kz-activation-abuse-showed') || 'false') === 'false') {
      this.dialog = true
      localStorage.setItem('rekassa.kz-activation-abuse-showed', 'true')
      this.analyticsLogEvent({ eventName: 're_cash_activate_pop_up_impression' })
    }
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
      showConfirm: 'tools/showConfirm',
    }),

    showActivation() {
      this.dialog = false
      this.$router.push('/ofdreg')
      this.analyticsLogEvent({ eventName: 're_cash_activate_pop_up_click_button' })
    },
  },
}
</script>
<style lang="stylus">
@media(max-width 599px)
  .small-dialog
    position absolute
    top 0
    margin 0
    border-radius 0px 0 20px 20px
@media(min-width 600px)
  .small-dialog
    border-radius 10px 10px 10px 10px
</style>
