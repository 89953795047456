<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/settings')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("правовая_информация") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.re-v-list-fulltext

            v-list-item(@click="$openLink(`https://link.rekassa.kz/app-agreement${locale === 'ru' ? '' : '-kk'}`, '_system')")
              v-list-item-content
                v-list-item-title {{ $t("пользовательское_соглашение") }}
                v-list-item-subtitle
              v-list-item-action
                v-icon(left style="font-size: 30px;") mdi-menu-right

            v-list-item(@click="$openLink(`https://link.rekassa.kz/personal-data-processing-policy${locale === 'ru' ? '' : '-kk'}`, '_system')")
              v-list-item-content
                v-list-item-title {{ $t("политика_обработки_персональных_данных") }}
              v-list-item-action
                v-icon(left style="font-size: 30px;") mdi-menu-right

            v-list-item(@click="$openLink('https://link.rekassa.kz/state-register-of-cash-registers', '_system')")
              v-list-item-content
                v-list-item-title {{ $t("государственный_реестр_контрольно-кассовых_машин") }}
                v-list-item-subtitle {{ $t("rekassa_версия_внесена_в_реестр_под_номером") }}
              v-list-item-action
                v-icon(left style="font-size: 30px;") mdi-menu-right

            v-list-item(@click="$openLink('https://link.rekassa.kz/normative-legal-acts', '_system')")
              v-list-item-content
                v-list-item-title {{ $t("нормативные_правовые_акты") }}
              v-list-item-action
                v-icon(left style="font-size: 30px;") mdi-menu-right

</template>
<script>
import { getLocale } from '../../i18n/index'

export default {

  computed: {
    locale() {
      return getLocale()
    },
  },

}
</script>
<style lang="stylus">
</style>
