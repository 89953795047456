import i18n from '../../i18n/index'
import $http from '../../utils/http'
import unitTypes from '../../utils/unitTypes'

const positions = {
  namespaced: true,

  state: {
    list: [],
    categories: [],
    cartList: [],
    loading: false,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    async fetchCategories({ commit, dispatch }, cashRegisterId) {
      commit('set', { type: 'loading', value: true })
      return new Promise((resolve, reject) => {
        $http.get(`/inventory/crs/${cashRegisterId}/categories?page=0&size=${process.env.VUE_APP_POSITIONS_LIMIT}`).then((result) => {
          if (result.data && result.data._embedded && result.data._embedded.categories) {
            result.data._embedded.categories.push(
              {
                id: 'no-category',
                data: {
                  name: 'Без категории',
                },
              },
            )
            commit('set', { type: 'categories', value: result.data._embedded.categories })
          } else {
            commit('set', { type: 'list', value: [] })
          }
          commit('set', { type: 'loading', value: false })
          resolve()
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${error}` }, { root: true })
          commit('set', { type: 'loading', value: false })
          reject()
        })
      })
    },

    async add({ }, payload) {
      return $http.post(`/inventory/crs/${payload.cashRegisterId}/positions`, payload.data)
    },

    async addAll({ }, payload) {
      return $http.post(`/inventory/crs/${payload.cashRegisterId}/positions/bulk`, payload.data)
    },

    async update({ }, payload) {
      return $http.put(`/inventory/crs/${payload.cashRegisterId}/positions/${payload.id}`, payload.data)
    },

    async delete({ }, payload) {
      return $http.delete(`/inventory/crs/${payload.cashRegisterId}/positions/${payload.id}`)
    },

    async deleteAll({ }, cashRegisterId) {
      return $http.delete(`/inventory/crs/${cashRegisterId}/positions`)
    },

    async fetch({ commit, dispatch }, cashRegisterId) {
      commit('set', { type: 'loading', value: true })
      return new Promise((resolve, reject) => {
        $http.get(`/inventory/crs/${cashRegisterId}/positions?page=0&size=${process.env.VUE_APP_POSITIONS_LIMIT}`).then((result) => {
          if (result.data && result.data._embedded && result.data._embedded.positions) {
            // Create a lookup map where code is the key
            const unitTypeMap = Object.fromEntries(
              Object.entries(unitTypes).map(([key, value]) => [value.code, { unit: key, ...value }]),
            )

            const list = result.data._embedded.positions.map(
              (item) => {
                let unitType = 'PIECE'
                if (item.data.unitType) {
                  unitType = item.data.unitType
                } else if (item.data.measureUnitCode) {
                  unitType = unitTypeMap[item.data.measureUnitCode.code]?.unit || 'PIECE'
                }

                return {
                  id: item.id,
                  name: item.data.name,
                  price: item.data.price,
                  unitType,
                  measureUnitCode: item.data.measureUnitCode,
                  category: item.data.category,
                  barcode: item.data.barcode,
                  type: item.data.type,
                  images: item.data.images,
                  amount: item.data.amount,
                }
              },
            ).sort((a, b) => {
              const nameA = a.name.toLowerCase()
              const nameB = b.name.toLowerCase()
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              return 0
            })
            commit('set', { type: 'list', value: list })
          } else {
            commit('set', { type: 'list', value: [] })
          }
          commit('set', { type: 'loading', value: false })
          resolve()
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${error}` }, { root: true })
          commit('set', { type: 'loading', value: false })
          reject()
        })
      })
    },

    clear({ commit }) {
      commit('set', { type: 'list', value: [] })
    },

    setCartList({ commit }, payload) {
      commit('set', { type: 'cartList', value: payload })
    },
  },
}

export default positions
