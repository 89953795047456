<template lang="pug">
</template>

<script>
import { mapState, mapActions } from 'vuex'
import passwordGenerator from 'generate-password'

export default {
  name: 'AuthenticationModule',

  data: () => ({
    faceTouchIdType: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  mounted() {
    if (this.$isCordova() && !this.$isFFBMode() && this.cashRegister.status !== 'TRIAL') {
      // Check if Google or Face/Touch ID avaliable
      if (this.$isAndroid()) {
        setTimeout(() => this.isGoogleAccountLinkAvailable(), 2000)
      } else {
        setTimeout(() => this.isFaceTouchIdAvailable(), 2000)
      }
    }
  },

  methods: {
    ...mapActions({
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    // Google
    isGoogleAccountLinkAvailable() {
      const freshSignin = (localStorage.getItem('rekassa.kz-auth-fresh-signin') || 'false') === 'true'
      localStorage.setItem('rekassa.kz-auth-fresh-signin', 'false')
      const googleEnabled = (localStorage.getItem('rekassa.kz-auth-google-enabled') || 'false') === 'true'
      const deepLink = this.$route.path !== '/' && this.$route.path !== '/kkm'

      if (freshSignin && !googleEnabled && !deepLink) {
        this.showConfirm({
          icon: 'mdi-google',
          title: this.$t('использовать_type_для_входа_в_приложение', { type: 'Google' }),
          resolveText: this.$t('использовать_type', { type: 'Google' }),
          persistent: false,
        }).then(() => {
          this.linkGoogleAccount()
        }).catch(() => {})
      }
    },

    linkGoogleAccount() {
      window.FirebasePlugin.authenticateUserWithGoogle(process.env.VUE_APP_GOOGLE_PROVIDE_ID, (credential) => {
        window.FirebasePlugin.linkUserWithCredential(credential, () => {
          this.googleAccountLinked()
        }, (error) => {
          // Костыль
          if (error === 'User has already been linked to the given provider.') {
            this.googleAccountLinked()
          } else if (error === 'This credential is already associated with a different user account.') {
            this.showSnackbar({ message: `${this.$t('данная_учетная_запись_google_уже_добавлена_к_другому_номеру_телефона')}` })
          } else {
            console.debug(error)
            this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: 'Google' }) })
          }
        })
      }, (error) => {
        console.debug(error)
        this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: 'Google' }) })
      })
    },

    googleAccountLinked() {
      localStorage.setItem('rekassa.kz-auth-google-enabled', 'true')
      window.FirebasePlugin.reloadCurrentUser()
      this.showConfirm({
        icon: 'mdi-google',
        title: this.$t('успешно_подключен_при_следующем_входе_dotdotdot', { provider: 'Google' }),
        resolveText: this.$t('закрыть'),
      }).then(() => {}).catch(() => {})
      this.analyticsLogEvent({ eventName: 're_auth_user_added_alternative_login_method', eventProperties: { type: 'Google' } })
    },

    // Face/Touch ID
    isFaceTouchIdAvailable() {
      const freshSignin = (localStorage.getItem('rekassa.kz-auth-fresh-signin') || 'false') === 'true'
      localStorage.setItem('rekassa.kz-auth-fresh-signin', 'false')
      const faceTouchIdEnabled = (localStorage.getItem('rekassa.kz-auth-face-touch-id-enabled') || 'false') === 'true'
      const deepLink = this.$route.path !== '/' && this.$route.path !== '/kkm'
      const isTestUser = this.user.phone === '+77777777772' // Avoid Apple check

      if (freshSignin && !faceTouchIdEnabled && !deepLink && !isTestUser) {
        window.Fingerprint.isAvailable((type) => {
          this.faceTouchIdType = (type === 'face' ? 'Face ID' : 'Touch ID')
          this.showConfirm({
            icon: type === 'face' ? 'mdi-face-recognition' : 'mdi-fingerprint',
            title: this.$t('использовать_type_для_входа_в_приложение', { type: this.faceTouchIdType }),
            resolveText: this.$t('использовать_type', { type: this.faceTouchIdType }),
            persistent: false,
          }).then(() => {
            this.registerFaceTouchId()
          }).catch(() => {})
        },
        () => {})
      }
    },

    registerFaceTouchId() {
      const email = `${this.user.phone}@rekassa.kz`
      const password = passwordGenerator.generate({ length: 15, numbers: true })

      window.FirebasePlugin.updateUserEmail(email, () => {
        window.FirebasePlugin.updateUserPassword(password, () => {
          window.Fingerprint.registerBiometricSecret({
            secret: `${email}:${password}`,
            invalidateOnEnrollment: !this.$isAndroid(),
            disableBackup: true,
          }, () => {
            localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'true')
            localStorage.setItem('rekassa.kz-auth-face-touch-id-type', this.faceTouchIdType)
            this.showConfirm({
              icon: this.faceTouchIdType === 'Face ID' ? 'mdi-face-recognition' : 'mdi-fingerprint',
              title: this.$t('успешно_подключен_при_следующем_входе_dotdotdot', { provider: this.faceTouchIdType }),
              resolveText: this.$t('закрыть'),
            }).then(() => {}).catch(() => {})
            this.analyticsLogEvent({ eventName: 're_auth_user_added_alternative_login_method', eventProperties: { type: this.faceTouchIdType } })
          }, (error) => {
            console.debug(error)
            this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: this.faceTouchIdType }) })
          })
        }, (error) => {
          console.debug(error)
          this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: this.faceTouchIdType }) })
        })
      }, (error) => {
        console.debug(error)
        this.showSnackbar({ message: this.$t('ошибка_подключения_type', { type: this.faceTouchIdType }) })
      })
    },
  },
}
</script>
