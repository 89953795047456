export default {
  badge: 'белгіше',
  close: 'Жабу',
  dataIterator: {
    noResultsText: 'Лайықты жазбалар табылмады',
    loadingText: 'Жазба жүктеліп жатыр...',
  },
  dataTable: {
    itemsPerPageText: 'Парақшадағы жолдар:',
    ariaLabel: {
      sortDescending: 'Кему бойынша тізілген.',
      sortAscending: 'Көбею бойынша тізілген.',
      sortNone: 'Реттелмеген.',
      activateNone: 'Сұрыптауды алып тастау үшін белсенді етіңіз.',
      activateDescending: 'Кему бойынша тізу үшін белсенді етіңіз.',
      activateAscending: 'Көбею бойынша тізу үшін белсенді етіңіз.',
    },
    sortBy: 'Бойынша сұрыптау',
  },
  dataFooter: {
    itemsPerPageText: 'Парақшадағы жазбалар:',
    itemsPerPageAll: 'Барлығы',
    nextPage: 'Келесі бет',
    prevPage: 'Алдыңғы бет',
    firstPage: 'Бірінші бет',
    lastPage: 'Соңғы бет',
    pageText: '{2} ден {0}-{1}',
  },
  datePicker: {
    itemsSelected: '{0} таңдалды',
    nextMonthAriaLabel: 'Келесі ай',
    nextYearAriaLabel: 'Келесі жыл',
    prevMonthAriaLabel: 'Өткен ай',
    prevYearAriaLabel: 'Алдыңғы жыл',
  },
  noDataText: 'Деректер жоқ',
  carousel: {
    prev: 'Алдыңғы слайд',
    next: 'Келесі слайд',
    ariaLabel: {
      delimiter: '{1} ден {0} слайд',
    },
  },
  calendar: {
    moreEvents: 'Тағы {0}',
  },
  fileInput: {
    counter: 'Файлдар: {0}',
    counterSize: 'Файлдар: {0} (барлығы {1})',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Беттерді ауыстыру',
      next: 'Келесі бет',
      previous: 'Алдыңғы бет',
      page: '{0} бетіне өту',
      currentPage: 'Ағымдағы бет, Бет {0}',
    },
  },
  rating: {
    ariaLabel: {
      icon: 'Рейтинг {1} ден {0}',
    },
  },
}
