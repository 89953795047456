<template lang="pug">
v-container
  v-row(justify="center")
    v-col.pb-0(cols="12" md="5" lg="4")

      v-container.pb-0
        v-row(no-gutters)
          v-col
            .text-h3.font-weight-bold.text-center {{ organization.businessName }}
            .subtitle-2.text-center.mt-4 {{ $t("бин_иин") }}: {{ organization.businessId }}
            .subtitle-2.text-center.mt-1 {{ $t("знм") }}: {{ cashRegister.serialNumber }}
            .subtitle-2.text-center.mt-1(v-if="preferences && preferences.ofd && preferences.ofd.accountId") {{ $t("лицевой_счёт") }} {{ $t(`fdo.${cashRegister.fdo}.title`) }}:
              a.subtitle-2(@click="$router.push('/kkm/fdo/payment-methods')") &nbsp;{{ preferences.ofd.accountId }}
            h2.subtitle-2.text-center.mt-1(v-if="cashRegisterStatus !== 'ONLINE'") {{ $t("статус") }}:&nbsp;&nbsp;
              v-chip.font-weight-bold.px-2(small :color="$t(`cashRegister.color.${cashRegisterStatus}`)") {{ $t(`cashRegister.status.${cashRegisterStatus}`) }}
                span(v-if="cashRegister.fdoMode === 'OFFLINE' && hoursLeftTillBlocked >= 0") &nbsp; ({{ hoursLeftTillBlocked }} {{ $t("час") }})

        v-row.mt-4(v-if="user._links.createCashRegister")
          v-col.text-center
            v-btn(color="primary" block @click="showCashRegisterSwitch(true); analyticsLogEvent({ eventName: 're_cash_click_button' })")
              v-icon(left) mdi-format-list-bulleted
              |  {{ $t("мои_кассы") }}

  v-row(justify="center")
    v-col(cols="12" md="5" lg="4")
      v-subheader {{ $t("настройки") }}

      v-list.px-0.pa-md-3.rounded-xl
        v-list-item(@click="routeTo('/kkm/settings', 'kkmSettings')" v-if="cashRegister.status === 'REGISTERED' || cashRegister.status === 'DEREGISTERED'")
          v-list-item-icon.ml-2
            v-icon mdi-calculator
          v-list-item-content
            v-list-item-title {{ $t("настройки_кассы") }}
            v-list-item-subtitle
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

        v-list-item(@click="routeTo('/receipt/settings', 'receiptSettings')")
          v-list-item-icon.ml-2
            v-icon mdi-receipt
          v-list-item-content
            v-list-item-title {{ $t("настройки_чека") }}
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

        v-list-item(@click="routeTo('/printer/settings', 'printerSettings')")
          v-list-item-icon.ml-2
            v-icon mdi-printer
          v-list-item-content
            v-list-item-title {{ $t("настройки_принтера") }}
            v-list-item-subtitle(v-if="printer.connectionType === 'bluetooth' && printer.bluetooth.name") {{ printer.bluetooth.name }}
            v-list-item-subtitle(v-if="printer.connectionType === 'wifi' && printer.wifi.name") {{ printer.wifi.name }}
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

        v-list-item(@click="routeTo('/ui/settings', 'uiSettings')")
          v-list-item-icon.ml-2
            v-icon mdi-television-guide
          v-list-item-content
            v-list-item-title {{ $t("настройки_интерфейса") }}
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

        v-list-item(@click="routeTo('/kkm/positions', 'priceList')")
          v-list-item-icon.ml-2
            v-icon mdi-tag-text-outline
          v-list-item-content
            v-list-item-title {{ $t("прайс_лист") }}
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

        v-list-item(@click="routeTo('/kkm/access-users', 'accessUsers')" v-if="!readonly")
          v-list-item-icon.ml-2
            v-icon mdi-account-group
          v-list-item-content
            v-list-item-title {{ $t("кассиры_пользователи") }}
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

        v-list-item(@click="routeTo('/kkm/access-api', 'accessApi')" v-if="!readonly")
          v-list-item-icon.ml-2
            v-icon mdi-apps-box
          v-list-item-content
            v-list-item-title {{ $t("сторонние_приложения") }}
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

    v-col(cols="12" md="5" lg="4")
      template(v-if="!$isFFBMode()")
        div.my-1

        v-subheader {{ $t("помощь") }}

        v-list.px-0.pa-md-3.rounded-xl
          v-list-item(@click="showSafety()")
            v-list-item-icon.ml-2
              v-icon mdi-security
            v-list-item-content
              v-list-item-title {{ $t("безопасность") }}
            v-list-item-action
              v-icon(style="font-size: 30px;") mdi-menu-right

          v-list-item(@click="showManual()")
            v-list-item-icon.ml-2
              v-icon mdi-book-information-variant
            v-list-item-content
              v-list-item-title {{ $t("инструкции") }}
            v-list-item-action
              v-icon(style="font-size: 30px;") mdi-menu-right

          v-list-item(@click="routeTo('/kkm/legal-and-regulatory', 'legalAndRegulatory')")
            v-list-item-icon.ml-2
              v-icon mdi-note-text
            v-list-item-content
              v-list-item-title {{ $t("правовая_информация") }}
            v-list-item-action
              v-icon(style="font-size: 30px;") mdi-menu-right

      v-subheader.mt-md-2.mt-7 {{ $t("профиль") }}

      v-list.px-0.pa-md-3.rounded-xl
        v-list-item(@click="routeTo('/profile', 'profileSettings')" :disabled="!user._links.createCashRegister")
          v-list-item-icon.ml-2
            v-icon mdi-account
          v-list-item-content
            v-list-item-title {{ $t("мой_профиль") }}: {{ user.name }}
            v-list-item-title.mt-2.text-h5.re-number-font {{ user.phone | phone }}
          v-list-item-action
            v-icon(style="font-size: 30px;") mdi-menu-right

      v-btn.mt-4.white--text(block color="danger" @click="preSignOut()")  {{ $t("выйти") }}

  div.mt-6(align="center")
    v-btn(large icon @click="$openLink('https://link.rekassa.kz/instagram', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'instagram' } })")
      v-icon mdi-instagram
    v-btn(large icon @click="$openLink('https://link.rekassa.kz/youtube', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'youtube' } })")
      v-icon mdi-youtube
    v-btn(large icon @click="$openLink('https://link.rekassa.kz/telegram', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'telegram' } })")
      v-icon mdi-send
    v-btn(large icon @click="$openLink('https://link.rekassa.kz/facebook', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'facebook' } })")
      v-icon mdi-facebook

  div.mt-2.mb-8(align="center")
    span.text-subtitle-1 &copy; COMRUN
    span.mx-2 &bull;
    a.text-subtitle-1.mb-3(@click="$openLink(`https://link.rekassa.kz/rekassa-website-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'website' } })") rekassa.kz
    div.text-subtitle-1 {{cordovaBuildVersion ? `v.${cordovaBuildVersion}` : ''}}

</template>
<script>
import { mapActions, mapState } from 'vuex'
import { getLocale } from '../../i18n/index'

export default {

  computed: {
    ...mapState({
      user: state => state.auth.user,
      roles: state => state.cashRegisters.cashRegister.roles,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      printer: state => state.printer.printer,
      cordovaBuildVersion: state => state.tools.cordovaBuildVersion,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    locale() {
      return getLocale()
    },

    cashRegisterStatus() {
      if (this.cashRegister.status === 'TRIAL' || this.cashRegister.status === 'DEREGISTERED') {
        return this.cashRegister.status
      } if (this.cashRegister.status === 'REGISTERED') {
        return this.cashRegister.fdoMode
      } return null
    },

    hoursLeftTillBlocked() {
      return this.cashRegister && this.cashRegister.offlineExpireTime ? Math.floor(this.$moment.duration(this.$moment(this.cashRegister.offlineExpireTime).diff(new Date())).asHours()) : null
    },

    readonly() {
      return this.cashRegister.status === 'TRIAL' || this.cashRegister.status === 'DEREGISTERED' || !this.isOwner || this.user._links.createCashRegister === undefined
    },

    isOwner() {
      return this.roles.find(r => r === 'OWNER') !== undefined
    },
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
    }),

    routeTo(route, event) {
      this.$router.push(route)
      this.analyticsLogEvent({ eventName: 're_settings_click_button', eventProperties: { section: event } })
    },

    preSignOut() {
      this.showConfirm({
        title: this.$t('вы_уверены_что_хотите_выйти_из_приложения'),
        resolveText: this.$t('выйти'),
        rejectText: this.$t('отменить'),
        resolveCallback: () => new Promise((resolve) => {
          if (!this.$isCordova()) {
            window.HelpCrunch('hideChatWidget')
          }
          this.signOut().then(() => {
            resolve({ hide: true })
          })
        }),
      })
    },

    showSafety() {
      this.showConfirm({
        title: this.$t('безопасность'),
        text: this.$t('в_случае_обнаружения_уязвимостей_или_недостатков_в_защищенности_dotdotdot'),
        resolveText: this.$t('закрыть'),
        resolveCallback: () => new Promise((resolve) => {
          resolve({ hide: true })
        }),
      })
    },

    showManual() {
      this.$openLink(`https://link.rekassa.kz/help-rekassa-${this.locale}`, '_system')
      this.analyticsLogEvent({ eventName: 're_settings_click_button', eventProperties: { section: 'manual' } })
    },
  },
}
</script>

<style lang="stylus">
</style>
