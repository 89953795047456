<template lang="pug">
  div.my-2(style="height: 60px;")
    div.re-otp-container(:class="{'re-error': error}" @click="click")
      div.re-otp-slot(:class="{'re-filled': otpValue[0]}") {{ otpValue[0] }}
      div.re-otp-slot(:class="{'re-filled': otpValue[1]}") {{ otpValue[1] }}
      div.re-otp-slot(:class="{'re-filled': otpValue[2]}") {{ otpValue[2] }}
      div.re-otp-slot(:class="{'re-filled': otpValue[3]}") {{ otpValue[3] }}
      div.re-otp-slot(:class="{'re-filled': otpValue[4]}") {{ otpValue[4] }}
      div.re-otp-slot(:class="{'re-filled': otpValue[5]}") {{ otpValue[5] }}
    div.error--text.subtitle-2.mt-2.text-center {{ error }}
    input.re-hidden(id="focusInput" type="tel" autocomplete="one-time-code" @input="input")
</template>

<script>
export default {
  name: 'OTPInput',

  props: {
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      otpValue: [],
    }
  },

  mounted() {
    window.addEventListener('keydown', this.keyListener, false)
    this.$nextTick(() => {
      // Don't work on iOS Safari
      document.getElementById('focusInput').focus()
    })
    // API WebOTP
    if ('OTPCredential' in window) {
      const ac = new AbortController()
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      }).then(() => {
      }).catch(err => {
        console.debug(err)
      })
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  // Короче куча костылей тут чтобы работало все на Cordova, mobile web, desktop web
  methods: {
    click() {
      document.getElementById('focusInput').focus()
    },

    keyListener(event) {
      const focusInput = document.getElementById('focusInput')
      if (!this.disabled && document.activeElement !== focusInput) {
        const reg = new RegExp('^[0-9]$')
        if (!reg.test(event.key) && event.key !== 'Backspace') {
          event.preventDefault()
        } else if (event.key === 'Backspace') {
          this.otpValue.pop()
          this.$emit('input', this.otpValue.join(''))
          this.$emit('update:error', null)
        } else if (this.otpValue.length < 6) {
          this.otpValue.push(event.key)
          this.$emit('input', this.otpValue.join(''))
          if (this.otpValue.length === 6) {
            this.$emit('action')
          }
        }
      } else if (!this.disabled && document.activeElement === focusInput && event.key === 'Backspace') {
        this.otpValue.pop()
        focusInput.value = ''
        this.$emit('input', this.otpValue.join(''))
        this.$emit('update:error', null)
      }
    },

    input() {
      const focusInput = document.getElementById('focusInput')
      if (!this.disabled && document.activeElement === focusInput) {
        if (this.otpValue.length < 6) {
          focusInput.value.split('').forEach(element => {
            const reg = new RegExp('^[0-9]$')
            if (!reg.test(element)) {
              return
            }
            this.otpValue.push(element)
          })
          focusInput.value = ''
          this.$emit('input', this.otpValue.join(''))
          if (this.otpValue.length === 6) {
            this.$emit('action')
          }
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.re-otp-container
  display flex
  height 70px
  &.re-error
    .re-otp-slot
      outline var(--v-error-base) 2px solid!important
.re-otp-slot
  font-size 32px
  font-weight 600
  display flex
  -webkit-box-flex 1
  -ms-flex 1 0 40px
  flex 1 0 40px
  align-items center
  justify-content center
  max-width 100%
  width 100%
  margin 0px 4px
  border-radius 14px
  background-color var(--v-reForeground-base)
  &.re-filled
    background-color var(--v-reForeground-base) !important
.re-hidden
  opacity 0
  width 0px
  height 0px
</style>
