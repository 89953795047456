import fdo from '@comrun/kfdo'
import { Decimal } from 'decimal.js'

export default {
  methods: {
    getNumberFromBillsAndCoins(obj) {
      return new Decimal(obj.bills).plus(new Decimal(obj.coins).dividedBy(100)).toNumber()
    },

    getBillsAndCoinsMoney(value) {
      const bills = new Decimal(value).trunc().toNumber()
      const coins = new Decimal(value).minus(bills).times(100).toNumber()
      const money = new fdo.kkm.proto.Money({
        bills,
        coins,
      })
      return money
    },
  },
}
