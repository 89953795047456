<template lang="pug">
  span
    v-dialog(v-model="showListDialog" persistent scrollable :transition="preferencesMode ? 'fade-transition' : 'dialog-transition'" :fullscreen="preferencesMode || $vuetify.breakpoint.xsOnly" :hide-overlay="preferencesMode || $vuetify.breakpoint.xsOnly" width="500" content-class="v-dialog--gray")
      v-card
        v-toolbar(v-if="preferencesMode" dark flat dense style="max-height: 48px;")
          v-btn(icon @click="$router.push('/')")
            v-icon mdi-arrow-left
          v-toolbar-title.mr-2 {{ $t("прайс_лист") }}: {{ cashRegister.name ? cashRegister.name : organization.businessName }}
          v-spacer
          v-btn(color="primary" @click="preAdd()") {{ $t("добавить") }}
        v-card-title.px-4.pb-0(v-else) {{ $t("прайс_лист") }}: {{ cashRegister.name ? cashRegister.name : organization.businessName }}
          v-spacer
          div.my-5
          v-spacer
          v-btn(v-if="editMode" text color="primary" @click="preAdd()") {{ $t("добавить") }}
        v-divider
        v-text-field.mx-4.mb-4(v-if="positionList.length > 0" :label="$t('поиск')" v-model="searchText" autocomplete="off" clearable style="max-height: 58px;" :messages="preFiltredPositionList.length > pageSize ? 'show' : null")
          template(v-slot:append-outer)
            v-btn(v-if="$isCordova()" icon @click="scanBarcode('searchText')")
              v-icon mdi-barcode-scan
          template(v-slot:message)
            v-flex.re-noselect
              div.text-center
                a(@click.prevent="paginate(false)") <<
                span.mx-3 {{ $t("страница_из", { page: pageNumber, total: totalPages}) }}
                a(@click.prevent="paginate(true)") >>

        v-card-text.pa-0
          div(style="height: 100%; min-height: 450px;")
            v-list.re-v-list-fulltext(v-if="filtredPositionList.length > 0")
              v-list-item(v-for="(position, $index) in filtredPositionList" :key="'p_' + $index" @click="select(position)")
                v-list-item-content
                  v-list-item-title {{ position.name }}
                  v-list-item-subtitle(v-if="position.barcode") {{ position.barcode }}
                v-list-item-action
                  v-list-item-action-text.subtitle-1 {{ position.price | numeral('0,0.[00]') }} ₸ {{ editMode || preferencesMode ? `(${$t('unitType.' + position.unitType)})` : '' }}
            v-container(v-if="filtredPositionList.length === 0 && !searchText" fill-height)
              v-layout(row wrap align-center justify-center)
                v-alert.d-flex(icon="mdi-information-outline" width="300") {{ $t("добавьте_товары_в_прайс-лист_чтобы_не_вбивать_наименование_и_стоимость_вручную") }}
        v-divider(v-if="!preferencesMode")
        v-card-actions(v-if="!preferencesMode")
          v-btn(v-if="!editMode" outlined @click="editMode = true") {{ $t("редактировать") }}
          v-btn(v-if="editMode" color="primary" @click="editMode = false") {{ $t("завершить_редактирование") }}
          v-spacer
          v-btn(outlined @click="showListDialog = false; editMode = false") {{ $t("закрыть") }}
        v-card-actions(v-if="preferencesMode")
          .caption(style="opacity: 0.7;") {{ $t('откройте_на_компьютере_сайт_чтобы_загрузить_или_выгрузить_прайс_лист_в_excel_файле') }} &bull;&nbsp;
            a(@click.prevent="$openLink('https://app.rekassa.kz/kkm/positions', '_system')") {{ "https://app.rekassa.kz" }}

    v-dialog(v-model="showEditDialog" persistent width="450")
      v-card
        v-card-text.px-6.py-4
          v-form(ref="form")
            v-row
              v-col.py-1.py-sm-2(cols="12")
                v-textarea(:label="$t('наименование')" v-model="selectedPosition.name" rows="1" :rules="[rules.required]" counter="500" maxlength="500" clearable validate-on-blur auto-grow autocomplete="off")
              v-col.py-1.py-sm-2(cols="6")
                v-select(:label="$t('единица_измерения')" v-model="selectedPosition.unitType" :items="unitTypes" item-value="value" item-text="title")
              v-col.py-1.py-sm-2(cols="6")
                re-numeric-input-field(:label="$t('стоимость')" v-model="selectedPosition.price" limit-decimal="2" :rules="[rules.numberRange(0, 100000000, selectedPosition.price)]" suffix="₸" validate-on-blur autocomplete="off")
              v-col.py-1.py-sm-2(cols="12")
                v-text-field(:label="$t('штрих_код')" v-model="selectedPosition.barcode" counter="20" maxlength="20" clearable autocomplete="off" placeholder=" ")
                  template(v-if="$isCordova()" v-slot:append-outer)
                    v-btn(icon @click="scanBarcode('selectedPosition')")
                      v-icon mdi-barcode-scan
        v-divider
        v-card-actions
          v-btn(v-if="!newPosition" :disabled="updateLoading" :loading="deleteLoading" outlined color="red" @click="del()") {{ $t('удалить') }}
          v-spacer
          v-btn(outlined :disabled="addLoading || updateLoading || deleteLoading" @click="cancel()") {{ $t('отменить') }}
          v-btn(v-if="newPosition" :loading="addLoading" :disabled="deleteLoading" color="primary" @click="add()") {{ $t('добавить') }}
          v-btn(v-if="!newPosition" :loading="updateLoading" :disabled="deleteLoading" color="primary" @click="update()") {{ $t('сохранить') }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import NumericInputField from '../../utils/NumericInputField.vue'
import dictionaryMixin from '../../../mixins/dictionaryMixin'

export default {
  components: {
    're-numeric-input-field': NumericInputField,
  },

  mixins: [dictionaryMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    preferencesMode: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    positions: [],
    showListDialog: false,

    editMode: false,
    newPosition: false,
    showEditDialog: false,

    selectedPosition: {
      id: null,
      name: null,
      unitType: null,
      price: null,
      barcode: null,
    },

    addLoading: false,
    updateLoading: false,
    deleteLoading: false,

    searchText: null,
    pageNumber: 1,
    pageSize: 50,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      positionList: state => state.positions.list,
    }),

    preFiltredPositionList() {
      if (this.searchText === null || this.searchText === '') {
        return this.positionList
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pageNumber = 1
      return this.positionList.filter((item) => !this.searchText || item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || (item.barcode === this.searchText))
    },

    filtredPositionList() {
      return this.preFiltredPositionList.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize)
    },

    totalPages() {
      return Math.ceil(this.preFiltredPositionList.length / this.pageSize)
    },
  },

  watch: {
    value(value) {
      if (value) {
        if (this.positionList.length === 0) {
          this.fetchPositions(this.cashRegister.id).then(() => {
            this.checkIfEmpty()
          }).catch(() => {
            this.$emit('input', false)
          })
        } else {
          this.checkIfEmpty()
        }
      }
    },

    showListDialog(value) {
      if (!value) {
        this.$emit('input', false)
        this.searchText = ''
      }
    },
  },

  methods: {
    ...mapActions({
      fetchPositions: 'positions/fetch',
      addPosition: 'positions/add',
      updatePosition: 'positions/update',
      deletePosition: 'positions/delete',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    paginate(next) {
      if (next && this.pageNumber < this.totalPages) {
        this.pageNumber += 1
      } else if (!next && this.pageNumber > 1) {
        this.pageNumber -= 1
      }
    },

    checkIfEmpty() {
      if (this.positionList.length === 0 && !this.preferencesMode) {
        this.showConfirm({
          title: this.$t('прайс_лист_пуст_хотите_добавить_новую_запись'),
          resolveText: this.$t('добавить'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.showListDialog = true
          this.$nextTick(() => {
            this.preAdd()
          })
        }).catch(() => {
          this.$emit('input', false)
        })
      } else {
        this.showListDialog = true
      }
    },

    cancel() {
      this.$refs.form.resetValidation()
      this.showEditDialog = false
    },

    select(position) {
      if (this.editMode || this.preferencesMode) {
        this.selectedPosition.id = position.id
        this.selectedPosition.name = position.name
        this.selectedPosition.unitType = position.unitType
        this.selectedPosition.price = position.price
        this.selectedPosition.barcode = position.barcode
        this.newPosition = false
        this.showEditDialog = true
      } else {
        this.$emit('positionSelected', position)
        this.showListDialog = false
        this.analyticsLogEvent({ eventName: 're_positions_used' })
      }
    },

    preAdd() {
      if (this.positionList.length >= process.env.VUE_APP_POSITIONS_LIMIT) {
        this.showSnackbar({ message: this.$t('превышен_лимит_на_количество_позиции_в_прайс_листе', { limit: process.env.VUE_APP_POSITIONS_LIMIT }) })
        return
      }
      this.selectedPosition = {
        id: null,
        name: null,
        unitType: 'PIECE',
        price: null,
        barcode: null,
      }
      this.newPosition = true
      this.showEditDialog = true
    },

    add() {
      if (this.$refs.form.validate()) {
        this.addLoading = true
        this.addPosition({
          cashRegisterId: this.cashRegister.id,
          data: {
            data: {
              name: this.selectedPosition.name, unitType: this.selectedPosition.unitType, price: this.selectedPosition.price, barcode: this.selectedPosition.barcode,
            },
          },
        }).then(() => {
          this.fetchPositions(this.cashRegister.id).then(() => {
            this.addLoading = false
            this.showEditDialog = false
            this.$refs.form.resetValidation()
          }).catch((error) => {
            this.addLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        }).catch((error) => {
          this.addLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }
    },

    update() {
      if (this.$refs.form.validate()) {
        this.updateLoading = true
        this.updatePosition({
          cashRegisterId: this.cashRegister.id,
          id: this.selectedPosition.id,
          data: {
            data: {
              name: this.selectedPosition.name, unitType: this.selectedPosition.unitType, price: this.selectedPosition.price, barcode: this.selectedPosition.barcode,
            },
          },
        }).then(() => {
          this.fetchPositions(this.cashRegister.id).then(() => {
            this.updateLoading = false
            this.showEditDialog = false
          }).catch((error) => {
            this.updateLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        }).catch((error) => {
          this.updateLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }
    },

    del() {
      this.deleteLoading = true
      this.deletePosition({ cashRegisterId: this.cashRegister.id, id: this.selectedPosition.id }).then(() => {
        this.fetchPositions(this.cashRegister.id).then(() => {
          this.deleteLoading = false
          this.showEditDialog = false
        }).catch((error) => {
          this.deleteLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }).catch((error) => {
        this.deleteLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    scanBarcode(which) {
      const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10)
      window.cordova.plugins.rekassaBarcode.scan((result) => {
        if (which === 'searchText') {
          this.searchText = `${result}`
        } else {
          this.selectedPosition.barcode = `${result}`
        }
      },
      () => {}, { aspectRatio })
    },
  },
}
</script>
<style lang="stylus">
</style>
