import { Decimal } from 'decimal.js'
import moment from 'moment'
import $http from '../../utils/http'
import i18n from '../../i18n/index'

const m4bank = {
  namespaced: true,

  state: {
    hasAndroid8AndAbove: false,
    hasNFC: false,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    checkCompatibility({ commit, state, dispatch }) {
      window.cordova.plugins.rekassaM4bank.hasAndroid8AndAbove((hasAndroid) => {
        commit('set', { type: 'hasAndroid8AndAbove', value: hasAndroid })
        window.cordova.plugins.rekassaM4bank.hasNFC((hasNFC) => {
          commit('set', { type: 'hasNFC', value: hasNFC })
          if (state.hasAndroid8AndAbove && state.hasNFC) {
            dispatch('analytics/setUserProperty', { key: 'support_m4bank', value: true }, { root: true })
          }
        }, (error) => { console.error(`hasNFC: ${error}`) })
      }, (error) => { console.error(`hasAndroid8AndAbove: ${error}`) })
    },

    hasM4bank({ }, payload) {
      return new Promise((resolve, reject) => {
        window.cordova.plugins.rekassaM4bank.hasM4bank((result) => {
          resolve(result)
        }, (error) => {
          reject(error)
        },
        { packageName: payload.packageName })
      })
    },

    requestOperation({ rootState }, payload) {
      const prefix = payload.bankType === 'HALYK_POS' ? 'halyk' : ''
      const packageName = payload.bankType === 'HALYK_POS' ? 'ru.m4bank.softpos.halyk' : ''

      return new Promise((resolve, reject) => {
        $http.get(`/${prefix}/crs/${payload.cashRegisterId}/payment-token`).then((tokenResult) => {
          const token = tokenResult.data.value
          const cardPayment = payload.ticket.payments.find((p) => p.type === 'PAYMENT_CARD')
          const jsonBody = {
            credentials: {
              authorizationToken: token,
            },
            operationData: {
              instrument: 'CARD',
              amountData: {
                currencyCode: 398,
                amount: new Decimal(cardPayment.sum.bills).plus(new Decimal(cardPayment.sum.coins).dividedBy(100)).times(100).toNumber(),
                amountExponent: 2,
              },
              goods: {
                product: payload.ticket.items.reduce((items, item) => {
                  if (item.type === 'ITEM_TYPE_COMMODITY') {
                    const type = item.commodity.auxiliary.find((a) => a.key === 'UNIT_TYPE')
                    const product = {
                      name: item.commodity.name,
                      price: new Decimal(item.commodity.price.bills).plus(new Decimal(item.commodity.price.coins).dividedBy(100)).times(100).toNumber(),
                      quantity: new Decimal(item.commodity.quantity).dividedBy(1000).toNumber(),
                      quantityExponent: 0,
                      accountingSubject: type === 'ONE_SERVICE' ? 'SERVICE' : 'PRODUCT',
                    }
                    items.push(product)
                  }
                  return items
                }, []),
              },
            },
          }

          const request = {
            packageName,
            operationType: 'PAYMENT',
            json: JSON.stringify(jsonBody),
          }

          window.cordova.plugins.rekassaM4bank.requestOperation((paymentResult) => {
            const resultJson = JSON.parse(paymentResult)
            if (resultJson && resultJson.transaction && resultJson.transaction.dateTime) {
              resultJson.transaction.dateTime = moment(resultJson.transaction.dateTime, 'ddd MMM DD HH:mm:ss [GMT]Z YYYY', 'en').format()
            }
            if (resultJson && resultJson.transaction && resultJson.transaction.instrumentSpecificData) {
              resultJson.transaction.maskedPan = resultJson.transaction.instrumentSpecificData.maskedPan
              resultJson.transaction.authorizationCode = resultJson.transaction.instrumentSpecificData.authorizationCode
              resultJson.transaction.rrn = resultJson.transaction.instrumentSpecificData.rrn
            }
            resolve(resultJson)
          }, (error) => {
            if (error === 'APPLICATION_NOT_INSTALLED') {
              const errorDescription = `${i18n.t('приложение_не_установлено')}: ${i18n.t(payload.bank)}`
              reject(errorDescription)
            } else {
              reject(error)
            }
          },
          request)
        }).catch((error) => {
          if (error && error.response && error.response.data && error.response.data.code && i18n.t(`m4bank/${error.response.data.code}`) !== `m4bank/${error.response.data.code}`) {
            reject(i18n.t(`m4bank/${error.response.data.code}`, { phoneNumber: rootState.auth.user.phone }))
          } else {
            reject(error)
          }
        })
      })
    },
  },
}

export default m4bank
