import axios from 'axios'
import { signInWithCustomToken, signOut } from 'firebase/auth'
import $http from '../../utils/http'
import router from '../../router'
import i18n from '../../i18n/index'
import devicePush from '../../utils/devicePush'
import webPush from '../../utils/webPush'

const auth = {
  namespaced: true,

  state: {
    user: null,
    firebaseAuth: null,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    setFirebaseAuth({ commit }, payload) {
      commit('set', { type: 'firebaseAuth', value: payload })
    },

    sendOtp({ }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_ROOT_URL}/sms/send-otp`, payload.data).then((result) => {
          resolve(result.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    verifyOtp({ }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_ROOT_URL}/sms/verify-otp`, payload.data).then((result) => {
          resolve(result.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    async setUser({ commit, dispatch, state }, user) {
      if (user) {
        commit('set', { type: 'user', value: (await $http.get('/api/auth/me')).data })
        // First ever login
        if (state.user.registrationDate === null && state.user.phone) {
          dispatch('analytics/logEvent', { eventName: 're_user_first_registered', eventProperties: { phone: state.user.phone } }, { root: true })
        }
      }
    },

    setName({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        $http.post(`/api/auth/me/set-name?name=${payload.name}`).then((result) => {
          commit('set', { type: 'user', value: result.data })
          resolve()
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}:  ${error.message}` }, { root: true })
          reject()
        })
      })
    },

    setReferrer({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        $http.post(`/api/auth/me/set-referrer?referrer=${payload.referrer}`).then((result) => {
          commit('set', { type: 'user', value: result.data })
          resolve()
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}:  ${error.message}` }, { root: true })
          reject()
        })
      })
    },

    getCustomTokenByNumberAndPassword({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_ROOT_URL}/api/auth/embed/login?apiKey=${process.env.VUE_APP_FFB_POS_API_KEY}`, payload.data).then((result) => {
          resolve(result.data)
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}:  ${error.message}` }, { root: true })
          reject()
        })
      })
    },

    signInWithCustomToken({ state }, payload) {
      // Cordova
      if (window.cordova !== undefined) {
        return new Promise((resolve, reject) => {
          window.FirebasePlugin.signInUserWithCustomToken(payload.token, () => {
            window.FirebasePlugin.getCurrentUser((user) => {
              resolve({ user })
            }, () => {
              reject()
            })
          }, () => {
            reject()
          })
        })
      }
      // Web
      return signInWithCustomToken(state.firebaseAuth, payload.token)
    },

    signOut({ state, commit, dispatch }) {
      // Cordova
      if (window.cordova !== undefined) {
        devicePush.unsubscribe().then(() => {
          window.FirebasePlugin.signOutUser(() => {
            console.debug('User signed out')
            router.push('/signin')
            dispatch('positions/clear', null, { root: true })
            dispatch('tools/setAppInitialized', false, { root: true })
            commit('set', { type: 'user', value: null })
          }, () => {
            router.push('/signin')
          })
        })
      // Web
      } else {
        webPush.unsubscribe().then(() => {
          signOut(state.firebaseAuth).then(() => {
            console.debug('User signed out')
            router.push('/signin')
            dispatch('positions/clear', null, { root: true })
            dispatch('tools/setAppInitialized', false, { root: true })
            commit('set', { type: 'user', value: null })
          }).catch(() => {
            router.push('/signin')
          })
        })
      }
    },
  },
}

export default auth
