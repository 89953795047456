<template lang="pug">
v-dialog(v-model="show" persistent max-width="300")
  v-card
    v-card-text
        v-row(row wrap)
          v-col.py-1.pb-3(align="center")
            v-icon(color="red" large) mdi-alert-outline
        v-row(row wrap)
          v-col.py-1(align="center")
            .title {{ $t("сервер_недоступен") }}
        v-row(row wrap)
          v-col.py-1(align="center")
            .subtitle {{ $t("проверьте_интернет_соединение_и_повторите_попытку") }}
    v-divider
    v-card-actions.pa-0.ma-0
      v-spacer
      v-btn(@click.native="close()" text block height="45") OK
      v-spacer
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ConnectionWarning',

  computed: {
    ...mapState({
      show: state => state.tools.connectionWarning,
      appInitialized: state => state.tools.appInitialized,
    }),
  },

  watch: {
    show(value) {
      if (value) {
        this.analyticsLogEvent({ eventName: 're_server_unavailable', eventProperties: { type: this.$isCordova() ? 'mobile' : 'web' } })
      }
    },
  },

  methods: {
    ...mapActions({
      setConnectionWarning: 'tools/setConnectionWarning',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    close() {
      this.setConnectionWarning(false)
      if (!this.appInitialized) {
        window.location.href = window.location.origin
      }
    },
  },

}
</script>

<style scoped lang="stylus">
</style>
