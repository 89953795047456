/* eslint-disable */
const utils_1 = require('./utils');
const options_1 = require('./options');
const modal_1 = require('./modal');
var Zuck = function (timeline, options) {
    if (!timeline.id) {
        timeline.setAttribute('id', (0, utils_1.generateId)());
    }
    var id = timeline.id;
    var _a = (0, options_1.loadOptions)(options),
        option = _a.option,
        callbackOption = _a.callback,
        templateOption = _a.template,
        languageOption = _a.language;
    var data = option('stories') || [];
    var internalData = {};

    var saveLocalData = function (key, data) {
        try {
            if (option('localStorage') && (0, utils_1.hasWindow)()) {
                var keyName = "zuck-".concat(id, "-").concat(key);
                window.localStorage[keyName] = JSON.stringify(data);
            }
        } catch (e) {}
    };
    var getLocalData = function (key) {
        if (option('localStorage') && (0, utils_1.hasWindow)()) {
            var keyName = "zuck-".concat(id, "-").concat(key);
            return window.localStorage[keyName] ?
                JSON.parse(window.localStorage[keyName]) :
                undefined;
        } else {
            return undefined;
        }
    };
    internalData.seenItems = getLocalData('seenItems') || {};
    var playVideoItem = function (storyViewer, elements, unmute) {
        var itemElement = elements === null || elements === void 0 ? void 0 : elements[1];
        var itemPointer = elements === null || elements === void 0 ? void 0 : elements[0];
        if (!itemElement || !itemPointer) {
            return false;
        }
        var cur = internalData.currentVideoElement;
        if (cur) {
            cur.pause();
        }
        if (itemElement.getAttribute('data-type') === 'video') {
            var video_1 = itemElement.querySelector('video');
            if (!video_1) {
                internalData.currentVideoElement = undefined;
                return false;
            }
            var setDuration = function () {
                var duration = video_1.duration;
                var itemPointerProgress = itemPointer.querySelector('.progress');
                if (+video_1.dataset.length) {
                    duration = +video_1.dataset.length;
                }
                if (duration && itemPointerProgress) {
                    itemPointerProgress.style.animationDuration = "".concat(duration, "s");
                }
            };
            setDuration();
            video_1.addEventListener('loadedmetadata', setDuration);
            internalData.currentVideoElement = video_1;
            video_1.play();
            try {
                unmuteVideoItem(video_1, storyViewer);
            } catch (e) {
                console.warn('Could not unmute video', unmute);
            }
        } else {
            internalData.currentVideoElement = undefined;
        }
    };
    var findStoryIndex = function (id) {
        return data.findIndex(function (item) {
            return item.id === id;
        });
    };
    var pauseVideoItem = function () {
        var video = internalData.currentVideoElement;
        if (video) {
            try {
                video.pause();
            } catch (e) {}
        }
    };
    var unmuteVideoItem = function (video, storyViewer) {
        video.muted = false;
        video.volume = 1.0;
        video.removeAttribute('muted');
        video.play();
        if (video.paused) {
            video.muted = true;
            video.play();
        }
        if (storyViewer) {
            storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('paused');
        }
    };
    var parseItems = function (story, forceUpdate) {
        var storyId = (story === null || story === void 0 ? void 0 : story.getAttribute('data-id')) || '';
        var storyIndex = findStoryIndex(storyId);
        var storyItems = document.querySelectorAll("#".concat(id, " [data-id=\"").concat(storyId, "\"] .items > li"));
        var items = [];
        if (!option('reactive') || forceUpdate) {
            storyItems.forEach(function (_a) {
                var firstElementChild = _a.firstElementChild;
                var a = firstElementChild;
                var img = a === null || a === void 0 ? void 0 : a.firstElementChild;
                var li = a === null || a === void 0 ? void 0 : a.parentElement;
                var item = {
                    id: (a === null || a === void 0 ? void 0 : a.getAttribute('data-id')) || (li === null || li === void 0 ? void 0 : li.getAttribute('data-id')),
                    src: a === null || a === void 0 ? void 0 : a.getAttribute('href'),
                    length: (0, utils_1.safeNum)(a === null || a === void 0 ? void 0 : a.getAttribute('data-length')),
                    type: a === null || a === void 0 ? void 0 : a.getAttribute('data-type'),
                    time: (a === null || a === void 0 ? void 0 : a.getAttribute('data-time')) || (li === null || li === void 0 ? void 0 : li.getAttribute('data-time')),
                    link: (a === null || a === void 0 ? void 0 : a.getAttribute('data-link')) || '',
                    linkText: a === null || a === void 0 ? void 0 : a.getAttribute('data-linkText'),
                    linkColor: a === null || a === void 0 ? void 0 : a.getAttribute('data-linkColor'),
                    linkBackground: a === null || a === void 0 ? void 0 : a.getAttribute('data-linkBackground'),
                    preview: img === null || img === void 0 ? void 0 : img.getAttribute('src'),
                    seen: li === null || li === void 0 ? void 0 : li.classList.contains('seen')
                };
                var all = a === null || a === void 0 ? void 0 : a.attributes;
                var reserved = [
                    'data-id',
                    'href',
                    'data-length',
                    'data-type',
                    'data-time',
                    'data-link',
                    'data-linkText',
                    'data-linkColor',
                    'data-linkBackground'
                ];
                if (all) {
                    for (var z = 0; z < all.length; z++) {
                        if (reserved.indexOf(all[z].nodeName) === -1) {
                            item[all[z].nodeName.replace('data-', '')] = all === null || all === void 0 ? void 0 : all[z].nodeValue;
                        }
                    }
                }
                // destruct the remaining attributes as options
                items.push(item);
            });
            data[storyIndex].items = items;
            var callback = callbackOption('onDataUpdate');
            if (callback) {
                callback(data, function () {});
            }
        }
    };
    var parseStory = function (story) {
        var _a, _b;
        var storyId = (story === null || story === void 0 ? void 0 : story.getAttribute('data-id')) || '';
        var storyIndex = findStoryIndex(storyId);
        var seen = false;
        if (internalData.seenItems[storyId]) {
            seen = true;
        }
        try {
            var storyData = {};
            if (storyIndex !== -1) {
                storyData = data[storyIndex];
            }
            storyData.id = storyId;
            storyData.photo = story === null || story === void 0 ? void 0 : story.getAttribute('data-photo');
            storyData.name = (_a = story === null || story === void 0 ? void 0 : story.querySelector('.name')) === null || _a === void 0 ? void 0 : _a.innerText;
            storyData.link = (_b = story === null || story === void 0 ? void 0 : story.querySelector('.item-link')) === null || _b === void 0 ? void 0 : _b.getAttribute('href');
            storyData.lastUpdated = (0, utils_1.safeNum)((story === null || story === void 0 ? void 0 : story.getAttribute('data-last-updated')) ||
                (story === null || story === void 0 ? void 0 : story.getAttribute('data-time')));
            storyData.seen = seen;
            if (!storyData.items) {
                storyData.items = [];
            }
            if (storyIndex === -1) {
                data.push(storyData);
            } else {
                data[storyIndex] = storyData;
            }
        } catch (e) {
            data[storyIndex] = {
                items: []
            };
        }
        if (story) {
            story.onclick = function (e) {
                const stories = document.querySelector('#stories')
                if (stories.getAttribute('data-slide-dragging') === 'false') {
                    modal.show(storyId);
                }
            };
        }
        var callback = callbackOption('onDataUpdate');
        if (callback) {
            callback(data, function () {});
        }
    };
    var add = function (data, append) {
        var _a, _b, _c, _d;
        var storyId = data['id'] || '';
        var storyEl = document.querySelector("#".concat(id, " [data-id=\"").concat(storyId, "\"]"));
        var items = data['items'];
        var story = null;
        var preview = undefined;
        if (items === null || items === void 0 ? void 0 : items[0]) {
            preview = ((_a = items === null || items === void 0 ? void 0 : items[0]) === null || _a === void 0 ? void 0 : _a.preview) || '';
        }
        if (internalData.seenItems[storyId] === true) {
            data.seen = true;
        }
        if (data) {
            data.currentPreview = preview;
        }
        if (!storyEl) {
            var storyItem = document.createElement('div');
            storyItem.innerHTML = templateOption('timelineItem')(data);
            story = storyItem.firstElementChild;
        } else {
            story = storyEl;
        }
        if (data.seen === false) {
            internalData.seenItems[storyId] = false;
            saveLocalData('seenItems', internalData.seenItems);
        }
        story === null || story === void 0 ? void 0 : story.setAttribute('data-id', storyId);
        if (data['photo']) {
            story === null || story === void 0 ? void 0 : story.setAttribute('data-photo', data['photo']);
        }
        story === null || story === void 0 ? void 0 : story.setAttribute('data-time', (_b = data['time']) === null || _b === void 0 ? void 0 : _b.toString());
        if (data['lastUpdated']) {
            story === null || story === void 0 ? void 0 : story.setAttribute('data-last-updated', (_c = data['lastUpdated']) === null || _c === void 0 ? void 0 : _c.toString());
        } else {
            story === null || story === void 0 ? void 0 : story.setAttribute('data-last-updated', (_d = data['time']) === null || _d === void 0 ? void 0 : _d.toString());
        }
        parseStory(story);
        if (!storyEl && !option('reactive')) {
            if (append) {
                timeline.appendChild(story);
            } else {
                (0, utils_1.prepend)(timeline, story);
            }
        }
        items === null || items === void 0 ? void 0 : items.forEach(function (item) {
            addItem(storyId, item, append);
        });
        if (!append) {
            updateStorySeenPosition();
        }
    };
    var update = add;
    var next = function () {
        modal.next();
    };
    var remove = function (storyId) {
        var _a;
        var story = document.querySelector("#".concat(id, " > [data-id=\"").concat(storyId, "\"]"));
        (_a = story === null || story === void 0 ? void 0 : story.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(story);
    };
    var addItem = function (storyId, data, append) {
        var story = document.querySelector("#".concat(id, " > [data-id=\"").concat(storyId, "\"]"));
        if (!option('reactive')) {
            var li = document.createElement('li');
            var el = story === null || story === void 0 ? void 0 : story.querySelectorAll('.items')[0];
            if (data['id']) {
                li.className = data['seen'] ? 'seen' : '';
                li.setAttribute('data-id', data['id']);
            }
            li.innerHTML = templateOption('timelineStoryItem')(data);
            if (append) {
                el === null || el === void 0 ? void 0 : el.appendChild(li);
            } else {
                (0, utils_1.prepend)(el, li);
            }
        }
        parseItems(story);
    };
    var removeItem = function (storyId, itemId) {
        var _a;
        var item = document.querySelector("#".concat(id, " > [data-id=\"").concat(storyId, "\"] [data-id=\"").concat(itemId, "\"]"));
        if (!option('reactive')) {
            (_a = item === null || item === void 0 ? void 0 : item.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(item);
            data.forEach(function (story) {
                if (story.id === storyId) {
                    story.items = story.items.filter(function (item) {
                        return item.id !== itemId;
                    });
                }
            });
        }
    };
    var nextItem = function (direction, event) {
        var currentStory = internalData.currentStory;
        var currentStoryIndex = findStoryIndex(internalData.currentStory);
        var currentItem = data[currentStoryIndex].currentItem;
        var storyViewer = document.querySelector("#zuck-modal .story-viewer[data-story-id=\"".concat(currentStory, "\"]"));
        var directionNumber = direction === 'previous' ? -1 : 1;
        if (!storyViewer) {
            return false;
        }
        var currentItemElements = storyViewer.querySelectorAll("[data-index=\"".concat(currentItem, "\"]"));
        var currentPointer = currentItemElements[0];
        var currentItemElement = currentItemElements[1];
        var navigateItem = currentItem + directionNumber;
        var nextItems = storyViewer.querySelectorAll("[data-index=\"".concat(navigateItem, "\"]"));
        var nextPointer = nextItems[0];
        var nextItem = nextItems[1];
        if (storyViewer && nextPointer && nextItem) {
            var navigateItemCallback = function () {
                if (direction === 'previous') {
                    currentPointer === null || currentPointer === void 0 ? void 0 : currentPointer.classList.remove('seen');
                    currentItemElement === null || currentItemElement === void 0 ? void 0 : currentItemElement.classList.remove('seen');
                } else {
                    currentPointer === null || currentPointer === void 0 ? void 0 : currentPointer.classList.add('seen');
                    currentItemElement === null || currentItemElement === void 0 ? void 0 : currentItemElement.classList.add('seen');
                }
                currentPointer === null || currentPointer === void 0 ? void 0 : currentPointer.classList.remove('active');
                currentItemElement === null || currentItemElement === void 0 ? void 0 : currentItemElement.classList.remove('active');
                nextPointer === null || nextPointer === void 0 ? void 0 : nextPointer.classList.remove('seen');
                nextPointer === null || nextPointer === void 0 ? void 0 : nextPointer.classList.add('active');
                nextItem === null || nextItem === void 0 ? void 0 : nextItem.classList.remove('seen');
                nextItem === null || nextItem === void 0 ? void 0 : nextItem.classList.add('active');
                storyViewer
                    .querySelectorAll('.time')
                    .forEach(function (el) {
                        el.innerText = (0, utils_1.timeAgo)(Number(nextItem.getAttribute('data-time')), option('language'));
                    });
                data[currentStoryIndex].currentItem =
                    data[currentStoryIndex].currentItem + directionNumber;
                var nextVideo = nextItem.querySelector('video');
                if (nextVideo) {
                    nextVideo.currentTime = 0;
                }
                playVideoItem(storyViewer, nextItems, event);
            };
            var callback = callbackOption('onNavigateItem');
            callback = !callback ?
                callbackOption('onNextItem') :
                callbackOption('onNavigateItem');
            callback(currentStory, nextItem.getAttribute('data-story-id'), navigateItemCallback);
        } else if (storyViewer) {
            if (direction !== 'previous') {
                modal.next();
            }
        }
        return true;
    };
    var navigateItem = nextItem;
    var updateStorySeenPosition = function () {
        document
            .querySelectorAll("#".concat(id, " .story.seen"))
            .forEach(function (el) {
                var storyId = el === null || el === void 0 ? void 0 : el.getAttribute('data-id');
                var storyIndex = findStoryIndex(storyId);
                if (storyId) {
                    var newData = data[storyIndex];
                    var timeline_1 = el === null || el === void 0 ? void 0 : el.parentNode;
                    if (!option('reactive') && timeline_1) {
                        timeline_1.removeChild(el);
                    }
                    update(newData, true);
                }
            });
    };
    var init = function () {
        if (timeline && timeline.querySelector('.story')) {
            timeline.querySelectorAll('.story').forEach(function (story) {
                parseStory(story);
                parseItems(story);
            });
        }
        if (option('backNative') && (0, utils_1.hasWindow)()) {
            if (window.location.hash === "#!".concat(id)) {
                window.location.hash = '';
            }
            window.addEventListener('popstate', function () {
                if (window.location.hash !== "#!".concat(id)) {
                    window.location.hash = '';
                }
            }, false);
        }
        if (!option('reactive')) {
            var seenItems_1 = getLocalData('seenItems');
            if (seenItems_1) {
                Object.entries(seenItems_1).forEach(function (_a) {
                    var key = _a[1];
                    if (key && data[key]) {
                        data[key].seen = seenItems_1[key] ? true : false;
                    }
                });
            }
        }
        option('stories').forEach(function (item) {
            add(item, true);
        });
        updateStorySeenPosition();
        var avatars = option('avatars') ? 'user-icon' : 'story-preview';
        var list = option('list') ? 'list' : 'carousel';
        var rtl = option('rtl') ? 'rtl' : '';
        timeline.className += " stories ".concat(avatars, " ").concat(list, " ").concat("".concat(option('skin')).toLowerCase(), " ").concat(rtl);
        return {
            id: id,
            option: option,
            callback: callbackOption,
            template: templateOption,
            language: languageOption,
            navigateItem: navigateItem,
            saveLocalData: saveLocalData,
            getLocalData: getLocalData,
            data: data,
            internalData: internalData,
            add: add,
            update: update,
            next: next,
            remove: remove,
            addItem: addItem,
            removeItem: removeItem,
            nextItem: nextItem,
            findStoryIndex: findStoryIndex,
            updateStorySeenPosition: updateStorySeenPosition,
            playVideoItem: playVideoItem,
            pauseVideoItem: pauseVideoItem,
            unmuteVideoItem: unmuteVideoItem
        };
    };
    var zuck = init();
    var modal = (0, modal_1.modal)(zuck);
    return zuck;
};

module.exports.Zuck = Zuck;