import { EventSourcePolyfill } from 'event-source-polyfill'
import $http from '../../utils/http'

const kaspiShop = {
  namespaced: true,

  state: {
    eventSource: null,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    listTerminalTradepoints({}, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.post('/kaspi-shop/terminals/v2', payload.data, cfg)
    },

    async listTerminalTradepointsStatus({ commit, rootState }, payload) {
      let url = `/kaspi-shop/terminals/${payload.terminalId}/status`
      let token = null

      if (process.env.VUE_APP_API_ROOT_URL) {
        url = process.env.VUE_APP_API_ROOT_URL + url
      }

      // Cordova
      if (window.cordova !== undefined) {
        const currentUser = await new Promise(resolve => { window.FirebasePlugin.getCurrentUser((user) => resolve(user)) })
        if (currentUser) {
          token = await currentUser.idToken
        }
      // Web
      } else {
        const { currentUser } = rootState.auth.firebaseAuth
        if (currentUser) {
          token = await currentUser.getIdToken()
        }
      }

      const es = new EventSourcePolyfill(url, { headers: { Authorization: `Bearer ${token}` } })
      es.onmessage = (event => {
        payload.messageCallback(event)
      })
      es.onerror = (event => {
        // Sometimes onerror called with error property undefined. EventSourcePolyfill BUG???
        if (event && Object.prototype.hasOwnProperty.call(event, 'error') && event.error === undefined) return
        payload.errorCallback(event)
      })
      commit('set', { type: 'eventSource', value: es })
    },

    closeTerminalTradepointsStatus({ state, commit }) {
      if (state.eventSource) {
        state.eventSource.close()
        commit('set', { type: 'eventSource', value: null })
      }
    },

    registerTerminalTradepoint({}, payload) {
      return $http.post(`/kaspi-shop/terminal/${payload.terminalId}/tradePoint`, payload.data)
    },

    deregisterTerminalTradepoint({}, payload) {
      return $http.delete('/kaspi-shop/terminals', {
        data: payload.data,
        headers: {
          'cash-register-password': payload.password,
        },
      })
    },

  },
}

export default kaspiShop
