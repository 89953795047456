import $http from '../../utils/http'

const moyUchet = {
  namespaced: true,

  state: {
    hasAndroid8AndAbove: false,
    hasNFC: false,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    fetchOrganization({}, payload) {
      return $http.get(`/moyuchet/crs/${payload.cashRegisterId}/organization?lang=ru`)
    },

    connectCashRegister({ }, payload) {
      return $http.post(`/moyuchet/crs/${payload.cashRegisterId}/connect`, payload.data, {
        headers: {
          'cash-register-password': payload.password,
        },
      })
    },

    disconnectCashRegister({ }, payload) {
      return $http.delete(`/moyuchet/crs/${payload.cashRegisterId}/disconnect`, {
        headers: {
          'cash-register-password': payload.password,
        },
      })
    },
  },
}

export default moyUchet
