import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import axios from 'axios'
import { UAParser } from 'ua-parser-js'
import store from '../store/index'
import i18n, { getLocale } from '../i18n'

const api = {
  init() {
    const messaging = getMessaging(store.state.tools.firebaseApp)

    onMessage(messaging, (message) => {
      console.debug('FCM(web): onMessageReceived')
      console.debug(message)

      if (message.data.notification_foreground === 'true' && message.data.notification) {
        const parsedMessage = JSON.parse(message.data.notification)
        // eslint-disable-next-line no-new
        const notif = new Notification(parsedMessage.title, {
          body: parsedMessage.body,
          icon: `${process.env.VUE_APP_API_ROOT_URL}/android-chrome-192x192.png`,
          data: {
            url: message.data.url,
          },
        })

        notif.onclick = (event) => {
          if (event.currentTarget.data.url) {
            Vue.prototype.$openLink(event.currentTarget.data.url)
          }
          notif.close()
        }
      }
    })

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register(process.env.VUE_APP_TEST === 'true' ? '/firebase-messaging-sw-test.js' : '/firebase-messaging-sw.js').then(
        (registration) => {
          const notificationPermissionRequestCount = parseInt(localStorage.getItem('rekassa.kz-tools-notification-permission-request-count') || 0, 10)
          if (Notification.permission === 'granted') {
            console.debug('FCM(web): Notifications permission was granted previously')
            this.requestToken(messaging, registration)
          } else if (notificationPermissionRequestCount < 2) {
            localStorage.setItem('rekassa.kz-tools-notification-permission-request-count', notificationPermissionRequestCount + 1)
            setTimeout(() => {
              store.dispatch('tools/showConfirm', {
                title: i18n.t('вы_хотели_бы_получать_уведомления_о_последних_новостях_и_событиях'),
                resolveText: `${i18n.t('да')}!`,
                rejectText: i18n.t('не_сейчас'),
              }).then(() => {
                Notification.requestPermission().then((permission) => {
                  console.debug(`FCM(web): Notifications permission was ${permission === 'granted' ? 'granted' : 'denied'}`)
                  if (permission === 'granted') {
                    this.requestToken(messaging, registration)
                  }
                })
              }).catch(() => {
              })
            }, 4000)
          }
        },
        (error) => {
          console.error(`FCM(web): Service worker registration failed: ${error}`)
        },
      )
    }
  },

  requestToken(messaging, registration) {
    getToken(messaging, { vapidKey: process.env.VUE_APP_PUSH_VAPID_PUBLIC_KEY, serviceWorkerRegistration: registration }).then((token) => {
      if (token) {
        console.debug('FCM(web): getToken: ', token)
        this.subscribe(token)
      } else {
        console.debug('FCM(web): No registration token available. Request permission to generate one.')
      }
    }).catch((err) => {
      console.debug('FCM(web): An error occurred while retrieving token. ', err)
    })
  },

  subscribe(token) {
    (async () => {
      const uuid = localStorage.getItem('rekassa.kz-web-push-uuid') || uuidv4()
      localStorage.setItem('rekassa.kz-web-push-uuid', uuid)

      const { currentUser } = store.state.auth.firebaseAuth
      const bearerToken = await currentUser.getIdToken()
      const config = {
        headers: { Authorization: `Bearer ${bearerToken}`, 'x-user-uid': currentUser.uid },
      }
      let url = '/notification/users/tokens'
      if (process.env.VUE_APP_API_ROOT_URL) {
        url = process.env.VUE_APP_API_ROOT_URL + url
      }
      axios.post(
        url, {
          deviceId: uuid, token, lang: getLocale().toUpperCase(), detailedInfo: this.getDetailedInfo(), deviceType: 'WEB',
        },
        config,
      ).then(() => {
        console.debug(`FCM(device): Subscribed for push notifications deviceId: ${uuid}`)
      }).catch((error) => {
        console.debug('FCM(device): Failed to subscribe: ', error)
      })
    })()
  },

  unsubscribe() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        const uuid = localStorage.getItem('rekassa.kz-web-push-uuid')

        const { currentUser } = store.state.auth.firebaseAuth
        const bearerToken = await currentUser.getIdToken()
        const config = {
          headers: { Authorization: `Bearer ${bearerToken}`, 'x-user-uid': currentUser.uid },
        }
        let url = `/notification/users/device-ids/${uuid}`
        if (process.env.VUE_APP_API_ROOT_URL) {
          url = process.env.VUE_APP_API_ROOT_URL + url
        }
        axios.delete(
          url,
          config,
        ).then(() => {
          console.debug(`FCM(device): Unsubscribed for push notifications deviceId: ${uuid}`)
          resolve()
        }).catch((error) => {
          console.debug('FCM(device): Failed to unsubscribed: ', error)
          resolve()
        })
      } catch (e) {
        resolve()
      }
    })
  },

  getDetailedInfo() {
    const info = {}
    try {
      info.userAgent = UAParser(navigator.userAgent)
    } catch (error) {
      console.debug(error)
    }
    return info
  },
}

export default api
