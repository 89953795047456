<template lang="pug">
v-app

  v-app-bar(app dark dense flat clipped-left)
    img(class="mr-1 ml-3" :src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" height="40")

  v-main(flat)
    v-container(fill-height style="max-width: 350px")
      v-container
        v-row
          v-col(cols="12" align="center")
            img(ref="loginPhoneFrame" src="/static/login-phone-frame.png" style="width: 200px;")

        v-row(justify="center" no-gutters)
          v-col.mt-4.mb-6(cols="12" align="center")
            h2.headline {{ $t("вход_в_систему") }}

        v-row(v-if="posCredentials" justify="center" no-gutters)
          v-col.mb-6(cols="12")
            v-btn.white--text(block :color="posCredentials ? 'primary' : 'secondary'" :loading="loading" @click="signInWithQR(posCredentials)")
              v-icon(small left) mdi-cash-register
              | {{ $t("войти_под", { serial: posCredentials.n }) }}

        v-row(justify="center" no-gutters)
          v-col.mb-6(cols="12")
            v-btn.white--text(block :color="posCredentials ? 'secondary' : 'primary'" :loading="loading" @click="scanQR()")
              v-icon(small left) mdi-qrcode
              | {{ $t("сканировать_qr_код") }}

        v-row(justify="center" no-gutters)
          v-row.mt-5(justify="center")
            span.caption {{ $t("нажимая_кнопку_продолжить_вы_принимаете_условия") }}
            .caption.ml-1 {{ $t("пользовательского_соглашения") }}

          v-row.mt-5(justify="center" no-gutters)
            v-col(cols="4")
              v-row(justify="center" no-gutters)
                a.caption(@click="changeLocale('ru')" :class="{ 'font-weight-bold' : locale === 'ru' }") Русский
            v-col(cols="4" justify="center")
              v-row(justify="center" no-gutters)
                a.caption(@click="changeLocale('kk')" :class="{ 'font-weight-bold' : locale === 'kk' }") Қазақша

  re-connection-warning
</template>
<script>
import { mapState, mapActions } from 'vuex'
import i18n, { getLocale, changeLocale } from '../../i18n'
import ConnectionWarning from '../utils/ConnectionWarning.vue'

export default {
  components: {
    're-connection-warning': ConnectionWarning,
  },

  data: () => ({
    imageUrl: {
      light: '/static/logo-white.png',
      dark: '/static/logo-black.png',
    },
    loading: false,
    posCredentials: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    if (this.user) {
      this.$router.push('/')
    }

    const ps = localStorage.getItem('rekassa.kz-auth-ffb-pos-credentials')
    if (ps) {
      this.posCredentials = JSON.parse(ps)
    }
  },

  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
      signInWithCustomToken: 'auth/signInWithCustomToken',
      getCustomTokenByNumberAndPassword: 'auth/getCustomTokenByNumberAndPassword',
      init: 'cashRegisters/init',
      setConnectionWarning: 'tools/setConnectionWarning',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    scanQR() {
      const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10)
      window.cordova.plugins.rekassaBarcode.scan((qrcode) => {
        try {
          const qr = JSON.parse(qrcode)
          if (qr.n && qr.p) {
            this.signInWithQR(qr)
          } else {
            this.showSnackbar({ message: `${i18n.t('неверный_qr_код')}` })
          }
        } catch (error) {
          this.showSnackbar({ message: `${i18n.t('неверный_qr_код')}` })
        }
      },
      () => {}, { aspectRatio })
    },

    signInWithQR(qr) {
      this.loading = true
      this.getCustomTokenByNumberAndPassword({ data: { number: qr.n, password: qr.p } }).then((token) => {
        this.signInWithCustomToken({ token }).then((tokenResult) => {
          this.setUser(tokenResult.user).then(() => {
            this.init().then(() => {
              localStorage.setItem('rekassa.kz-auth-ffb-pos-credentials', JSON.stringify(qr))
              this.$router.push('/')
              this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'QR' } })
            })
          })
        }).catch(() => {
          this.loading = false
          this.showSnackbar({ message: `${i18n.t('произошла_ошибка')}` })
        })
      }).catch(() => {
        this.loading = false
      })
    },

    changeLocale(locale) {
      changeLocale(locale)
    },
  },
}
</script>
<style lang="stylus">
</style>
