<template lang="pug">
v-text-field(v-bind="$attrs" :value="numericValue" inputmode="decimal" @keypress="keypress" @input="input")
  slot(v-for="slot in Object.keys($slots)" :name="slot" :slot="slot")
    template(v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope")
      slot(:name="slot" v-bind="scope")
</template>

<script>
export default {
  name: 'NumericInputField',

  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    limitDecimal: {
      type: String,
      default: '2',
    },
  },

  data() {
    return {
      numericValue: null,
    }
  },

  watch: {
    value(newValue) {
      this.numericValue = newValue
    },
  },

  created() {
    this.numericValue = this.value
  },

  methods: {
    input(value) {
      this.numericValue = value.replace(/,/g, '.')
      let zeros = ''
      for (let index = 0; index < this.limitDecimal; index += 1) {
        zeros += '0'
      }
      this.$emit('input', this.$options.filters.numeral(this.numericValue, `0.[${zeros}]`))
    },

    keypress(event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which)
      if (keyCode === 44) {
        keyCode = 46
      }
      const { value } = event.target
      const i = value.indexOf('.')

      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || i !== -1)) {
        event.preventDefault()
      } else if (event.target.selectionStart === event.target.selectionEnd && (i === -1 || event.target.selectionEnd > i) && value.split('.')[1]?.length > parseInt(this.limitDecimal, 10) - 1) {
        event.preventDefault()
      } else if (event.target.value === '0' && keyCode !== 46) {
        event.target.value = ''
      } else if (event.target.value === '' && keyCode === 46) {
        event.preventDefault()
      }
    },
  },
}
</script>

<style lang="stylus">
</style>
