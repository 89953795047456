<template lang="pug">
.mt-6.mt-md-12.d-flex.justify-center(v-if="show" :class="{'container': $vuetify.breakpoint.mdAndUp}")
  div(id="stories" data-slide-dragging="false")
div.mt-4(v-else)
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'
import { Zuck } from '../../utils/lib/stories/index'
import '../../utils/lib/stories/style.css'
// import defaultStories from '../../utils/lib/stories/default-stories.json'

export default {
  data: () => ({
    leftScrollDisabled: true,
    rightScrollDisabled: false,
    show: true,

    slider: null,
    isDown: false,
    startX: null,
    scrollLeft: null,
    velX: 0,
    momentumID: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),

    locale() {
      return getLocale()
    },
  },

  mounted() {
    const options = {
      skin: 'Snapgram',
      backNative: false,
      previousTap: true,
      autoFullScreen: false,
      avatars: true,
      paginationArrows: false,
      list: false,
      cubeEffect: true,
      localStorage: true,
      callbacks: {
        onOpen(storyId, callback) {
          callback()
        },
        onView: (story) => {
          const items = document.querySelectorAll('.viewing .zuck-link')
          items.forEach(item => {
            item.addEventListener('click', (evt) => this.handleLink(story, evt))
          }, this)

          this.analyticsLogEvent({ eventName: 're_dash_story_viewed', eventProperties: { story_name: story.id, story_date_publication: this.$moment(story.lastUpdated).format('DD-MM-YYYY') } })
        },
        onEnd(storyId, callback) {
          callback()
        },
        onClose(storyId, callback) {
          callback()
        },
        onNextItem(storyId, nextStoryId, callback) {
          callback()
        },
        onNavigateItem(storyId, nextStoryId, callback) {
          callback()
        },
      },
    }

    // const element = document.querySelector('#stories')
    // const zuckjs = Zuck(element, options)
    // this.parseStories(zuckjs, defaultStories[this.locale])

    this.firebaseRemoteConfigGetString('STORIES').then((result) => {
      try {
        const storiesJSON = JSON.parse(result)[this.locale]
        this.show = true
        const element = document.querySelector('#stories')
        const zuckjs = Zuck(element, options)
        this.parseStories(zuckjs, storiesJSON)
      } catch (error) {
        this.show = false
      }
    })
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    handleLink(story, event) {
      const link = event.target.getAttribute('data-link')
      this.$openLink(link, '_system')
      document.querySelector('#zuck-modal').modal.close()

      this.analyticsLogEvent({ eventName: 're_dash_story_link_clicked', eventProperties: { story_name: story.id, story_date_publication: this.$moment(story.lastUpdated).format('DD-MM-YYYY'), story_link: link } })
    },

    parseStories(zuckjs, storiesJSON) {
      storiesJSON.reverse().forEach((story) => {
        zuckjs.add(story)
      })

      this.mouseScrollInit()
    },

    mouseScrollInit() {
      this.slider = document.querySelector('#stories')

      this.slider.addEventListener('mousedown', (e) => {
        this.isDown = true
        this.slider.setAttribute('data-slide-dragging', 'false')
        this.startX = e.pageX - this.slider.offsetLeft
        this.scrollLeft = this.slider.scrollLeft
        this.cancelMomentumTracking()
      })

      this.slider.addEventListener('mouseleave', () => {
        this.isDown = false
      })

      this.slider.addEventListener('mouseup', () => {
        this.isDown = false
        this.beginMomentumTracking()
      })

      this.slider.addEventListener('mousemove', (e) => {
        if (!this.isDown) return
        e.preventDefault()
        const x = e.pageX - this.slider.offsetLeft
        const walk = (x - this.startX)
        if (walk !== 0) this.slider.setAttribute('data-slide-dragging', 'true')
        const prevScrollLeft = this.slider.scrollLeft
        this.slider.scrollLeft = this.scrollLeft - walk
        this.velX = this.slider.scrollLeft - prevScrollLeft
      })

      this.slider.addEventListener('wheel', () => {
        this.cancelMomentumTracking()
      })
    },

    beginMomentumTracking() {
      this.cancelMomentumTracking()
      this.momentumID = requestAnimationFrame(this.momentumLoop)
    },

    cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID)
    },

    momentumLoop() {
      this.slider.scrollLeft += this.velX
      this.velX *= 0.95
      if (Math.abs(this.velX) > 0.5) {
        this.momentumID = requestAnimationFrame(this.momentumLoop)
      }
    },
  },
}
</script>
<style lang="stylus">
</style>
