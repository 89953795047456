<template lang="pug">
div.products-wrapper
  v-row.pa-0.ma-0.justify-space-between
    p.small-text.text-subtitle-2.text-md-h5.pa-0.col-4 {{ $t("наименование") }}
    p.small-text.text-subtitle-2.text-md-h5.pa-0.col-3 {{ $t("кол_во") }}
    p.small-text.text-subtitle-2.text-md-h5.pa-0.col-2 {{ $t("цена") }}
    p.small-text.text-subtitle-2.text-md-h5.pa-0.col-2(:class="readonly ? 'col-1' : 'col-2'") {{ $t("итог") }}
    v-sheet(width="24" color="transparent")
  v-divider.mt-1.mt-md-2
  div.mt-1.mt-md-2.mt-lg-6
    v-row(v-for="(item, index) in products" :key="item.id").pa-0.ma-0.justify-space-between.align-center.mb-1.mb-md-2.mb-lg-6
      p.small-text.text-subtitle-2.text-md-h5.pa-0.col-4 {{ item.name }}
      v-row.text-subtitle-2.text-md-h5.pa-0.ma-0.align-center.col-3
        v-btn(elevation="0" :width="countBtnSize" :height="countBtnSize" :min-width="countBtnSize" color="primary" @click="$emit('decreaseQuantity', item)" v-if="!readonly").border-radius.pa-0
          v-icon(color="tertiary") mdi-minus
        p.small-text.px-2.text-subtitle-2.text-md-h5 {{ item.quantity }}
        v-btn(elevation="0" :width="countBtnSize" :height="countBtnSize" :min-width="countBtnSize" color="primary" @click="$emit('increaseQuantity', item)" v-if="!readonly").border-radius.pa-0
          v-icon(color="tertiary") mdi-plus
      p.small-text.text-subtitle-2.text-md-h5.pa-0.col-2 {{ item.price | numeral('0,0.[00]') }}
      p.small-text.text-subtitle-2.text-md-h5.pa-0.col-2 {{ item.totalPrice | numeral('0,0.[00]') }}
      v-btn(elevation="0" color="transparent" min-width="fit-content" width="fit-content" height="fit-content" @click="$emit('removeItemFromCart', item)" v-if="!readonly").pa-0
        v-icon(color="red") mdi-close
</template>

<script>
export default {
  name: 'ProductList',

  props: {
    products: {
      type: Array,
      required: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    countBtnSize() {
      if (this.$vuetify.breakpoint.width < 341) {
        return '20'
      }
      return '24'
    },

    optionsBtnSize() {
      if (this.$vuetify.breakpoint.width < 341) {
        return '10'
      }
      return 'fit-content'
    },
  },
}
</script>

<style lang="stylus" scoped>
.products-wrapper {
    height: calc(35vh - 85px);
    overflow-y: scroll;
    overflow-x: hidden;

    @media screen and (min-width: 960px) {
        height: 70vh !important;
    }
}

.small-text {
    @media screen and (max-width: 340px) {
        font-size: 0.775rem !important;
    }
}

.border-radius {
    border-radius: 4px;
}

.custom-item {
    min-height: 32px !important;
}
</style>
