<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$goBack()")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("прайс_лист") }}: {{ cashRegister.name ? cashRegister.name : organization.businessName }}
      v-spacer
      v-btn(v-if="!$isCordova()" icon @click="$openLink(`https://link.rekassa.kz/price-list-manual-${locale}`, '_system')")
        v-icon mdi-help-circle
    v-progress-linear(:active="positionsLoading || addLoading || updateLoading || deleteLoading" :indeterminate="positionsLoading || addLoading || updateLoading || deleteLoading" fixed top color="secondary")

  v-main(v-if="!$isCordova()" flat)
    v-container
      v-row
        v-col
          v-data-table.rounded-xl(:headers="headers" :items="filtredPositionList" :items-per-page="10" sort-by="name" class="elevation-1")
            template(v-slot:top)
              v-container.py-1
                v-row
                  v-col.pb-0(cols="4")
                    v-text-field.py-0.pl-2(:label="$t('поиск')" v-model="searchText" autocomplete="off" clearable)
                  v-col.pb-0(cols="2" align="left")
                    v-btn(color="primary" @click="preAdd()") + {{ $t("добавить") }}
                  v-col.pb-0(cols="6" align="right")
                    v-btn(id="menu_button" outlined color="primary") {{ $t("меню") }}
                      v-menu(bottom left activator="#menu_button")
                        v-list
                          v-list-item-group
                            v-list-item(@click="downloadTemplate()")
                              v-list-item-content
                                v-list-item-title {{ $t("скачать_шаблон") }}
                              v-list-item-icon
                                v-icon mdi-download
                            v-list-item(@click="downloadNKT()")
                              v-list-item-content
                                v-list-item-title {{ $t("скачать_справочник_нкт") }}
                              v-list-item-icon
                                v-icon mdi-download
                            v-list-item(@click="preUpload()")
                              v-list-item-content
                                v-list-item-title {{ $t("загрузить_прайс_лист") }}
                              v-list-item-icon
                                v-icon mdi-upload
                            v-list-item(@click="download()")
                              v-list-item-content
                                v-list-item-title {{ $t("скачать_прайс_лист") }}
                              v-list-item-icon
                                v-icon mdi-tag-text-outline
            template(v-slot:item.unitType="{ item }") {{ $t('unitType.' + item.unitType) }}
            template(v-slot:item.price="{ item }") {{ item.price | numeral('0,0.[00]') }}
            template(v-slot:item.edit="{ item }")
              v-icon(small @click="edit(item)") mdi-pencil
            template(v-slot:item.delete="{ item }")
              v-icon(small color="error" @click="promtDelete(item)") mdi-delete

  v-dialog(v-model="showEditDialog" persistent width="450")
    v-card
      v-card-text.px-6.py-4
        v-form(ref="form")
          v-row
            v-col.py-1.py-sm-2(cols="12")
              v-textarea(:label="$t('наименование')" v-model="selectedPosition.name" rows="1" :rules="[rules.required]" counter="500" maxlength="500" clearable autofocus validate-on-blur auto-grow autocomplete="off")
            v-col.py-1.py-sm-2(cols="6")
              v-select(:label="$t('единица_измерения')" v-model="selectedPosition.unitType" :items="unitTypes" item-value="value" item-text="title")
            v-col.py-1.py-sm-2(cols="6")
              re-numeric-input-field(:label="$t('стоимость')" v-model="selectedPosition.price" limit-decimal="2" :rules="[rules.numberRange(0, 100000000, selectedPosition.price)]" suffix="₸" validate-on-blur autocomplete="off")
            v-col.py-1.py-sm-2(cols="12")
              v-text-field(:label="$t('штрих_код')" v-model="selectedPosition.barcode" counter="20" maxlength="20" clearable autocomplete="off" placeholder=" ")
                template(v-if="$isCordova()" v-slot:append-outer)
                  v-btn(icon @click="scanBarcode('selectedPosition')")
                    v-icon mdi-barcode-scan
      v-divider
      v-card-actions
        v-btn(v-if="!newPosition" :disabled="updateLoading" :loading="deleteLoading" outlined color="red" @click="del()") {{ $t('удалить') }}
        v-spacer
        v-btn(outlined :disabled="addLoading || updateLoading || deleteLoading" @click="cancel()") {{ $t('отменить') }}
        v-btn(v-if="newPosition" :loading="addLoading" :disabled="deleteLoading" color="primary" @click="add()") {{ $t('добавить') }}
        v-btn(v-if="!newPosition" :loading="updateLoading" :disabled="deleteLoading" color="primary" @click="update()") {{ $t('сохранить') }}

  v-dialog(v-model="showUploadDialog" persistent width="450")
    v-card
      v-card-text.px-6.py-4
        v-form(ref="form")
          v-row
            v-col.pb-0
              v-file-input(v-model="file" ref="upload" :label="$t('выберите_файл_для_загрузки')" accept=".xlsx" truncate-length="30" outlined dense :disabled="uploadLoading" :loading="uploadLoading" :messages="uploadMessage" :error-messages="uploadErrorMessage" @change="fileChanged")
          v-row.py-0
            v-col.py-0.ml-7
              v-checkbox(v-model="deleteAllPositionList" :label="$t('очистить_прайс_лист')" color="error" :disabled="uploadLoading")
          v-row.py-0(v-if="deleteAllPositionList")
            v-col.py-0.ml-7
              v-checkbox(v-model="deleteAllPositionListConfirm" :label="$t('вы_уверенны_что_хотите_очистить_прайс_лист')" color="error" :disabled="uploadLoading")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined :disabled="uploadLoading" @click="showUploadDialog = false") {{ $t('отменить') }}
        v-btn(color="primary" :loading="uploadLoading" @click="upload()") {{ $t('загрузить') }}

  re-positions(:value="showPositionsDialog" :preferencesMode="true")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Decimal } from 'decimal.js'
import XLSX from 'xlsx'
import i18n, { getLocale } from '../../i18n/index'
import NumericInputField from '../utils/NumericInputField.vue'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import Positions from '../ticket/parts/Positions.vue'

export default {
  components: {
    're-positions': Positions,
    're-numeric-input-field': NumericInputField,
  },

  mixins: [dictionaryMixin],

  data: () => ({
    showPositionsDialog: false,

    templateList: [
      {
        barcode: '00015453345',
        name: 'Молоко Моё отборное 1л.',
        unitType: 'PIECE',
        price: 550,
      },
      {
        barcode: '4870205223735',
        name: 'Мастер жидкий порошок 4300 мл',
        unitType: 'PIECE',
        price: 2850,
      },
      {
        barcode: '',
        name: 'Яблоки Превосход',
        unitType: 'KILOGRAM',
        price: 600,
      },
      {
        barcode: '',
        name: 'Компот фруктовый',
        unitType: 'LITER',
        price: 550,
      },
      {
        barcode: '',
        name: 'Прокат Sony Playstation 5',
        unitType: 'HOUR',
        price: 500,
      },
      {
        barcode: '',
        name: 'Аренда Hyundai Accent',
        unitType: 'DAY',
        price: 12600,
      },
      {
        barcode: '',
        name: 'Аренда 2х комнатной квартиры',
        unitType: 'MONTH',
        price: 250000,
      },
      {
        barcode: '',
        name: 'Маникюр с покрытием',
        unitType: 'ONE_SERVICE',
        price: 3500,
      },
    ],

    headers: [
      {
        text: i18n.t('штрих_код'),
        sortable: true,
        value: 'barcode',
      },
      {
        text: i18n.t('наименование'),
        sortable: true,
        value: 'name',
      },
      {
        text: i18n.t('единица_измерения'),
        sortable: true,
        value: 'unitType',
      },
      {
        text: i18n.t('стоимость'),
        align: 'start',
        sortable: true,
        value: 'price',
      },
      {
        text: '',
        sortable: false,
        value: 'edit',
      },
      {
        text: '',
        sortable: false,
        value: 'delete',
      },
    ],

    searchText: null,

    newPosition: false,
    addLoading: false,
    updateLoading: false,
    deleteLoading: false,
    showEditDialog: false,

    selectedPosition: {
      id: null,
      name: null,
      unitType: null,
      price: null,
      barcode: null,
    },

    showUploadDialog: false,
    deleteAllPositionList: false,
    deleteAllPositionListConfirm: false,
    uploadLoading: false,
    uploadMessage: null,
    uploadErrorMessage: null,
    file: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      positionList: state => state.positions.list,
      positionsLoading: state => state.positions.loading,
    }),

    locale() {
      return getLocale()
    },

    filtredPositionList() {
      if (this.searchText === null || this.searchText === '') {
        return this.positionList
      }
      return this.positionList.filter((item) => !this.searchText || item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || (item.barcode === this.searchText))
    },
  },

  watch: {
    deleteAllPositionList(val) {
      if (!val) this.deleteAllPositionListConfirm = false
    },
  },

  created() {
    this.fetchPositions(this.cashRegister.id).then(() => {
      if (this.$isCordova()) {
        this.$nextTick(() => {
          this.showPositionsDialog = true
        })
      }
    }).catch(() => {})
  },

  methods: {
    ...mapActions({
      fetchPositions: 'positions/fetch',
      addPosition: 'positions/add',
      addAllPosition: 'positions/addAll',
      updatePosition: 'positions/update',
      deletePosition: 'positions/delete',
      deleteAllPosition: 'positions/deleteAll',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    cancel() {
      this.$refs.form.resetValidation()
      this.showEditDialog = false
    },

    edit(position) {
      this.selectedPosition.id = position.id
      this.selectedPosition.name = position.name
      this.selectedPosition.unitType = position.unitType
      this.selectedPosition.price = position.price
      this.selectedPosition.barcode = position.barcode
      this.newPosition = false
      this.showEditDialog = true
    },

    preAdd() {
      if (this.positionList.length >= process.env.VUE_APP_POSITIONS_LIMIT) {
        this.showSnackbar({ message: this.$t('превышен_лимит_на_количество_позиции_в_прайс_листе', { limit: process.env.VUE_APP_POSITIONS_LIMIT }) })
        return
      }
      this.selectedPosition = {
        id: null,
        name: null,
        unitType: 'PIECE',
        price: null,
        barcode: null,
      }
      this.newPosition = true
      this.showEditDialog = true
    },

    add() {
      if (this.$refs.form.validate()) {
        this.addLoading = true
        this.addPosition({
          cashRegisterId: this.cashRegister.id,
          data: {
            data: {
              name: this.selectedPosition.name, unitType: this.selectedPosition.unitType, price: this.selectedPosition.price, barcode: this.selectedPosition.barcode,
            },
          },
        }).then(() => {
          this.fetchPositions(this.cashRegister.id).then(() => {
            this.addLoading = false
            this.showEditDialog = false
            this.$refs.form.resetValidation()
            this.analyticsLogEvent({ eventName: 're_positions_added_item' })
          }).catch((error) => {
            this.addLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        }).catch((error) => {
          this.addLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }
    },

    update() {
      if (this.$refs.form.validate()) {
        this.updateLoading = true
        this.updatePosition({
          cashRegisterId: this.cashRegister.id,
          id: this.selectedPosition.id,
          data: {
            data: {
              name: this.selectedPosition.name, unitType: this.selectedPosition.unitType, price: this.selectedPosition.price, barcode: this.selectedPosition.barcode,
            },
          },
        }).then(() => {
          this.fetchPositions(this.cashRegister.id).then(() => {
            this.updateLoading = false
            this.showEditDialog = false
          }).catch((error) => {
            this.updateLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        }).catch((error) => {
          this.updateLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }
    },

    del() {
      this.deleteLoading = true
      this.deletePosition({ cashRegisterId: this.cashRegister.id, id: this.selectedPosition.id }).then(() => {
        this.fetchPositions(this.cashRegister.id).then(() => {
          this.deleteLoading = false
          this.showEditDialog = false
        }).catch((error) => {
          this.deleteLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }).catch((error) => {
        this.deleteLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    promtDelete(item) {
      if (this.deleteLoading) return
      this.selectedPosition.id = item.id
      this.showConfirm({
        title: this.$t('удалить_позицию', { name: item.name }),
        resolveText: this.$t('удалить'),
        rejectText: this.$t('отменить'),
        resolveButtonColor: 'error',
        rejectButtonColor: 'gray',
      }).then(() => {
        this.del()
      }).catch(() => {})
    },

    scanBarcode(which) {
      const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10)
      window.cordova.plugins.rekassaBarcode.scan((result) => {
        if (which === 'searchText') {
          this.searchText = `${result}`
        } else {
          this.selectedPosition.barcode = `${result}`
        }
      },
      () => {}, { aspectRatio })
    },

    downloadNKT() {
      fetch(`${process.env.VUE_APP_API_ROOT_URL}/static/nkt-directory-${this.locale}.xlsx`)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = blobUrl
          link.setAttribute('download', `${this.$t('справочник_нкт')}.xlsx`)

          document.body.appendChild(link)
          link.click()

          link.parentNode.removeChild(link)
          window.URL.revokeObjectURL(blobUrl)
        })
        .catch(e => console.error('Download error:', e))
    },

    downloadTemplate() {
      try {
        const data = this.templateList.map((item) => {
          const obj = {}
          obj[this.$t('штрих_код')] = item.barcode
          obj[this.$t('наименование')] = item.name
          obj[this.$t('единица_измерения')] = this.$t(`unitType.${item.unitType}`)
          obj[this.$t('стоимость')] = item.price
          return obj
        })

        const wsPrice = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, wsPrice, 'Прайс-лист')

        const hint = []
        this.unitTypes.forEach((item) => {
          const obj = {}
          obj[this.$t('единица_измерения')] = item.title
          hint.push(obj)
        })

        const wsHint = XLSX.utils.json_to_sheet(hint)
        XLSX.utils.book_append_sheet(wb, wsHint, this.$t('справочник'))

        XLSX.writeFile(wb, `${this.$t('шаблон')}.xlsx`)
      } catch (error) {
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      }
    },

    download() {
      try {
        const data = this.positionList.map((item) => {
          const obj = {}
          obj[this.$t('штрих_код')] = item.barcode
          obj[this.$t('наименование')] = item.name
          obj[this.$t('единица_измерения')] = this.$t(`unitType.${item.unitType}`)
          obj[this.$t('стоимость')] = item.price
          return obj
        })

        const wsPrice = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, wsPrice, 'Прайс-лист')

        const hint = []
        this.unitTypes.forEach((item) => {
          const obj = {}
          obj[this.$t('единица_измерения')] = item.title
          hint.push(obj)
        })

        const wsHint = XLSX.utils.json_to_sheet(hint)
        XLSX.utils.book_append_sheet(wb, wsHint, this.$t('справочник'))

        XLSX.writeFile(wb, `${this.$t('прайс_лист')}.xlsx`)
      } catch (error) {
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      }

      this.analyticsLogEvent({ eventName: 're_positions_download_click_button' })
    },

    preUpload() {
      this.file = null
      this.uploadMessage = null
      this.uploadErrorMessage = null
      this.deleteAllPositionList = false
      this.deleteAllPositionListConfirm = false
      this.showUploadDialog = true
    },

    fileChanged() {
      this.uploadMessage = null
      this.uploadErrorMessage = null
    },

    upload() {
      const reader = new FileReader()

      if (!this.file) {
        this.uploadErrorMessage = this.$t('требуется_файл')
        return
      }

      reader.onerror = () => {
        this.showSnackbar({ message: this.$t('произошла_ошибка') })
      }

      reader.onload = (event) => {
        this.uploadLoading = true

        let binary = ''
        const bytes = new Uint8Array(event.target.result)
        const length = bytes.byteLength

        for (let i = 0; i < length; i += 1) {
          binary += String.fromCharCode(bytes[i])
        }

        const wb = XLSX.read(binary, { type: 'binary' })

        const dataJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          header: ['barcode', 'name', 'unitType', 'price'], range: 1, defval: null, raw: false,
        })

        this.validateData(dataJson)
      }

      reader.readAsArrayBuffer(this.file)
    },

    validateData(dataJson) {
      let valid = true
      const { unitTypes } = this
      try {
        if (dataJson && dataJson.length > 0) {
          if (this.deleteAllPositionList && this.deleteAllPositionListConfirm) {
            if (dataJson.length > process.env.VUE_APP_POSITIONS_LIMIT) throw Error(this.$t('превышен_лимит_на_количество_позиции_в_прайс_листе', { limit: process.env.VUE_APP_POSITIONS_LIMIT }))
          } else if (dataJson.length + this.positionList.length > process.env.VUE_APP_POSITIONS_LIMIT) throw Error(this.$t('превышен_лимит_на_количество_позиции_в_прайс_листе', { limit: process.env.VUE_APP_POSITIONS_LIMIT }))

          let index = 2
          dataJson.forEach((item) => {
            item.price = this.fuzzynum(item.price)
            if (item.barcode !== null && item.barcode.length > 20) throw Error(this.$t('длина_штрих_кода_превышает_символов_строка_в_файле', { barcode: item.barcode, row: index }))
            if (item.name === null) throw Error(this.$t('отсутствует_наименование_строка_в_файле', { row: index }))
            if (item.name && item.name.length > 500) throw Error(this.$t('длина_наименования_превышает_символов_строка_в_файле', { name: item.name, row: index }))
            if (item.unitType === null) throw Error(this.$t('не_указана_единица_измерения_строка_в_файле', { row: index }))
            if (item.price === null || item.price < 0) throw Error(this.$t('не_указана_стоимость_строка_в_файле', { row: index }))

            const unitType = unitTypes.filter(type => type.title === item.unitType.trim())[0]
            if (unitType === undefined) {
              const allowed = unitTypes.reduce((allowedString, i) => {
                allowedString += (`«${i.title}» `)
                return allowedString
              }, '')
              throw Error(this.$t('неизвестная_единица_измерения_строка_в_файле', { type: item.unitType, row: index, allowed }))
            }
            item.unitType = unitType.value

            item.price = new Decimal(item.price).toNumber().toFixed(2)
            index += 1
          })
        } else {
          this.uploadLoading = false
          this.showSnackbar({ message: this.$t('в_файле_отсутствуют_данные_для_загрузки') })
        }
      } catch (error) {
        valid = false
        this.uploadLoading = false
        this.uploadErrorMessage = `${this.$t('ошибка')}: ${error.message ? error.message : error}`
      }

      if (valid) {
        this.uploadErrorMessage = null
        if (this.deleteAllPositionList && this.deleteAllPositionListConfirm) {
          this.deleteAllPosition(this.cashRegister.id).then(() => {
            this.uploadDataAll(dataJson)
          }).catch((error) => {
            this.uploadLoading = false
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          })
        } else {
          this.uploadDataAll(dataJson)
        }
      }
    },

    uploadDataAll(dataJson) {
      this.uploadMessage = this.$t('идет_загрузка_пожалуйста_подождите')
      this.$nextTick(() => {
        const positionsObject = dataJson.map((item) => {
          const obj = {}
          obj.data = item
          return obj
        })
        this.addAllPosition({
          cashRegisterId: this.cashRegister.id,
          data: positionsObject,
        }).then(() => {
          this.fetchPositions(this.cashRegister.id).then(() => {
            this.showUploadDialog = false
            this.uploadLoading = false
            this.analyticsLogEvent({ eventName: 're_positions_uploaded' })
            this.showSnackbar({ message: this.$t('прайс_лист_успешно_загружен') })
          }).catch(() => {})
        }).catch((error) => {
          this.uploadLoading = false
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      })
    },

    fuzzynum(s) {
      let v = Number(s)
      if (Number.isFinite(v)) return v
      if (!Number.isNaN(v)) return NaN
      if (!/\d/.test(s)) return v
      let wt = 1
      let ss = s.replace(/([\d]),([\d])/g, '$1$2').replace(/[$]/g, '').replace(/[%]/g, () => { wt *= 100; return '' })
      // eslint-disable-next-line no-cond-assign
      if (!Number.isNaN(v = Number(ss))) return v / wt
      ss = ss.replace(/[(](.*)[)]/, ($$, $1) => { wt = -wt; return $1 })
      // eslint-disable-next-line no-cond-assign
      if (!Number.isNaN(v = Number(ss))) return v / wt
      return v
    },
  },
}
</script>
