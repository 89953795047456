/* eslint-disable */
var hasWindow = function () {
    return typeof window !== 'undefined';
};
module.exports.hasWindow = hasWindow;
var safeNum = function (num) {
    return num ? Number(num) : 0;
};
module.exports.safeNum = safeNum;
var onAnimationEnd = function (el, func) {
    el.addEventListener('animationend', func);
};
module.exports.onAnimationEnd = onAnimationEnd;
var onTransitionEnd = function (el, func) {
    if (!el.transitionEndEvent) {
        el.transitionEndEvent = true;
        el.addEventListener('transitionend', func);
    }
};
module.exports.onTransitionEnd = onTransitionEnd;
var prepend = function (parent, child) {
    if (!child || !parent) {
        return;
    }
    if (parent === null || parent === void 0 ? void 0 : parent.firstChild) {
        parent.insertBefore(child, parent === null || parent === void 0 ? void 0 : parent.firstChild);
    } else {
        parent.appendChild(child);
    }
};
module.exports.prepend = prepend;
var generateId = function () {
    return 'stories-' + Math.random().toString(36).substr(2, 9);
};
module.exports.generateId = generateId;
var findPos = function (obj, offsetY, offsetX, stop) {
    var curleft = 0;
    var curtop = 0;
    if (obj) {
        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
                if (obj === stop) {
                    break;
                }
            } while ((obj = obj.offsetParent));
        }
        if (offsetY) {
            curtop = curtop - offsetY;
        }
        if (offsetX) {
            curleft = curleft - offsetX;
        }
    }
    return [curleft, curtop];
};
module.exports.findPos = findPos;
var timeAgo = function (time, languageObject) {
    var language = (languageObject === null || languageObject === void 0 ? void 0 : languageObject.time) || undefined;
    var timeNumber = time instanceof Date ? time.getTime() : (0, module.exports.safeNum)(time) * 1000;
    var dateObj = new Date(timeNumber);
    var dateStr = dateObj.getTime();
    var seconds = (new Date().getTime() - dateStr) / 1000;
    var formats = [
        [60, " ".concat((language === null || language === void 0 ? void 0 : language.seconds) || ''), 1],
        [120, "1 ".concat((language === null || language === void 0 ? void 0 : language.minute) || ''), ''],
        [3600, " ".concat((language === null || language === void 0 ? void 0 : language.minutes) || ''), 60],
        [7200, "1 ".concat((language === null || language === void 0 ? void 0 : language.hour) || ''), ''],
        [86400, " ".concat((language === null || language === void 0 ? void 0 : language.hours) || ''), 3600],
        [172800, " ".concat((language === null || language === void 0 ? void 0 : language.yesterday) || ''), ''],
        [604800, " ".concat((language === null || language === void 0 ? void 0 : language.days) || ''), 86400]
    ];
    var currentFormat = 1;
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        currentFormat = 2;
    }
    var result = false;
    formats.forEach(function (format) {
        var formatKey = format[0];
        if (seconds < formatKey && !result) {
            if (typeof format[2] === 'string') {
                result = format[currentFormat];
            } else if (format !== null) {
                result = Math.floor(seconds / format[2]) + format[1];
            }
        }
    });
    if (!result) {
        var day = dateObj.getDate();
        var month = dateObj.getMonth();
        var year = dateObj.getFullYear();
        return "".concat(day, "/").concat(month + 1, "/").concat(year);
    } else {
        return result;
    }
};
module.exports.timeAgo = timeAgo;