import Vue from 'vue'
import axios from 'axios'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const userLocale = () => localStorage.getItem('rekassa.kz-user-locale') || process.env.VUE_APP_I18N_FALLBACK_LOCALE

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: userLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
})

export function getLocale() {
  return i18n.locale
}

export function changeLocale(locale) {
  locale = locale || userLocale()
  i18n.locale = locale
  i18n._vm.$moment.locale(locale)
  axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html').setAttribute('lang', locale)
  localStorage.setItem('rekassa.kz-user-locale', locale)
}

export default i18n
