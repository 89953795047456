<template lang="pug">
v-sheet(color="#2D5F69").rounded-xl.px-3.py-5.component-shadow.pa-lg-8
  p.mb-3 {{ $t("скидка_на_все_товары") }}
  v-row.pa-0.ma-0.gap
    v-text-field(:value="formattedSelectedDiscount" @input="updateSelectedDiscount" type="number" background-color="reForeground darken-1" flat filled :height="inputHeight" ).rounded-xl.text-center.max-width.flex-item
    v-sheet(height="fit-content" color="reForeground darken-1").padding.rounded-xl.fit
      v-btn(:md-height="btnHeight" :height="btnHeight" :color="type === 'PERCENTAGE' ? 'primary' : 'transparent'" elevation="0" @click="switchType('PERCENTAGE')").px-4.text-lg-h5 {{ $t("в_процентах") }}
      v-btn(:md-height="btnHeight" :height="btnHeight" :color="type === 'CURRENCY' ? 'primary' : 'transparent'" elevation="0" @click="switchType('CURRENCY')").px-4.text-lg-h5 {{ $t("в_тенге") }}
  v-row.pa-0.ma-0.gap.mt-lg-6.flex-nowrap
    v-btn(v-for="(discount, index) in discounts" :key="index" min-width="fit-content" :md-height="btnHeight" :height="btnHeight" color="reForeground lighten-2" md-width="fit-content" @click="selectDiscount(discount)").px-3.btn-shadow.text-lg-h5.flex-item {{ discount.title }}
</template>

<script>
export default {
  name: 'Discount',

  props: {
    total: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    type: 'PERCENTAGE',
    selectedDiscount: 0,

    discounts: [{
      title: '0%',
      amount: 0,
    },
    {
      title: '5%',
      amount: 5,
    },
    {
      title: '10%',
      amount: 10,
    },
    {
      title: '15%',
      amount: 15,
    },
    {
      title: '20%',
      amount: 20,
    }],
  }),
  computed: {
    formattedSelectedDiscount() {
      return this.selectedDiscount === 0 ? '' : this.selectedDiscount
    },

    btnHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '48' : '36'
    },
    inputHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '60' : '48'
    },
  },

  watch: {
    selectedDiscount() {
      this.calculateDiscount()
    },
  },

  methods: {
    switchType(type) {
      this.type = type
      this.selectedDiscount = 0
      this.calculateDiscount()
    },

    selectDiscount(discount) {
      this.selectedDiscount = discount.amount
      this.type = 'PERCENTAGE'
      this.calculateDiscount()
    },

    calculateDiscount() {
      if (this.selectedDiscount > 0) {
        this.$emit('discount', { value: this.selectedDiscount, type: this.type })
      } else {
        this.$emit('discount', { value: 0, type: this.type })
      }
    },

    updateSelectedDiscount(newValue) {
      this.selectedDiscount = newValue === '' ? 0 : newValue
    },
  },
}
</script>

<style lang="stylus" scoped>
.component-shadow
  box-shadow 0px 0px 24px 0px rgba(0, 0, 0, 0.12)

.padding
  padding 6px

.gap
  gap 8px

.btn-shadow
  box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset

::v-deep .v-text-field__details
  display none

.flex-item
  flex 1 0 0

.fit
  flex 0 1 auto
</style>
