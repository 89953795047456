<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/marketplaces')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("маркетплейсы") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pt-5(align="center")
          v-img.rounded-xl(:src="`/static/marketplace-request-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("маркетплейсы") }}
          .subtitle-1.my-2 {{ $t("продавай_онлайн_легально_и_легко_с_любого_маркетплейса") }}
          .subtitle-1.my-2.mt-6 {{ $t("хотите_что_бы_чеки_автоматически_пробивались_с_другого_маркетплейса_оставьте_заявку_и_мы_рассмотрим") }}

      v-row
        v-col
          v-form(ref="form")
            v-text-field(:label="$t('номер_телефона')" v-model="phoneNumber" @input="phoneNumberError = null" :error-messages="phoneNumberError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" clearable)
            .title.mt-4 {{ $t("выберите_маркетплейс") }}
            v-radio-group.re-radio-group(v-model="marketplace"  @change="marketplaceError = null" :error-messages="marketplaceError")
              v-radio(label="Satu.kz" value="Satu")
              v-radio(label="Jusan Магазин" value="Jusan")
              v-radio(label="Halyk Market" value="Halyk")
              v-radio(:label="$t('мой_сайт')" value="myWebsite")
              v-radio(:label="$t('другое')" value="other")
            v-text-field(v-if="marketplace === 'other' || marketplace === 'myWebsite'" v-model="other" :label="marketplace === 'other' ? $t('введите_название_маркетплейса') : $t('введите_название_сайта')" type="text" @input="otherError = null" :error-messages="otherError" autocomplete="off" maxlength="40" counter="40")

            v-btn.mt-4(color="secondary" block @click="submitApplication()") {{ $t("отправить_заявку_на_подключение") }}

</template>
<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'

export default {
  data: () => ({
    phoneNumber: null,
    phoneNumberError: null,
    marketplace: null,
    marketplaceError: null,
    other: null,
    otherError: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    this.phoneNumber = this.user.phone
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    submitApplication() {
      if (this.phoneNumber?.length !== 18) {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        return
      }

      if (this.marketplace === null) {
        this.marketplaceError = this.$t('требуется_заполнить')
        return
      } if ((this.marketplace === 'other' || this.marketplace === 'myWebsite') && (this.other === null || this.other.length === 0)) {
        this.otherError = this.$t('требуется_заполнить')
        return
      }

      const url = 'https://api.sheetson.com/v2/sheets/marketplaceRequest'
      const data = {
        phone: this.phoneNumber.replace(/\D/g, ''),
        marketplace: (this.marketplace === 'other' || this.marketplace === 'myWebsite') ? this.other : this.marketplace,
        date: this.$moment().format('DD-MM-YYYY HH:mm:ss'),
      }
      const config = {
        headers: {
          Authorization: 'Bearer WL6r-WEd2IPRoiBnA80pMaQYA1UwLPOtJlKx_hOYOGs7UOK2kgVd_v2ErE0',
          'X-Spreadsheet-Id': process.env.VUE_APP_TEST === 'true' ? '1ekqUIN29RQCoBGoLBkLX1PR3irze6Hamz_XoXW6TbV4' : '1TWygb83FEdlGaE7w5T5Z-MGtNE0wMIpIG2zYOXTt4Ec',
          'Content-Type': 'application/json',
        },
      }
      axios.post(url, data, config)
        .then((response) => {
          console.debug(response.data)
        })
        .catch((error) => {
          console.error(error)
        })

      this.$router.push('/')
      this.showConfirm({
        title: this.$t('ваша_заявка_успешна_принята'),
        resolveText: this.$t('ok'),
      }).then(() => {}).catch(() => {})
      this.analyticsLogEvent({ eventName: 're_marketplace_request_form_submitted' })
    },

  },
}
</script>

<style lang="stylus">
</style>
