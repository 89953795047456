<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$goBack()")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("смена") }} №{{ shiftNumber }}: {{ $t("отчёт_по_секциям") }}
    v-progress-linear(:active="loading" :indeterminate="loading" fixed top color="secondary")

  v-main(flat :class="rollWidthClass")
    v-container.skeleton.re-max-width-400(v-if="report === null")
      v-skeleton-loader.mt-5.mx-auto(type="text" style="width: 150px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" style="width: 200px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" style="width: 200px;")

      v-skeleton-loader.mt-5.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")

      v-skeleton-loader.mt-7.mx-auto(type="text" style="width: 140px;")

      v-skeleton-loader.mt-7(type="text" style="width: 200px;")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" style="width: 200px;")
      v-skeleton-loader.mt-3.mx-auto(type="text")
      v-skeleton-loader.mt-3.mx-auto(type="text")

      v-divider.my-3

      v-skeleton-loader.mt-5.mx-auto(type="text" style="width: 200px;")

    v-container.receipt.re-max-width-400(v-if="report")
      v-row
        v-col
          h1.headline.font-weight-bold.text-center {{ organization.businessName }}
          h2.subtitle-1.text-center {{ $t("бин_иин") }}: {{ organization.businessId }}
          h2.subtitle-2.text-center {{ cashRegister.pos.address }}

      v-row.mt-4
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("рнм") }}:
            div {{ cashRegister.registrationNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("знм") }}:
            div {{ cashRegister.serialNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("ккм") }}:
            div {{ cashRegister.model }}

      v-row.my-4
        v-col
          h2.title.text-center {{ $t("отчёт_по_секциям") }}

      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("смена") }}:
            div №{{ shiftNumber }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("начало") }}:
            div {{ report.openShiftTime.value | moment("DD-MM-YYYY") }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("время") }}:
            div {{ report.openShiftTime.value | moment("HH:mm:ss") }}
      v-row(v-if="report.closeShiftTime")
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("конец") }}:
            div {{ report.closeShiftTime.value | moment("DD-MM-YYYY") }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("время") }}:
            div {{ report.closeShiftTime.value | moment("HH:mm:ss") }}

      v-divider.my-3

      template(v-for="(section, $index) in sections")
        v-row
          v-col(cols="6")
            div.d-flex.justify-space-between(style="flex: 1")
              div {{ $t("секция") }}:
              div {{ section.sectionCode }}
        v-row(v-for="(operation, $index2) in section.operations" :key="'o_' + $index + $index2")
          v-col
            .text-uppercase {{ $t('operationType.' + operation.operation) }}
            v-list
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("количество_чеков") }}
                v-list-item-action.flex-row-reverse
                  v-list-item-action-text.body-1 {{ operation.count | numeral('0,0') }}
              v-list-item
                v-list-item-content
                  v-list-item-title {{ $t("сумма") }}
                v-list-item-action
                  v-list-item-action-text.body-1 {{ operation.sum.value | numeral('0,0.00') | beautiful-minus }} ₸

        v-divider.my-3

      v-row
        v-col.text-center
          div {{ $t(`fdo.${cashRegister.fdo}.title`) }}
          div
            span {{ $t(`fdo.${cashRegister.fdo}.url`) }}

  v-bottom-navigation(app)
    v-btn(icon @click="downloadReceipt()" :loading="downloadLoading" :disabled="loading")
      v-icon mdi-download

    v-btn(icon @click="print()" :loading="printingLoading" :disabled="loading")
      v-icon mdi-printer

</template>
<script>
import { mapState, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
import download from 'downloadjs'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    printingDialog: false,

    printingLoading: false,
    downloadLoading: false,

    report: null,
    loading: false,

    shiftNumber: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      printerSettings: state => state.printer.settings,
    }),

    sections() {
      return this.report.sections.map((section) => {
        section.operations = section.operations.map((operation) => {
          operation.sum.value = this.getNumberFromBillsAndCoins(operation.sum)
          return operation
        })
        return section
      }).sort(((a, b) => a.sectionCode - b.sectionCode))
    },

    rollWidthClass() {
      return this.printerSettings.rollWidth === 58 ? 'roll-width-58mm' : 'roll-width-80mm'
    },
  },

  created() {
    this.shiftNumber = this.$route.params.shiftNumber
    this.fetchReport()
  },

  methods: {
    ...mapActions({
      fetchSectionsReport: 'cashRegisters/fetchSectionsReport',
      printReceipt: 'printer/printReceipt',
      showSnackbar: 'tools/showSnackbar',
    }),

    getDateTime(obj) {
      return this.$moment(`${obj.date.day}-${obj.date.month}-${obj.date.year} ${obj.time.hour}:${obj.time.minute}:${obj.time.second}`, 'D-M-YYYY H:m:s')
    },

    fetchReport() {
      this.loading = true
      this.fetchSectionsReport({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber }).then((result) => {
        const { data } = result
        data.openShiftTime.value = this.getDateTime(data.openShiftTime)
        if (data.closeShiftTime) {
          data.closeShiftTime.value = this.getDateTime(data.closeShiftTime)
        }
        this.report = data
        this.loading = false
        if (localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true') {
          setTimeout(() => {
            this.$vuetify.goTo(document.getElementsByClassName('receipt')[0].offsetHeight)
          }, 500)
        }
      }).catch((error) => {
        if (error && error.response && error.response.status === 404) {
          this.showSnackbar({ message: this.$t('смена_не_существует') })
        }
        this.$router.push('/')
      })
    },

    downloadReceipt() {
      this.downloadLoading = true
      setTimeout(() => {
        const receipt = document.getElementsByClassName('receipt')[0]
        html2canvas(receipt, {
          backgroundColor: '#ffffff',
          y: 50,
          scale: 2,
        }).then(canvas => {
          if (this.$isCordova()) {
            window.canvas2ImagePlugin.saveImageDataToLibrary(
              () => {
                this.showSnackbar({ message: this.$t('чек_успешно_сохранен_в_галерее') })
                this.downloadLoading = false
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                this.downloadLoading = false
              },
              canvas,
              `${this.$t('смена')} №${this.shiftNumber} ${this.$t('отчёт_по_секциям')}`,
              80,
              `reKassa_${this.cashRegister.serialNumber}`,
            )
          } else {
            download(canvas.toDataURL('image/png'), `${this.cashRegister.serialNumber} ${this.$t('смена')} №${this.shiftNumber} ${this.$t('отчёт_по_секциям')}`)
            this.downloadLoading = false
          }
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('не_удалось_cкачать_чек')}: ${error}` })
          this.downloadLoading = false
        })
      }, 0)
    },

    print() {
      if (this.$isCordova()) {
        this.printCordova()
      } else {
        this.printWeb()
      }
    },

    printWeb() {
      window.print()
    },

    printCordova() {
      this.printingLoading = true

      const printCmds = []
      printCmds.push('#align_center#')
      printCmds.push(this.organization.businessName)
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('бин_иин')} ${this.organization.businessId}`)
      printCmds.push('#new_line#')

      printCmds.push(this.cashRegister.pos.address)
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      printCmds.push(this.$t('рнм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.registrationNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('знм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.serialNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('ккм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.model)
      printCmds.push('#new_line#')

      printCmds.push('#align_center#')
      printCmds.push(this.$t('отчёт_по_секциям'))
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      printCmds.push(`${this.$t('смена')} `)
      printCmds.push('#half_line#')
      printCmds.push(`#${this.shiftNumber}`)
      printCmds.push('#new_line#')

      printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('начало_short')} ` : `${this.$t('начало')} `}`)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.report.openShiftTime.value).format('DD-MM-YYYY'))
      printCmds.push(`${this.$t('время')} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.report.openShiftTime.value).format('HH:mm:ss'))
      printCmds.push('#new_line#')

      if (this.report.closeShiftTime) {
        printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('конец_short')} ` : `${this.$t('конец')} `}`)
        printCmds.push('#half_line#')
        printCmds.push(this.$moment(this.report.closeShiftTime.value).format('DD-MM-YYYY'))
        printCmds.push(`${this.$t('время')} `)
        printCmds.push('#half_line#')
        printCmds.push(this.$moment(this.report.closeShiftTime.value).format('HH:mm:ss'))
        printCmds.push('#new_line#')
      }

      printCmds.push('#line#')

      for (let index = 0; index < this.sections.length; index += 1) {
        const section = this.sections[index]

        printCmds.push(this.$t('секция'))
        printCmds.push('#half_line#')
        printCmds.push(section.sectionCode)
        printCmds.push('#new_line#')

        for (let index2 = 0; index2 < section.operations.length; index2 += 1) {
          const operation = section.operations[index2]
          printCmds.push(this.$t(`operationType.${operation.operation}`).toUpperCase())
          printCmds.push('#new_line#')

          printCmds.push(this.$t('кол-во_чеков'))
          printCmds.push('#full_width#')
          printCmds.push(this.$options.filters.numeral(operation.count, '0,0'))
          printCmds.push('#new_line#')

          printCmds.push(this.$t('сумма'))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(operation.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }

        printCmds.push('#line#')
      }

      printCmds.push('#align_center#')

      printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.title`))
      printCmds.push('#new_line#')
      printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.url`))
      printCmds.push('#new_line#')

      const payload = {
        cmds: printCmds,
        qrCode: null,
      }

      this.printReceipt(payload).then(() => {
        setTimeout(() => { this.printingLoading = false }, 500)
      }, () => {
        setTimeout(() => { this.printingLoading = false }, 500)
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
