<template lang="pug">
v-dialog(v-model="confirm.show" :persistent="confirm.persistent || confirm.resolveLoading || confirm.rejectLoading" scrollable max-width="375")
  v-sheet.rounded-xl.pa-10
    v-container.pt-0.pb-8(v-if="confirm.icon")
      v-row(justify="center")
        v-icon(size="100" color="secondary") {{ confirm.icon }}

    v-container.px-0(v-if="confirm.img")
      v-row(justify="center")
        v-img(eager :src="confirm.img")

    p.text-h3.font-weight-bold.mb-5.text-center(v-html="confirm.title")
    p.text-subtitle-1.mb-6.text-center(v-html="confirm.text")

    template(v-if="confirm.resolveText")
      v-btn.font-weight-bold.text-h5.px-3.col-12.mb-4(:color="confirm.resolveButtonColor" :loading="confirm.resolveLoading" :disabled="confirm.rejectLoading" @click="resolve()") {{ confirm.resolveText }}

    template(v-if="confirm.rejectText")
      v-btn.font-weight-bold.text-h5.px-4.col-12(:color="confirm.rejectButtonColor" :loading="confirm.rejectLoading" :disabled="confirm.resolveLoading" @click="reject()" outlined) {{ confirm.rejectText }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Confirm',

  computed: {
    ...mapState({
      confirm: state => state.tools.confirm,
    }),
  },

  methods: {
    ...mapActions({
      hideConfirm: 'tools/hideConfirm',
      clearConfirm: 'tools/clearConfirm',
      setConfirmLoading: 'tools/setConfirmLoading',
    }),

    resolve() {
      if (this.confirm.resolveCallback) {
        this.setConfirmLoading({ type: 'resolveLoading', value: true })
        this.confirm.resolveCallback().then((payload) => {
          this.setConfirmLoading({ type: 'resolveLoading', value: false })
          if (payload.hide) {
            this.hideConfirm()
            this.$nextTick(() => {
              this.clearConfirm()
            })
          }
        })
      } else {
        this.confirm.deferred.resolve()
        this.hideConfirm()
        this.$nextTick(() => {
          this.clearConfirm()
        })
      }
    },

    reject() {
      if (this.confirm.rejectCallback) {
        this.setConfirmLoading({ type: 'rejectLoading', value: true })
        this.confirm.rejectCallback().then((payload) => {
          this.setConfirmLoading({ type: 'rejectLoading', value: false })
          if (payload.hide) {
            this.hideConfirm()
            this.$nextTick(() => {
              this.clearConfirm()
            })
          }
        })
      } else {
        this.confirm.deferred.reject()
        this.hideConfirm()
        this.$nextTick(() => {
          this.clearConfirm()
        })
      }
    },
  },

}
</script>

<style lang="stylus">
</style>
