<template lang="pug">
v-app
  v-app-bar.re-app-bar(app color="error" :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/kkm')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("деактивация_кассы") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.re-v-list-fulltext
            v-list-item
              v-list-item-content
                v-list-item-title.mb-5 {{ $t("деактивировать_снять_с_учёта") }}
                v-list-item-title.mb-5 {{ $t("деактивируйте_кассу_закрыв_смену_dotdotdot") }}
                v-list-item-title {{ $t("если_понадобятся_чеки_dotdotdot") }}

      v-row
        v-col
          v-btn.my-2(block color="error" @click="showDeregistrationWarning()") {{ $t("деактивировать_кассу") }}

  v-dialog(v-model="deregistrationDialogWarning" persistent scrollable max-width="400")
    v-card
      v-card-text.pt-6
        .headline {{ $t("вы_действительно_хотите_деактивировать_кассу", { name: cashRegister.name ? cashRegister.name : organization.businessName }) }}
        v-alert.mt-5(color="error" dark prominent)
          | {{ $t("внимание_после_деактивации_dotdotdot") }}
        v-checkbox(v-model="deregistrationCheckbox" :label="$t('я_осознаю_что_касса_безвозвратно_перейдёт_dotdotdot')" color="red" :class="deregistrationCheckboxClass")
      v-card-actions.mb-2
        v-spacer
        v-btn(color="error" :disabled="deregistrationDisabled" @click.native="showDeregistrationDialogPinpad()" ) {{ $t("деактивировать") }} {{ deregistrationDisabledTimeLeft }}
        v-btn(outlined @click.native="cancel()") {{ $t("отменить") }}

  re-pinpad(v-model="deregistrationDialog" :title="$t('деактивация_кассы')" :subtitle="$t('введите_пин-код')" :text="$t('для_деактивация_кассы')" :loading="loading" :errorText.sync="deregistrationErrorMessage" @action="deregistrate")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import store from '../../store/index'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-pinpad': Pinpad,
  },

  data: () => ({
    loading: false,

    deregistrationDialogWarning: false,
    deregistrationDialog: false,
    deregistrationErrorMessage: null,

    deregistrationCheckbox: false,
    deregistrationCheckboxClass: null,
    deregistrationDisabled: true,
    deregistrationDisabledTimer: null,
    deregistrationDisabledTimeLeft: null,
    deregistrationDisabledCounter: null,
  }),

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.status === 'REGISTERED' && store.state.cashRegisters.cashRegister.roles.find(r => r === 'OWNER') !== undefined) {
      next()
    } else {
      next('/')
    }
  },

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),
  },

  methods: {
    ...mapActions({
      deregistrateCashRegister: 'cashRegisters/deregistrateCashRegister',
      analyticsLogEvent: 'analytics/logEvent',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
    }),

    isShiftClosed() {
      if (this.cashRegister.shiftOpen) {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену_чтобы_поменять_данный_параметр'),
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
        return false
      }
      return true
    },

    showDeregistrationWarning() {
      if (!this.isShiftClosed()) return

      this.deregistrationDialogWarning = true

      // Countdown
      this.deregistrationCheckbox = false
      this.deregistrationCheckboxClass = null
      this.deregistrationDisabled = true
      this.deregistrationDisabledTimeLeft = '30'
      this.deregistrationDisabledCounter = 30
      this.deregistrationDisabledTimer = setInterval(() => {
        if (this.deregistrationDisabledCounter <= 1) {
          clearInterval(this.deregistrationDisabledTimer)
          this.deregistrationDisabledTimeLeft = null
          this.deregistrationDisabled = false
        } else {
          this.deregistrationDisabledCounter -= 1
          this.deregistrationDisabledTimeLeft = this.$options.filters.numeral(this.deregistrationDisabledCounter, '00')
        }
      }, 1000)
    },

    cancel() {
      this.deregistrationDialogWarning = false
      if (this.deregistrationDisabledTimer) {
        clearInterval(this.deregistrationDisabledTimer)
      }
    },

    showDeregistrationDialogPinpad() {
      if (!this.deregistrationCheckbox) {
        this.deregistrationCheckboxClass = 'blinker'
        return
      }
      this.deregistrationDialogWarning = false
      this.deregistrationDialog = true
    },

    deregistrate(pincode) {
      this.loading = true
      this.deregistrateCashRegister({
        cashRegister: this.cashRegister,
        password: pincode,
      }).then(() => {
        this.deregistrationDialog = false
        this.loading = false
        this.showSnackbar({ message: this.$t('касса_успешно_деактивирована') })
        this.analyticsLogEvent({ eventName: 're_cash_register_deactivated' })
        this.$router.push('/')
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.deregistrationErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_снять_с_учета_кассу', { error }) })
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.init()
            this.deregistrationDialog = false
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
