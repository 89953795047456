/* eslint-disable */
const utils_1 = require('./utils');
var optionsDefault = function (option) {
    return ({
        rtl: false,
        skin: 'snapgram',
        avatars: true,
        stories: [],
        backButton: true,
        backNative: false,
        paginationArrows: false,
        previousTap: true,
        autoFullScreen: false,
        openEffect: true,
        cubeEffect: false,
        list: false,
        localStorage: true,
        callbacks: {
            onOpen: function (storyId, callback) {
                // on open story viewer
                callback();
            },
            onView: function (storyId, callback) {
                // on view story
                callback === null || callback === void 0 ? void 0 : callback();
            },
            onEnd: function (storyId, callback) {
                // on end story
                callback();
            },
            onClose: function (storyId, callback) {
                // on close story viewer
                callback();
            },
            onNextItem: function (storyId, nextStoryId, callback) {
                // on navigate item of story
                callback();
            },
            onNavigateItem: function (storyId, nextStoryId, callback) {
                // use to update state on your reactive framework
                callback();
            },
            onDataUpdate: function (data, callback) {
                // use to update state on your reactive framework
                callback();
            }
        },
        template: {
            timelineItem: function (itemData) {
                return "\n        <div class=\"story ".concat(itemData['seen'] === true ? 'seen' : '', "\">\n          <a class=\"item-link\" ").concat(itemData['link'] ? "href=\"".concat(itemData['link'] || '', "\"") : '', ">\n            <span class=\"item-preview\">\n              <img lazy=\"eager\" draggable=\"false\" src=\"").concat(option('avatars') || !itemData['currentPreview'] ?
                    itemData['photo'] :
                    itemData['currentPreview'], "\" />\n            </span>\n            <span class=\"info\" itemProp=\"author\" itemScope itemType=\"http://schema.org/Person\">\n              <strong class=\"name\" itemProp=\"name\">").concat(itemData['name'], "</strong>\n              <span class=\"time\">").concat((0, utils_1.timeAgo)(itemData['lastUpdated'] || itemData['time'], option('language')) || '', "</span>\n            </span>\n          </a>\n\n          <ul class=\"items\"></ul>\n        </div>");
            },
            timelineStoryItem: function (itemData) {
                var reserved = [
                    'id',
                    'seen',
                    'src',
                    'link',
                    'linkText',
                    'linkColor',
                    'linkBackground',
                    'loop',
                    'time',
                    'type',
                    'length',
                    'preview'
                ];
                var attributes = "";
                for (var dataKey in itemData) {
                    if (reserved.indexOf(dataKey) === -1) {
                        if (itemData[dataKey] !== undefined && itemData[dataKey] !== false) {
                            attributes += " data-".concat(dataKey, "=\"").concat(itemData[dataKey], "\"");
                        }
                    }
                }
                reserved.forEach(function (dataKey) {
                    if (itemData[dataKey] !== undefined && itemData[dataKey] !== false) {
                        attributes += " data-".concat(dataKey, "=\"").concat(itemData[dataKey], "\"");
                    }
                });
                return "<a href=\"".concat(itemData['src'], "\" ").concat(attributes, ">\n                <img loading=\"auto\" src=\"").concat(itemData['preview'], "\" />\n              </a>");
            },
            viewerItem: function (storyData, currentStoryItem) {
                return "<div class=\"story-viewer\">\n                <div class=\"head\">\n                  <div class=\"left\">\n                    ".concat(option('backButton') ? '<a class="back">&lsaquo;</a>' : '', "\n\n                    <span class=\"item-preview\">\n                      <img lazy=\"eager\" class=\"profilePhoto\" src=\"").concat(storyData['photo'], "\" />\n                    </span>\n\n                    <div class=\"info\">\n                      <strong class=\"name\">").concat(storyData['name'], "</strong>\n                      <span class=\"time\">").concat((0, utils_1.timeAgo)(storyData['time'], option('language')) || '', "</span>\n                    </div>\n                  </div>\n\n                  <div class=\"right\">\n                    <span class=\"time\">\n                      ").concat((0, utils_1.timeAgo)(currentStoryItem['time'], option('language')) ||
                    '', "\n                    </span>\n                    <span class=\"loading\"></span>\n                    <a class=\"close\" tabIndex=\"2\">&times;</a>\n                  </div>\n                </div>\n\n                <div class=\"slides-pointers\">\n                  <div class=\"wrap\"></div>\n                </div>\n\n                ").concat(option('paginationArrows') ?
                    "\n                    <div class=\"slides-pagination\">\n                      <span class=\"previous\">&lsaquo;</span>\n                      <span class=\"next\">&rsaquo;</span>\n                    </div>" :
                    '', "\n              </div>");
            },
            viewerItemPointerProgress: function (style) {
                return "<span class=\"progress\" style=\"".concat(style, "\"></span>");
            },
            viewerItemPointer: function (index, currentIndex, item) {
                return "<span\n                class=\"\n                  ".concat(currentIndex === index ? 'active' : '', "\n                  ").concat(item['seen'] === true ? 'seen' : '', "\n                \"\n                data-index=\"").concat(index, "\" data-item-id=\"").concat(item['id'], "\">\n                  ").concat(option('template')['viewerItemPointerProgress']("animation-duration:".concat((0, utils_1.safeNum)(item['length']) ? item['length'] : '10', "s")), "\n              </span>");
            },
            viewerItemBody: function (index, currentIndex, item) {
                return "<div\n                class=\"\n                  item\n                  ".concat(item['seen'] === true ? 'seen' : '', "\n                  ").concat(currentIndex === index ? 'active' : '', "\n                \"\n                data-time=\"").concat(item['time'], "\"\n                data-type=\"").concat(item['type'], "\"\n                data-index=\"").concat(index, "\"\n                data-item-id=\"").concat(item['id'], "\">\n                ").concat(item['type'] === 'video' ?
                    "<video class=\"media\" data-length=\"".concat(item.length, "\" ").concat(item.loop ? 'loop' : '', " muted webkit-playsinline playsinline preload=\"auto\" src=\"").concat(item['src'], "\" ").concat(item['type'], "></video>\n                    <b class=\"tip muted\">").concat(option('language')['unmute'], "</b>") :
                    "<img loading=\"auto\" class=\"media\" src=\"".concat(item['src'], "\" ").concat(item['type'], " />\n                "), "\n\n                ").concat(item['link'] ?
                    "<a class=\"tip link zuck-link\" data-link=\"".concat(item['link'], "\" rel=\"noopener\" ")
                    .concat("style=\"color:", item['linkColor'] !== 'null' ? item['linkColor'] : '#FFF')
                    .concat("; background:", item['linkBackground'] !== 'null' ? item['linkBackground'] : '#000')
                    .concat("\">", "")
                    .concat(item['linkText'], "\n                      </a>") :
                    '', "\n              </div>");
            }
        },
        language: {
            unmute: 'Touch to unmute',
            keyboardTip: 'Press space to see next',
            visitLink: 'Visit link',
            time: {
                ago: 'ago',
                hour: 'hour ago',
                hours: 'hours ago',
                minute: 'minute ago',
                minutes: 'minutes ago',
                fromnow: 'from now',
                seconds: 'seconds ago',
                yesterday: 'yesterday',
                tomorrow: 'tomorrow',
                days: 'days ago'
            }
        }
    });
};
module.exports.optionsDefault = optionsDefault;
var option = function (options, _name) {
    var self = function (name) {
        return typeof (options === null || options === void 0 ? void 0 : options[name]) !== 'undefined' ?
            options === null || options === void 0 ? void 0 : options[name] :
            (0, module.exports.optionsDefault)(self)[name];
    };
    return self(_name);
};
module.exports.option = option;
var loadOptions = function (options) {
    return {
        option: function (name) {
            return (0, module.exports.option)(options, name);
        },
        callback: function (name) {
            var customOpts = (0, module.exports.option)(options, 'callbacks');
            return typeof customOpts[name] !== undefined ?
                customOpts[name] :
                (0, module.exports.option)(undefined, 'callbacks')[name];
        },
        template: function (name) {
            var customOpts = (0, module.exports.option)(options, 'template');
            return typeof customOpts[name] !== undefined ?
                customOpts[name] :
                (0, module.exports.option)(undefined, 'template')[name];
        },
        language: function (name) {
            var customOpts = (0, module.exports.option)(options, 'language');
            return typeof customOpts[name] !== undefined ?
                customOpts[name] :
                (0, module.exports.option)(undefined, 'language')[name];
        }
    };
};
module.exports.loadOptions = loadOptions;