<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("подключение_кассы") }}
    v-progress-linear(:active="loading" :indeterminate="loading" fixed top color="secondary")

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.re-v-list-fulltext
            div.mt-2.pt-2.px-4
              v-alert(color="yellow darken-2" dark icon="mdi-alert" dense text outlined)
                .subtitle {{ $t("для_подключения_кассы_dotdotdot") }}. &nbsp;
                  a.blinker(@click.prevent="$openLink('https://link.rekassa.kz/share-cash-register', '_system')") {{ $t("подробнее") }}

            v-list-item(@click="openFdoTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("офд") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ $t(`fdo.${fdo}.title`) }}

            v-form.px-4(ref="restoreForm" @submit.prevent="restore()")
              v-text-field(:label="$t('заводской_номер')" v-mask="'NNNNNNNN-NNN'" v-model="serialNumber" :rules="[rules.required]" autocomplete="off" validate-on-blur)
              v-text-field(:label="$t('инм_id')" v-model="id" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" validate-on-blur maxlength="9")
              v-text-field(:label="$t('токен')" v-model="token" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" validate-on-blur maxlength="9")
              v-text-field(:label="$t('введите_пин_код_от_текущей_кассы')" v-model="pincode" type="tel" :rules="[pincodeRules.length, rules.numbersOnly]" autocomplete="off" maxlength="4" validate-on-blur)
              v-btn.my-3(color="primary" block :disabled="loading" @click="restore()") {{ $t("подключить") }}

      v-dialog(v-model="fdoTypeDialog" max-width="400px")
        v-card
          v-card-title.pb-0
            span.headline {{ $t("выберите_офд") }}
          v-card-text.py-0
            v-container
              v-form.mt-3(ref="fdoTypeForm" @submit.prevent="saveFdoType()")
                v-radio-group(v-model="fdoType")
                  template(v-for="(item, $index) in fdoProviders")
                    div.fdo-radio.mt-5(@click="selectFdo(item)")
                      v-radio.fdo-radio(:value="item.id" :disabled="!item.enabled")
                      .d-inline(:style="!item.enabled ? 'opacity: 50%' : ''")
                        .title.d-inline {{ getLocale() === 'ru' ? item.name.ru : item.name.kk }}

          v-divider
          v-card-actions
            v-spacer
            v-btn(outlined @click="fdoTypeDialog = false") {{ $t("отменить") }}
            v-btn(color="primary" @click="saveFdoType()") {{ $t("выбрать") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import i18n, { getLocale } from '../../i18n/index'

export default {
  data: () => ({
    loading: false,

    fdo: 'TTK',
    serialNumber: null,
    id: null,
    token: null,
    pincode: null,

    fdoProviders: null,
    fdoType: null,
    fdoTypeDialog: false,

    pincodeRules: {
      length: (v) => (!!v && v.length === 4) || i18n.t('требуется_4-х_значный_пин-код'),
    },
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },

  created() {
    this.loading = true
    this.fetchFdoProviders().then((data) => {
      this.loading = false
      this.fdoProviders = data.data
    }).catch(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions({
      restoreCashRegister: 'cashRegisters/restoreCashRegister',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      fetchFdoProviders: 'cashRegisters/fetchFdoProviders',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    getLocale() {
      return getLocale()
    },

    openFdoTypeDialog() {
      this.fdoType = this.fdo
      this.fdoTypeDialog = true
    },
    selectFdo(item) {
      if (item.enabled) {
        this.fdoType = item.id
      }
    },
    saveFdoType() {
      if (this.$refs.fdoTypeForm.validate()) {
        this.fdoTypeDialog = false
        this.fdo = this.fdoType
      }
    },

    restore() {
      if (this.$refs.restoreForm.validate()) {
        this.loading = true
        this.restoreCashRegister({
          userId: this.user.id,
          serialNumber: this.serialNumber.toLocaleUpperCase(),
          fdo: this.fdo,
          id: this.id,
          token: this.token,
          password: this.pincode,
        }).then((result) => {
          this.selectCashRegister(result.data.cashRegister.id).then(() => {
            this.loading = false
            this.$router.push('/')
            this.showSnackbar({ message: this.$t('касса_успешна_подключена') })
            this.analyticsLogEvent({ eventName: 're_cash_register_restored', eventProperties: { serialNumber: result.data.cashRegister.serialNumber } })
          }).catch(() => {
            this.loading = false
          })
        }).catch(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>

<style lang="stylus">
.fdo-radio
  display inherit
</style>
