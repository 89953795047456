/* eslint-disable */
const utils_1 = require('./utils');
var modal = function (zuck) {
    var id = zuck.id;
    var modalZuckContainer = document.querySelector('#zuck-modal');
    if (!modalZuckContainer && !zuck.hasModal) {
        zuck.hasModal = true;
        modalZuckContainer = document.createElement('div');
        modalZuckContainer.id = 'zuck-modal';
        if (zuck.option('cubeEffect')) {
            modalZuckContainer.className = 'with-cube';
        }
        modalZuckContainer.innerHTML = '<div id="zuck-modal-content"></div>';
        modalZuckContainer.style.display = 'none';
        modalZuckContainer.setAttribute('tabIndex', '1');
        modalZuckContainer.onkeyup = function (_a) {
            var keyCode = _a.keyCode;
            var code = keyCode;
            if (code === 27) {
                modalZuckContainer.modal.close();
            } else if (code === 13 || code === 32) {
                modalZuckContainer.modal.next();
            }
        };
        if (zuck.option('openEffect')) {
            modalZuckContainer === null || modalZuckContainer === void 0 ? void 0 : modalZuckContainer.classList.add('with-effects');
        }
        if (zuck.option('rtl')) {
            modalZuckContainer === null || modalZuckContainer === void 0 ? void 0 : modalZuckContainer.classList.add('rtl');
        }
        (0, utils_1.onTransitionEnd)(modalZuckContainer, function () {
            var modalContent = document.querySelector('#zuck-modal-content');
            if (modalZuckContainer === null || modalZuckContainer === void 0 ? void 0 : modalZuckContainer.classList.contains('closed')) {
                if (modalContent) {
                    modalContent.innerHTML = '';
                }
                modalZuckContainer.style.display = 'none';
                modalZuckContainer.classList.remove('closed');
                modalZuckContainer.classList.remove('animated');
            }
        });
        document.body.appendChild(modalZuckContainer);
    }
    var translate = function (element, to, duration, ease) {
        console.log('translate: ', element, to, duration, ease);
        var _a;
        if (to === undefined || (to && isNaN(to))) {
            return;
        }
        var direction = to > 0 ? 1 : -1;
        var modalWidth = ((_a = document.querySelector('#zuck-modal')) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 1;
        var to3d = (Math.abs(to) / modalWidth) * 90 * direction;
        if (zuck.option('cubeEffect')) {
            var scaling = to3d === 0 ? 'scale(0.95)' : 'scale(0.930,0.930)';
            var modalContent = document.querySelector('#zuck-modal-content');
            if (modalContent) {
                modalContent.style.transform = scaling;
            }
            if (to3d < -90 || to3d > 90) {
                return false;
            }
        }
        var transform = !zuck.option('cubeEffect') ?
            "translate3d(".concat(to, "px, 0, 0)") :
            "rotateY(".concat(to3d, "deg)");
        if (element) {
            if (ease) {
                element.style.transitionTimingFunction = ease;
            }
            element.style.transitionDuration = "".concat(duration, "ms");
            element.style.transform = transform;
        }
    };
    var fullScreen = function (elem, cancel) {
        var anyDocument = document;
        var anyElem = elem;
        try {
            if (cancel) {
                if (anyDocument.fullscreenElement ||
                    anyDocument.webkitFullscreenElement ||
                    anyDocument.mozFullScreenElement ||
                    anyDocument.msFullscreenElement) {
                    if (anyDocument.exitFullscreen) {
                        anyDocument.exitFullscreen()["catch"](function () {});
                    } else if (anyDocument.mozCancelFullScreen) {
                        anyDocument.mozCancelFullScreen()["catch"](function () {});
                    }
                }
            } else {
                if (anyElem.requestFullscreen) {
                    anyElem.requestFullscreen();
                } else if (anyElem.msRequestFullscreen) {
                    anyElem.msRequestFullscreen();
                } else if (anyElem.mozRequestFullScreen) {
                    anyElem.mozRequestFullScreen();
                } else if (anyElem.webkitRequestFullscreen) {
                    anyElem.webkitRequestFullscreen();
                }
            }
        } catch (e) {
            console.warn("[Zuck.js] Can't access fullscreen");
        }
    };
    var moveStoryItem = function (direction) {
        var modalContainer = document.querySelector('#zuck-modal');
        var modalSlider = document.querySelector("#zuck-modal-slider-".concat(id));
        var target = '';
        var useless = '';
        var transform = 0;
        var slideItems = {
            previous: document.querySelector('#zuck-modal .story-viewer.previous'),
            next: document.querySelector('#zuck-modal .story-viewer.next'),
            viewing: document.querySelector('#zuck-modal .story-viewer.viewing')
        };
        if ((!slideItems.previous && !direction) ||
            (!slideItems.next && direction)) {
            if (!zuck.option('rtl')) {
                return false;
            }
        }
        if (!direction) {
            target = 'previous';
            useless = 'next';
        } else {
            target = 'next';
            useless = 'previous';
        }
        var transitionTime = 600;
        if (zuck.option('cubeEffect')) {
            if (target === 'previous') {
                transform = (0, utils_1.safeNum)(modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.slideWidth);
            } else if (target === 'next') {
                transform = (0, utils_1.safeNum)(modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.slideWidth) * -1;
            }
        } else {
            transform = (0, utils_1.findPos)(slideItems[target])[0] * -1;
        }
        translate(modalSlider, transform, transitionTime, null);
        setTimeout(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            // set page data when transition complete
            if (zuck.option('rtl')) {
                var tmp = target;
                target = useless;
                useless = tmp;
            }
            if (target !== '' && slideItems[target] && useless !== '') {
                var currentStory = (_a = slideItems[target]) === null || _a === void 0 ? void 0 : _a.getAttribute('data-story-id');
                zuck.internalData.currentStory = currentStory;
                var oldStory = document.querySelector("#zuck-modal .story-viewer.".concat(useless));
                if (oldStory) {
                    (_b = oldStory === null || oldStory === void 0 ? void 0 : oldStory.parentNode) === null || _b === void 0 ? void 0 : _b.removeChild(oldStory);
                }
                if (slideItems.viewing) {
                    (_c = slideItems.viewing) === null || _c === void 0 ? void 0 : _c.classList.add('stopped');
                    (_d = slideItems.viewing) === null || _d === void 0 ? void 0 : _d.classList.add(useless);
                    (_e = slideItems.viewing) === null || _e === void 0 ? void 0 : _e.classList.remove('viewing');
                }
                if (slideItems[target]) {
                    (_f = slideItems[target]) === null || _f === void 0 ? void 0 : _f.classList.remove('stopped');
                    (_g = slideItems[target]) === null || _g === void 0 ? void 0 : _g.classList.remove(target);
                    (_h = slideItems[target]) === null || _h === void 0 ? void 0 : _h.classList.add('viewing');
                }
                var newTimelineItem = getStoryMorningGlory(target);
                if (newTimelineItem) {
                    createStoryViewer(newTimelineItem, target);
                }
                var storyId = zuck.internalData.currentStory;
                var storyIndex = zuck.findStoryIndex(storyId);
                var storyWrap = document.querySelector("#zuck-modal [data-story-id=\"".concat(storyId, "\"]"));
                var items = undefined;
                if (storyWrap) {
                    items = storyWrap.querySelectorAll('[data-index].active');
                    var duration = (_j = items === null || items === void 0 ? void 0 : items[0]) === null || _j === void 0 ? void 0 : _j.firstElementChild;
                    zuck.data[storyIndex].currentItem = (0, utils_1.safeNum)((_k = items === null || items === void 0 ? void 0 : items[0]) === null || _k === void 0 ? void 0 : _k.getAttribute('data-index'));
                    if (items === null || items === void 0 ? void 0 : items[0]) {
                        items[0].innerHTML = zuck.template('viewerItemPointerProgress')(duration.style.cssText);
                        (0, utils_1.onAnimationEnd)(duration, function () {
                            zuck.nextItem();
                        });
                    }
                }
                translate(modalSlider, 0, 0, null);
                if (items) {
                    var storyViewer = document.querySelector("#zuck-modal .story-viewer[data-story-id=\"".concat(currentStory, "\"]"));
                    zuck.playVideoItem(storyViewer, items);
                }
                zuck.callback('onView')(zuck.data[storyIndex]);
            }
        }, transitionTime + 50);
    };
    var createStoryViewer = function (storyData, className, forcePlay) {
        var modalSlider = document.querySelector("#zuck-modal-slider-".concat(id));
        var storyItems = storyData['items'];
        storyData.time = storyItems && (storyItems === null || storyItems === void 0 ? void 0 : storyItems[0]['time']);
        var htmlItems = '';
        var pointerItems = '';
        var storyId = storyData['id'];
        var slides = document.createElement('div');
        var currentItem = storyData['currentItem'] || 0;
        var exists = document.querySelector("#zuck-modal .story-viewer[data-story-id=\"".concat(storyId, "\"]"));
        if (exists) {
            return false;
        }
        slides.className = 'slides';
        storyItems.forEach(function (item, i) {
            if (currentItem > i) {
                storyData.items[i].seen = true;
                item.seen = true;
            }
            pointerItems += zuck.template('viewerItemPointer')(i, currentItem, item);
            htmlItems += zuck.template('viewerItemBody')(i, currentItem, item);
        });
        slides.innerHTML = htmlItems;
        var video = slides.querySelector('video');
        var addMuted = function (video) {
            if (video.muted) {
                storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.add('muted');
            } else {
                storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('muted');
            }
        };
        if (video) {
            video.onwaiting = function () {
                if (video.paused) {
                    storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.add('paused');
                    storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.add('loading');
                }
            };
            video.onplay = function () {
                addMuted(video);
                storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('stopped');
                storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('paused');
                storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('loading');
            };
            video.onload =
                video.onplaying =
                video.oncanplay =
                function () {
                    addMuted(video);
                    storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('loading');
                };
            video.onvolumechange = function () {
                addMuted(video);
            };
        }
        var storyViewerWrap = document.createElement('div');
        storyViewerWrap.innerHTML = zuck.template('viewerItem')(storyData, storyItems[currentItem]);
        var storyViewer = storyViewerWrap.firstElementChild;
        var storyViewerPointerWrap = storyViewer.querySelector('.slides-pointers .wrap');
        storyViewer.className = "story-viewer muted ".concat(className, " ").concat(!forcePlay ? 'stopped' : '', " ").concat(zuck.option('backButton') ? 'with-back-button' : '');
        if (storyId) {
            storyViewer.setAttribute('data-story-id', storyId);
        }
        if (storyViewerPointerWrap) {
            storyViewerPointerWrap.innerHTML = pointerItems;
        }
        storyViewer
            .querySelectorAll('.close, .back')
            .forEach(function (el) {
                el.onclick = function (e) {
                    e.preventDefault();
                    modalZuckContainer.modal.close();
                };
            });
        storyViewer.appendChild(slides);
        if (className === 'viewing') {
            zuck.playVideoItem(storyViewer, storyViewer.querySelectorAll("[data-index=\"".concat(currentItem, "\"].active")), undefined);
        }
        storyViewer
            .querySelectorAll('.slides-pointers [data-index] > .progress')
            .forEach(function (el) {
                (0, utils_1.onAnimationEnd)(el, function () {
                    zuck.nextItem(undefined);
                });
            });
        if (!modalSlider) {
            return;
        }
        if (className === 'previous') {
            (0, utils_1.prepend)(modalSlider, storyViewer);
        } else {
            modalSlider.appendChild(storyViewer);
        }
    };
    var createStoryTouchEvents = function (modalSlider) {
        var modalContainer = document.querySelector('#zuck-modal');
        var enableMouseEvents = true;
        var position = null;
        var touchOffset = null;
        var isScrolling = null;
        var delta = null;
        var timer = undefined;
        var nextTimer = undefined;
        var touchStart = function (event) {
            var storyViewer = document.querySelector('#zuck-modal .viewing');
            var storyViewerWrap = document.querySelector('#zuck-modal .story-viewer');
            if (event.target.nodeName === 'A') {
                return;
            }
            var touches = event.touches ?
                event.touches[0] :
                event;
            var pos = (0, utils_1.findPos)(document.querySelector('#zuck-modal .story-viewer.viewing'));
            if (modalContainer) {
                modalContainer.slideWidth = storyViewerWrap === null || storyViewerWrap === void 0 ? void 0 : storyViewerWrap.offsetWidth;
                modalContainer.slideHeight = storyViewerWrap === null || storyViewerWrap === void 0 ? void 0 : storyViewerWrap.offsetHeight;
            }
            position = {
                x: pos[0],
                y: pos[1]
            };
            var clientX = touches.clientX;
            var clientY = touches.clientY;
            touchOffset = {
                x: clientX,
                y: clientY,
                time: Date.now(),
                valid: true
            };
            if (clientY < 80 || clientY > (0, utils_1.safeNum)(modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.slideHeight) - 80) {
                touchOffset.valid = false;
            } else {
                event.preventDefault();
                isScrolling = undefined;
                delta = {};
                if (enableMouseEvents) {
                    modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.addEventListener('mousemove', touchMove);
                    modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.addEventListener('mouseup', touchEnd);
                    modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.addEventListener('mouseleave', touchEnd);
                }
                modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.addEventListener('touchmove', touchMove);
                modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.addEventListener('touchend', touchEnd);
                if (storyViewer) {
                    storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.add('paused');
                }
                zuck.pauseVideoItem();
                timer = setTimeout(function () {
                    if (storyViewer) {
                        storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.add('longPress');
                    }
                }, 600);
                nextTimer = setTimeout(function () {
                    clearInterval(nextTimer);
                    nextTimer = undefined;
                }, 250);
            }
        };
        var touchMove = function (event) {
            // var touches = event.touches ?
            //     event.touches[0] :
            //     event;
            // var clientX = touches.clientX;
            // var clientY = touches.clientY;
            // if (touchOffset && touchOffset.valid) {
            //     delta = {
            //         x: clientX - touchOffset.x,
            //         y: clientY - touchOffset.y
            //     };
            //     if (typeof isScrolling === 'undefined') {
            //         isScrolling = !!(isScrolling || Math.abs(delta.x) < Math.abs(delta.y));
            //     }
            //     if (!isScrolling && touchOffset) {
            //         event.preventDefault();
            //         translate(modalSlider, (0, utils_1.safeNum)(position === null || position === void 0 ? void 0 : position.x) + (0, utils_1.safeNum)(delta === null || delta === void 0 ? void 0 : delta.x), 0, null);
            //     }
            // }
        };
        var touchEnd = function (event) {
            var storyViewer = document.querySelector('#zuck-modal .viewing');
            var lastTouchOffset = touchOffset;
            var duration = touchOffset ? Date.now() - touchOffset.time : undefined;
            var isValid = (Number(duration) < 300 && Math.abs((0, utils_1.safeNum)(delta === null || delta === void 0 ? void 0 : delta.x)) > 25) ||
                Math.abs((0, utils_1.safeNum)(delta === null || delta === void 0 ? void 0 : delta.x)) > (0, utils_1.safeNum)(modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.slideWidth) / 3;
            var direction = (0, utils_1.safeNum)(delta === null || delta === void 0 ? void 0 : delta.x) < 0;
            var index = direction ?
                document.querySelector('#zuck-modal .story-viewer.next') :
                document.querySelector('#zuck-modal .story-viewer.previous');
            var isOutOfBounds = (direction && !index) || (!direction && !index);
            if (touchOffset && !touchOffset.valid) {} else {
                if (delta) {
                    if (!isScrolling) {
                        if (isValid && !isOutOfBounds) {
                            moveStoryItem(direction);
                        } else {
                            translate(modalSlider, (0, utils_1.safeNum)(position === null || position === void 0 ? void 0 : position.x), 300);
                        }
                    }
                    touchOffset = undefined;
                    if (enableMouseEvents) {
                        modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.removeEventListener('mousemove', touchMove);
                        modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.removeEventListener('mouseup', touchEnd);
                        modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.removeEventListener('mouseleave', touchEnd);
                    }
                    modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.removeEventListener('touchmove', touchMove);
                    modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.removeEventListener('touchend', touchEnd);
                }
                var video = zuck.internalData.currentVideoElement;
                if (timer) {
                    clearInterval(timer);
                }
                if (storyViewer) {
                    zuck.playVideoItem(storyViewer, storyViewer.querySelectorAll('.active'), undefined);
                    storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('longPress');
                    storyViewer === null || storyViewer === void 0 ? void 0 : storyViewer.classList.remove('paused');
                }
                if (nextTimer) {
                    clearInterval(nextTimer);
                    nextTimer = undefined;
                    var navigateItem = function () {
                        if (!direction) {
                            if ((0, utils_1.safeNum)(lastTouchOffset === null || lastTouchOffset === void 0 ? void 0 : lastTouchOffset.x) > document.body.offsetWidth / 3 ||
                                !zuck.option('previousTap')) {
                                if (zuck.option('rtl')) {
                                    zuck.navigateItem('previous', event);
                                } else {
                                    zuck.navigateItem('next', event);
                                }
                            } else {
                                if (zuck.option('rtl')) {
                                    zuck.navigateItem('next', event);
                                } else {
                                    zuck.navigateItem('previous', event);
                                }
                            }
                        }
                    };
                    var storyViewerViewing = document.querySelector('#zuck-modal .viewing');
                    if (storyViewerViewing && video) {
                        if (storyViewerViewing === null || storyViewerViewing === void 0 ? void 0 : storyViewerViewing.classList.contains('muted')) {
                            zuck.unmuteVideoItem(video, storyViewerViewing);
                        } else {
                            navigateItem();
                        }
                    } else {
                        navigateItem();
                        return false;
                    }
                }
            }
        };
        modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.addEventListener('touchstart', touchStart);
        if (enableMouseEvents) {
            modalSlider === null || modalSlider === void 0 ? void 0 : modalSlider.addEventListener('mousedown', touchStart);
        }
    };
    var getStoryMorningGlory = function (what) {
        var currentStory = zuck.internalData.currentStory;
        if (currentStory && what !== '') {
            var element = document.querySelector("#".concat(id, " [data-id=\"").concat(currentStory, "\"]"));
            var foundStory = what === 'previous' ?
                element.previousElementSibling :
                element.nextElementSibling;
            if (foundStory) {
                var storyId = foundStory.getAttribute('data-id');
                var storyIndex = zuck.findStoryIndex(storyId);
                var data = zuck.data[storyIndex] || false;
                return data;
            }
        }
        return false;
    };
    var show = function (storyId) {
        var modalContainer = document.querySelector('#zuck-modal');
        var callback = function () {
            var modalContent = document.querySelector('#zuck-modal-content');
            modalContent.innerHTML = "<div id=\"zuck-modal-slider-".concat(id, "\" class=\"slider\"></div>");
            if (!modalContent || !storyId) {
                return;
            }
            var storyIndex = zuck.findStoryIndex(storyId);
            var storyData = zuck.data[storyIndex];
            var currentItem = storyData.currentItem || 0;
            var modalSlider = document.querySelector("#zuck-modal-slider-".concat(id));
            createStoryTouchEvents(modalSlider);
            zuck.internalData.currentStory = storyId;
            storyData.currentItem = currentItem;
            if (zuck.option('backNative') && (0, utils_1.hasWindow)()) {
                window.location.hash = "#!".concat(id);
            }
            var previousItemData = getStoryMorningGlory('previous');
            if (previousItemData) {
                createStoryViewer(previousItemData, 'previous');
            }
            createStoryViewer(storyData, 'viewing', true);
            var nextItemData = getStoryMorningGlory('next');
            if (nextItemData) {
                createStoryViewer(nextItemData, 'next');
            }
            if (zuck.option('autoFullScreen')) {
                modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.classList.add('fullscreen');
            }
            var tryFullScreen = function () {
                if ((modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.classList.contains('fullscreen')) &&
                    zuck.option('autoFullScreen') &&
                    document.body.offsetWidth <= 1024) {
                    fullScreen(modalContainer);
                }
                modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.focus();
            };
            var storyViewerWrap = document.querySelector('#zuck-modal .story-viewer');
            if (zuck.option('openEffect') && modalContainer) {
                var storyEl = document.querySelector("#".concat(id, " [data-id=\"").concat(storyId, "\"] .item-preview"));
                var pos = (0, utils_1.findPos)(storyEl);
                modalContainer.style.marginLeft = "".concat(pos[0] + (0, utils_1.safeNum)(storyEl === null || storyEl === void 0 ? void 0 : storyEl.offsetWidth) / 2, "px");
                modalContainer.style.marginTop = "".concat(pos[1] + (0, utils_1.safeNum)(storyEl === null || storyEl === void 0 ? void 0 : storyEl.offsetHeight) / 2, "px");
                modalContainer.style.display = 'block';
                modalContainer.slideWidth = (storyViewerWrap === null || storyViewerWrap === void 0 ? void 0 : storyViewerWrap.offsetWidth) || 0;
                setTimeout(function () {
                    modalContainer === null || modalContainer === void 0 ? void 0 : modalContainer.classList.add('animated');
                }, 10);
                setTimeout(function () {
                    tryFullScreen();
                }, 300); // because effects
            } else {
                if (modalContainer) {
                    modalContainer.style.display = 'block';
                    modalContainer.slideWidth = (storyViewerWrap === null || storyViewerWrap === void 0 ? void 0 : storyViewerWrap.offsetWidth) || 0;
                }
                tryFullScreen();
            }
            zuck.callback('onView')(storyData);
        };
        zuck.callback('onOpen')(storyId, callback);
    };
    var next = function () {
        var callback = function () {
            var lastStory = zuck.internalData.currentStory;
            var lastStoryIndex = zuck.findStoryIndex(lastStory);
            var lastStoryTimelineElement = document.querySelector("#".concat(id, " [data-id=\"").concat(lastStory, "\"]"));
            if (lastStoryTimelineElement) {
                lastStoryTimelineElement === null || lastStoryTimelineElement === void 0 ? void 0 : lastStoryTimelineElement.classList.add('seen');
                zuck.data[lastStoryIndex].seen = true;
                zuck.internalData.seenItems[lastStory] = true;
                zuck.saveLocalData('seenItems', zuck.internalData.seenItems);
                zuck.updateStorySeenPosition();
            }
            var stories = document.querySelector('#zuck-modal .story-viewer.next');
            if (!stories) {
                modalZuckContainer.modal.close();
            } else {
                if (zuck.option('rtl')) {
                    moveStoryItem(false);
                } else {
                    moveStoryItem(true);
                }
            }
        };
        zuck.callback('onEnd')(zuck.internalData.currentStory, callback);
    };
    var close = function () {
        var modalContainer = document.querySelector('#zuck-modal');
        var modalContent = document.querySelector('#zuck-modal-content');
        var callback = function () {
            if (zuck.option('backNative') && (0, utils_1.hasWindow)()) {
                window.location.hash = '';
            }
            fullScreen(modalContainer, true);
            if (modalContainer) {
                if (zuck.option('openEffect')) {
                    modalContainer.classList.add('closed');
                } else {
                    if (modalContent) {
                        modalContent.innerHTML = '';
                    }
                    modalContainer.style.display = 'none';
                }
            }
        };
        zuck.callback('onClose')(zuck.internalData.currentStory, callback);
    };
    modalZuckContainer.modal = {
        show: show,
        next: next,
        close: close
    };
    return modalZuckContainer.modal;
};
module.exports.modal = modal;